import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

import {
  handleChange,
  asyncHandleChange,
} from '../functions/InputHandlers';

import { PersonContext } from '../contexts/PersonContext';

import QuicklinksCard from './cards/QuicklinksCard';
import ValueCard from './cards/ValueCard';
import ExpensesCard from './cards/ExpensesCard';
import BookValueCard from './cards/BookValueCard';
import BankingCard from './cards/BankingCard';
import PropertiesCard from './cards/PropertiesCard';

import FilterDialog from '../components/FilterDialog';

import PageHeader from '../components/PageHeader';
import GraphsCard from './cards/GraphsCard';

const styles = {
  grid: {
    width: '100%',
    margin: 0,
  },
};

class Home extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { checkCache, checkLogin } = this.context;
    checkCache();
    await checkLogin();
    this.setState({
      stale: {
        cashflow: false,
        expenses: false,
      },
      filterDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      loading: false,
    });
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.setState({ stale: { cashflow: true, expenses: true } });
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  render() {
    const { classes, match } = this.props;
    const {
      filter, stale, filterDialog, loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid spacing={3} container className={classes.grid}>
        <PageHeader
          match={match}
          title="Portfolio Dashboard"
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          dateRange
        />
        <GraphsCard
          view="portfolio"
          id={null}
        />
        <QuicklinksCard
          markStale={this.markStale}
          view="portfolio"
          id={null}
        />
        <PropertiesCard />
        <ValueCard
          view="portfolio"
          id={null}
        />
        <BankingCard />
        <ExpensesCard
          stale={stale.expenses}
          removeStale={this.removeStale}
          view="portfolio"
          id={null}
          startDate={filter.startDate ? filter.startDate : undefined}
          endDate={filter.endDate ? filter.endDate : undefined}
        />
        <BookValueCard
          view="portfolio"
          id={null}
        />
      </Grid>
    );
  }
}

Home.contextType = PersonContext;

Home.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Home);
