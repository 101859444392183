import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import makeStyles from '@material-ui/core/styles/makeStyles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import NumberFormat from 'react-number-format';
import { Link as RouterLink } from 'react-router-dom';

import PasswordStrengthChecker from '~/components/PasswordStrengthChecker';

import useRegisterForm from './useRegisterForm';

const styles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  fullNameContainer: {
    display: 'flex',
    gap: '12px',
    width: '100%',
  },
  fieldWrapper: {
    marginTop: '12px',
    width: '100%',
  },
  errorMessage: {
    marginTop: '20px',
    lineHeight: 1.2,
  },
  passwordRequirements: {
    marginTop: theme.space.sm,
  },
  acknowledgementText: {
    marginTop: '12px',
    paddingInline: '0',
    color: theme.palette.text.primary,

    [theme.breakpoints.up('sm')]: {
      paddingInline: theme.spacing(3),
    },
  },
  ctaButton: {
    marginTop: '20px',
    color: theme.palette.primary.contrast,
    fontWeight: 500,
    textTransform: 'none',
  },
  linkWrapper: {
    width: '100%',
  },
  recaptchaBadge: {
    zIndex: 5,
  },
}));

export default function RegisterForm({
  emailAlreadyExists,
  error,
  setEmailAlreadyExists,
  buttonText,
  setError,
  hasPasswordField,
  alternateSubmitFunction,
  prefillData,
}) {
  const [arePasswordRequirementsVisible, setArePasswordRequirementsVisible] = useState(false);

  const {
    checkForUniqueEmail,
    isPasswordSubmitted,
    isPasswordValid,
    passwordRequirements,
    registerForm,
    recaptchaRef,
    registerSubmit,
    setRegisterForm,
    submitting,
  } = useRegisterForm({ hasPasswordField, setEmailAlreadyExists, setError });

  const classes = styles();

  /**
   * If prefillData is provided, set the form state to the prefillData
   */
  useEffect(() => {
    if (prefillData) {
      setRegisterForm({
        ...registerForm,
        ...prefillData,
      });
    }
  }, []);

  const submit = (event) => {
    event.preventDefault();
    if (alternateSubmitFunction) {
      return alternateSubmitFunction(registerForm);
    }
    return registerSubmit(event);
  };

  return (
    <form onSubmit={submit}>
      <Box className={classes.container}>
        <Box className={classes.fullNameContainer}>
          <TextField
            fullWidth
            id="firstName"
            label="First Name"
            required
            variant="outlined"
            InputProps={{
              value: registerForm.firstName,
              name: 'nested_registerForm_firstName',
              onChange: (event) => {
                setRegisterForm({ ...registerForm, firstName: event.target.value });
              },
              autoComplete: 'given-name',
            }}
          />
          <TextField
            fullWidth
            id="lastName"
            label="Last Name"
            required
            variant="outlined"
            InputProps={{
              value: registerForm.lastName,
              name: 'nested_registerForm_lastName',
              onChange: (event) => {
                setRegisterForm({ ...registerForm, lastName: event.target.value });
              },
              autoComplete: 'family-name',
            }}
          />
        </Box>

        <TextField
          label="Email"
          id="email"
          fullWidth
          required
          variant="outlined"
          InputProps={{
            value: registerForm.email,
            name: 'nested_registerForm_email',
            onChange: (event) => {
              checkForUniqueEmail(event);
            },
            type: 'email',
            autoComplete: 'email',
          }}
          className={classes.fieldWrapper}
        />

        {emailAlreadyExists ? (
          <>
            <Typography color="error" variant="caption" className={classes.errorMessage}>
              {`This email address has already been registered. Please sign in to access your
              existing records or create an additional portfolio.`}
            </Typography>

            <RouterLink
              to={`/sign-in?email=${registerForm.email}&autoSelectOrganization=false`}
              className={classes.linkWrapper}
            >
              <Button
                color="primary"
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                className={classes.ctaButton}
              >
                Sign In Instead
              </Button>
            </RouterLink>
          </>
        ) : (
          <>
            {hasPasswordField && (
              <div className={classes.fieldWrapper}>
                <TextField
                  error={!isPasswordValid && isPasswordSubmitted}
                  fullWidth
                  id="password"
                  label="Create Password"
                  required
                  variant="outlined"
                  InputProps={{
                    value: registerForm.password,
                    name: 'nested_registerForm_password',
                    onChange: (event) => {
                      setRegisterForm({ ...registerForm, password: event.target.value });
                    },
                    onFocus: () => setArePasswordRequirementsVisible(true),
                    type: 'password',
                    autoComplete: 'new-password',
                  }}
                />
                {arePasswordRequirementsVisible && (
                  <PasswordStrengthChecker
                    requirements={passwordRequirements}
                    className={classes.passwordRequirements}
                  />
                )}
              </div>
            )}

            <FormControl
              fullWidth
              variant="outlined"
              className={classes.fieldWrapper}
            >
              <InputLabel>
                Phone (Optional)
              </InputLabel>
              <NumberFormat
                label="Phone (Optional)"
                value={registerForm.phone}
                type="tel"
                format="(###) ###-####"
                placeholder="(___) ___-____"
                mask="_"
                autoComplete="tel-national"
                onValueChange={(event) => {
                  setRegisterForm({ ...registerForm, phone: event.value });
                }}
                customInput={OutlinedInput}
              />
            </FormControl>

            <Typography
              align="center"
              variant="caption"
              className={classes.acknowledgementText}
            >
              {`By clicking ${buttonText}, you agree to REI Hub's `}
              <Link
                href="https://www.reihub.net/terms"
                color="primary"
                underline="always"
                target="_blank"
              >
                Terms of Service
              </Link>
              {' and '}
              <Link
                href="https://www.reihub.net/privacy"
                color="primary"
                underline="always"
                target="_blank"
              >
                Privacy Policy
              </Link>
              .
            </Typography>

            <ReCAPTCHA
              className={classes.recaptchaBadge}
              sitekey="6LdCnJscAAAAANpuCS4G9OWfER1Sphen8uXne-bD"
              ref={recaptchaRef}
              size="invisible"
              badge="bottomright"
            />

            {error && (
              <Typography color="error" gutterBottom variant="caption">
                {error.message}
              </Typography>
            )}

            <Button
              color="primary"
              disabled={submitting}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              className={classes.ctaButton}
            >
              {buttonText}
            </Button>
          </>
        )}
      </Box>
    </form>
  );
}

RegisterForm.propTypes = {
  emailAlreadyExists: PropTypes.bool.isRequired,
  error: PropTypes.object,
  setEmailAlreadyExists: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  hasPasswordField: PropTypes.bool,
  alternateSubmitFunction: PropTypes.func,
  prefillData: PropTypes.object,
};

RegisterForm.defaultProps = {
  error: undefined,
  buttonText: 'Start for Free',
  hasPasswordField: true,
  alternateSubmitFunction: null,
  prefillData: null,
};
