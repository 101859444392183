import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
  handleChange,
} from '../functions/InputHandlers';

import {
  parseQuery,
  buildQuery,
} from '../functions/FilterFunctions';

import CardBase from '../components/CardBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';

import ExpensesGraphCard from './cards/ExpensesGraphCard';
import ExpensesQuicklinksCard from './cards/ExpensesQuicklinksCard';
import AddBookedTransactionDialog from '../components/AddBookedTransactionDialog';
import ViewImportedTransactionDialog from '../components/ViewImportedTransactionDialog';
import ViewBookedTransactionDialog from '../components/ViewBookedTransactionDialog';
import FilterDialog from '../components/FilterDialog';
import DownloadDialog from '../components/DownloadDialog';
import FilterCard from './cards/FilterCard';
import BatchChangeCard from './cards/BatchChangeCard';
import BatchChangeImportedTransactionDialog from '../components/BatchChangeImportedTransactionDialog';
import { TablePaginationButtons } from '../functions/TableFunctions';

const styles = (theme) => ({
  red: {
    color: 'red',
  },
  tagButton: {
    padding: 0,
    color: theme.palette.action.active,
    minWidth: '32px',
  },
  tagSpacer: {
    minWidth: '32px',
    display: 'inline-block',
  },
  truncateOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class Expenses extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      stale: {
        journals: false,
        expensesGraph: false,
      },
      filterJournalsDialog: false,
      addBookedTransactionDialog: false,
      viewBookedTransactionDialog: false,
      viewImportedTransactionDialog: false,
      downloadDialog: false,
      yodleeTransactionId: null,
      filter: {
        startDate: null,
        endDate: null,
        propertyId: null,
        unitId: null,
        expenseAccountId: null,
        amount: null,
        description: '',
        startDateSelect: null,
        endDateSelect: null,
        receiptStatusSelect: { name: 'All Transactions', id: 'All' },
        propertyIdSelect: { address1: 'All Properties', id: 'All' },
        unitIdSelect: null,
        expenseAccountIdSelect: { name: 'All Expenses', id: 'All' },
        page: 0,
        pageSize: 50,
      },
      batchChangeImportedTransactionDialog: false,
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
      batchPages: [],
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.getJournals();

    this.setState({
      loading: false,
    });
  }

  componentDidUpdate() {
    const { stale } = this.state;
    if (stale.journals) {
      this.getJournals();
      this.removeStale('journals');
    }
  }

  getJournals = async () => {
    const { basis } = this.context;

    const query = Object.assign(buildQuery(this), {
      basis,
      accountType: 'Expense',
      includeFullJournals: true,
      $sort: {
        date: -1,
        amount: -1,
      },
    });

    const journalLinesResponse = await find(this, 'journal-lines', { query });
    const journalLines = journalLinesResponse.data;
    const journals = [];
    journalLines.forEach((journalLine) => {
      const { journal } = journalLine;
      // eslint-disable-next-line no-param-reassign
      journal.creditLines = [];
      // eslint-disable-next-line no-param-reassign
      journal.debitLines = [];
      journal.journalLines.forEach((line) => {
        if (line.id === journalLine.id) {
          // eslint-disable-next-line no-param-reassign
          journal.accountLine = line;
        }

        if (line.type === 'credit') {
          journal.creditLines.push(line);
        } else {
          journal.debitLines.push(line);
        }
      });
      journals.push(journal);
    });

    const totalJournals = journalLinesResponse.total;
    this.setState({
      journals,
      totalJournals,
    });
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateJournalsFilter(newFilter);
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getJournals();
  };

  closeTransactionDialogs = () => {
    this.setState({
      addBookedTransactionDialog: false,
      viewImportedTransactionDialog: false,
      viewBookedTransactionDialog: false,
      filterJournalsDialog: false,
    });
  };

  updateJournalsFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.markStale('expensesGraph');
    this.getJournals();
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  splitAccount = (journal) => {
    if (journal.accountLine.type === 'credit') {
      if (journal.debitLines.length === 1) {
        return journal.debitLines[0].account.name;
      }
      return 'Split';
    }
    if (journal.creditLines.length === 1) {
      return journal.creditLines[0].account.name;
    }
    return 'Split';
  };

  formatNegative = (value, accountLine) => {
    const { classes } = this.props;
    if (accountLine.type === 'credit') {
      return (
        <span className={classes.red}>
          {`(${value})`}
        </span>
      );
    }
    return value;
  };

  exportXlsx = async () => {
    const { organizationId } = this.context;
    const { location } = this.props;
    const { filter, journals, exportOptions } = this.state;
    return fetch(`${process.env.REACT_APP_FEATHERS_SOCKET}/export-xlsx`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('feathers-jwt')}`,
      },
      body: JSON.stringify({
        organizationId,
        page: `${location.pathname}`,
        filter,
        journals,
        exportOptions,
        reportName: 'Expenses',
      }),
    })
      .then(async (resp) => {
        if (!resp.ok) {
          const err = new Error(`File Download Error: ${resp.statusText}`);
          err.code = resp.status;
          return new Promise((resolve) => {
            this.setState(() => { throw err; }, () => resolve());
          });
        }
        return resp;
      })
      .then(async (resp) => {
        if (exportOptions.deliverySelect.id === 'email') {
          return;
        }
        const blob = new Blob([await resp.blob()], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'expenses.xlsx';
        document.body.appendChild(a);
        a.click();
      });
  }

  setExportOptions = (options) => {
    asyncHandleChange('exportOptions', options, this);
  }

  setBatchEditState = () => {
    const { batchChange } = this.state;
    if (batchChange === true) {
      this.setState({
        batchChange: false,
        batchChangeTargets: [],
        fullBatchChangeTargets: [],
        batchPages: [],
        batchType: null,
      });
    } else {
      this.setState({ batchChange: true });
    }
  };

  cancelBatchChange = () => {
    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
    });
  };

  addAllPageTransactionsToBatch = (event, value) => {
    const {
      journals, batchChangeTargets, filter, batchPages, fullBatchChangeTargets,
    } = this.state;

    const batchArray = [];
    const fullBatchArray = [];

    journals.forEach((journal) => {
      batchArray.push(journal.id);
      fullBatchArray.push(journal);
    });

    if (value) {
      const newBatchTargets = [
        ...new Set([...batchChangeTargets, ...batchArray]),
      ];
      const newFullBatchTargets = [
        ...new Set([...fullBatchChangeTargets, ...fullBatchArray]),
      ];
      const newBatchPage = [...batchPages, filter.page];

      this.setState({
        batchChangeTargets: newBatchTargets,
        fullBatchChangeTargets: newFullBatchTargets,
        batchPages: newBatchPage,
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter(
          (target) => !batchArray.includes(target),
        ),
        fullBatchChangeTargets: fullBatchChangeTargets.filter(
          (target) => !fullBatchArray.includes(target),
        ),
        batchPages: batchPages.filter((page) => page !== filter.page),
      });
    }
  };

  getBatchTransactions = (event, value, transaction) => {
    const { batchChangeTargets, fullBatchChangeTargets } = this.state;
    if (value) {
      this.setState({
        batchChangeTargets: [...batchChangeTargets, transaction.id],
        fullBatchChangeTargets: [...fullBatchChangeTargets, transaction],
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter(
          (target) => target !== transaction.id,
        ),
        fullBatchChangeTargets: fullBatchChangeTargets.filter(
          (target) => target.id !== transaction.id,
        ),
      });
    }
  };

  closeBatchChangeImportedTransactionDialog = () => {
    this.setState({
      batchChangeImportedTransactionDialog: false,
    });
  };

  onBatchChangeTransactionsDialog = () => {
    this.getJournals();

    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  openBatchChangeImportedTransactionDialog = () => {
    this.setState({ batchChangeImportedTransactionDialog: true });
  };

  actionButtons = () => {
    const buttons = [
      { text: 'Export', action: (() => this.setState({ downloadDialog: true })), class: 'export' },
      { text: 'Batch Edit', action: () => this.setBatchEditState(), class: 'edit' },
    ];
    return buttons;
  }

  render() {
    const { classes, match } = this.props;
    const {
      loading,
      journals,
      totalJournals,
      filter,
      addBookedTransactionDialog,
      journalId,
      viewBookedTransactionDialog,
      filterJournalsDialog,
      viewImportedTransactionDialog,
      yodleeTransactionId,
      downloadDialog,
      stale,
      batchChange,
      batchChangeTargets,
      fullBatchChangeTargets,
      batchChangeImportedTransactionDialog,
      batchType,
      batchPages,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title="Expenses"
          // appliedFilter={appliedFilter(this.updateJournalsFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <AddBookedTransactionDialog
          isOpen={addBookedTransactionDialog}
          type="Expense"
          closeDialog={this.closeTransactionDialogs}
          onAddTransaction={this.getJournals}
        />
        {viewBookedTransactionDialog && (
          <ViewBookedTransactionDialog
            journalId={journalId}
            closeDialog={this.closeTransactionDialogs}
            onTransactionModified={this.getJournals}
          />
        )}
        <ViewImportedTransactionDialog
          yodleeTransactionId={yodleeTransactionId}
          isOpen={viewImportedTransactionDialog}
          closeDialog={this.closeTransactionDialogs}
          onUnmatchTransaction={this.getJournals}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterJournalsDialog}
          closeDialog={this.closeTransactionDialogs}
          updateFilter={this.updateJournalsFilter}
          dateRange
          expenseAccount
          property
          unit
          vendor
          amount
          description
          receiptStatus
        />
        <DownloadDialog
          isOpen={downloadDialog}
          // exportPdf={this.exportPdf}
          exportXlsx={this.exportXlsx}
          closeDialog={() => this.setState({
            downloadDialog: false,
          })}
          setExportOptions={this.setExportOptions}
        />
        <ExpensesGraphCard
          stale={stale.expensesGraph}
          removeStale={this.removeStale}
          filter={filter}
        />
        <ExpensesQuicklinksCard
          markStale={this.markStale}
        />
        <FilterCard
          openFilter={() => this.setState({ filterJournalsDialog: true })}
          updateFilter={this.updateJournalsFilter}
          filter={filter}
          fields={['expenseAccount', 'property']}
        />
        {batchChange && (
          <BatchChangeCard
            openBatchChangeImportedTransactionDialog={
              () => this.setState({ batchChangeImportedTransactionDialog: true })
            }
            transactions={batchChangeTargets}
            cancelBatchChange={this.cancelBatchChange}
            yodleeTransactions={journals}
            screen="Expense"
            updateBatchType={(type) => this.setState({ batchType: type })}
          />
        )}
        {batchChangeImportedTransactionDialog && (
          <BatchChangeImportedTransactionDialog
            closeDialog={() => this.closeBatchChangeImportedTransactionDialog()}
            transactions={batchChangeTargets}
            fullTransactions={fullBatchChangeTargets}
            onBatchChangeTransactions={this.onBatchChangeTransactionsDialog}
            batchType={batchType}
            screen="Expense"
          />
        )}
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    {batchChange && (
                      <TableCell>
                        <Checkbox
                          onChange={(event, value) => {
                            this.addAllPageTransactionsToBatch(event, value);
                          }}
                          checked={
                            !!batchPages.includes(filter.page)
                            && batchChangeTargets.length > 0
                          }
                        />
                      </TableCell>
                    )}
                    <TableCell><Typography variant="subtitle2">Date</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Accounts</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Description</Typography></TableCell>
                    <TableCell />
                    <TableCell align="right"><Typography variant="subtitle2">Amount</Typography></TableCell>
                    {!batchChange && (
                    <TableCell>
                      <Typography variant="subtitle2" align="right">
                        Action
                      </Typography>
                    </TableCell>
                    )}
                  </TableRow>
                  {journals.map((journal) => (
                    <TableRow key={journal.accountLine.id} className="noprintbreak">
                      {batchChange && (
                        <TableCell>
                          <Checkbox
                            onChange={
                              (event, value) => this.getBatchTransactions(event, value, journal)
                            }
                            checked={
                              !!batchChangeTargets.find(
                                (target) => target === journal.id,
                              )
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {moment(journal.date).format('M/D/YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" className={classes.truncateOneLine} gutterBottom>
                          {journal.accountLine.account.name}
                        </Typography>
                        <Typography variant="caption" className={classes.truncateOneLine} component="div">
                          {this.splitAccount(journal)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                          {journal.description ? journal.description : '---'}
                        </Typography>
                        {journal.entityId !== null && journal.propertyId === null && (
                          <Typography variant="caption" component="div">
                            {journal.entity.name}
                          </Typography>
                        )}
                        {journal.propertyId !== null && (
                          <Typography variant="caption" component="div">
                            {journal.property.address1}
                          </Typography>
                        )}
                        {journal.unitId !== null && (
                          <Typography variant="caption" component="div">
                            {journal.unit.name}
                          </Typography>
                        )}
                        {journal.vendorId !== null && (
                          <Typography variant="caption" component="div">
                            {journal.vendor.name}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {journal.attachmentURL ? (
                          <Button
                            className={classes.tagButton}
                            aria-label="attachments"
                            href={journal.attachmentURL.replace('~1/', '~1/nth/0/')}
                            target="_blank"
                          >
                            <AttachFileIcon fontSize="small" />
                          </Button>
                        ) : <div className={classes.tagSpacer} />}
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          displayType="text"
                          value={journal.accountLine.type === 'credit'
                            ? journal.accountLine.credit
                            : journal.accountLine.debit}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                          renderText={
                            (value) => this.formatNegative(value, journal.accountLine)
                          }
                        />
                      </TableCell>
                      {!batchChange && (
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              this.setState({
                                journalId: journal.id,
                                viewBookedTransactionDialog: true,
                              });
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter className="noprint">
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalJournals}
                      page={filter.page}
                      rowsPerPage={50}
                      onPageChange={this.handleChangePage}
                      rowsPerPageOptions={[50]}
                      ActionsComponent={TablePaginationButtons}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Expenses.contextType = PersonContext;

Expenses.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Expenses);
