import { createTheme } from '@material-ui/core/styles';

import { FONT_SIZES, SPACES } from '~/constants/themes';

import theme from './theme';

const palette = {
  ...theme.palette,
  background: { default: '#FFFFFF', paper: '#FFFFFF', secondary: '#FAFAFA' },
  border: { dark: '#000', default: '#0000003A' },
  divider: '#0000001F',
  primary: { contrast: '#F0E4F0', main: '#431C5D', dark: '#2E1341' },
  success: { dark: '#1B5E20', light: '#4CAF50', main: '#2E7D32' },
  text: { primary: '#000000DE', secondary: '#00000099' },
};

export const altTheme = createTheme({
  ...theme,
  borderRadius: {
    sm: '4px',
  },
  font: {
    size: FONT_SIZES,
    weight: {
      bold: 700,
    },
  },
  icon: {
    size: {
      lg: '36px',
      xlg: '44px',
    },
  },
  overrides: {
    ...theme.overrides,
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: palette.text.secondary,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 600,
      },
    },
  },
  palette,
  radius: {
    md: '8px',
  },
  space: SPACES,
});

export default altTheme;
