import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';

import authenticateTheme from '~/themes/authenticateTheme';

const useStyles = makeStyles((theme) => ({
  buyNowAppBar: {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  buyNowToolBar: {
    minHeight: '60px',
    color: 'white',
  },
  buyNowText: {
    width: '100%',
    fontWeight: 700,
  },
  buyNowLink: {
    color: '#FFEB3B',
    fontWeight: 700,
    textDecoration: 'underline',

    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
    },
  },
  buyNowSavings: {
    [theme.breakpoints.up('md')]: {
      color: '#FFEB3B',
    },
  },
}));

export default function SkipTrialBanner({
  onBuyNowClick,
}) {
  const classes = useStyles(authenticateTheme);

  return (
    <AppBar position="fixed" className={classes.buyNowAppBar}>
      <Toolbar className={classes.buyNowToolBar}>
        <Typography
          align="center"
          variant="body1"
          className={classes.buyNowText}
        >
          {'Skip the trial and '}
          <span className={classes.buyNowSavings}>SAVE 50%</span>
          {' on your first 3 months.'}
          <Link
            href="/select-pricing"
            className={classes.buyNowLink}
            onClick={() => onBuyNowClick()}
          >
            {'BUY NOW AND SAVE >>'}
          </Link>
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

SkipTrialBanner.propTypes = {
  onBuyNowClick: PropTypes.func,
};

SkipTrialBanner.defaultProps = {
  onBuyNowClick: () => {},
};
