import React from 'react';
import {
  Button,
  createTheme,
} from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';

const buttonTheme = createTheme({
  typography: {
    fontSize: 16,
    fontFamily: '"Open Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#431C5D',
      secondary: '#DCDCDC',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 600,
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  buttonLabelContainer: {
    color: '#F0E4F0',
  },
  buttonLabelContainerDisabled: {
    color: '#A3A3A3',
  },
}));

export default function PurpleButton({
  variant, onClick, children, ...rest
}) {
  const classes = useStyles();

  const getLabelClass = () => {
    if (rest.disabled) {
      return classes.buttonLabelContainerDisabled;
    }
    return classes.buttonLabelContainer;
  };

  return (
    <ThemeProvider theme={buttonTheme}>
      <Button
        color={rest.disabled ? 'secondary' : 'primary'}
        variant={variant}
        type="submit"
        size="large"
        fullWidth
        disableElevation={variant === 'contained'}
        onClick={onClick}
        {...rest}
      >
        <div className={variant === 'contained' ? getLabelClass() : null}>
          {children}
        </div>
      </Button>
    </ThemeProvider>
  );
}

PurpleButton.defaultProps = {
  children: null,
  onClick: () => { },
};

PurpleButton.propTypes = {
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
