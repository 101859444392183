import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import {
  create,
} from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
} from '../functions/InputHandlers';

import UpdateSubscriptionDialog from './UpdateSubscriptionDialog';

class AddUnitDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      key: null,
      loading: true,
      updateSubscriptionDialog: false,
    };
  }

  setInitialState = async () => {
    const { partnerSubscribed, adminLogin } = this.context;

    if (partnerSubscribed && !adminLogin) {
      const initialState = {
        loading: false,
        key: 'partnerSubscribed',
      };
      this.setState(initialState);
      return;
    }

    this.checkSubscription();
  };

  checkSubscription = async () => {
    const { organizationId, subscriptionMaxUnits } = this.context;

    const unitCount = await create(
      this,
      'reports',
      {
        organizationId,
        reportName: 'unitCount',
      },
    );

    if (subscriptionMaxUnits && (unitCount + 1) > subscriptionMaxUnits) {
      this.setState({
        loading: false,
        key: 'updateSubscriptionPrompt',
        newUnitCount: unitCount + 1,
      });
      return;
    }

    this.setState({
      loading: false,
      submitting: false,
      key: 'unitInfo',
      unit: {
        name: '',
      },
    });
  };

  saveUnit = async (event) => {
    event.preventDefault();
    const { organizationId } = this.context;
    const {
      submitting, unit,
    } = this.state;
    const { propertyId, onAddUnit } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    const unitSubmit = { ...unit };
    unitSubmit.organizationId = organizationId;
    unitSubmit.propertyId = propertyId;

    create(this, 'units', unitSubmit)
      .then(() => {
        onAddUnit();
        this.closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  getDialogContent = (key) => {
    const {
      unit, error,
    } = this.state;
    const { partnerName } = this.context;

    switch (key) {
      case 'updateSubscriptionPrompt':
        return (
          <>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Please update your subscription plan
                </Typography>
              </Box>
              <Typography variant="body1">
                {`Adding additional units requires updating your subscription to REI Hub. 
                  Please click below to choose a plan or see our `}
                <Link href="https://www.reihub.net/pricing" target="_blank" color="secondary">
                  pricing page
                </Link>
                {' for more information.'}
              </Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                <Button
                  onClick={() => this.setState({ updateSubscriptionDialog: true })}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Update Subscription
                </Button>
                <Button
                  color="primary"
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </DialogContent>
          </>
        );
      case 'unitInfo':
        return (
          <>
            <DialogContent>
              <form onSubmit={this.saveUnit} autoComplete="off">
                <Box mx="auto" mb={2}>
                  <Typography variant="h6" gutterBottom>
                    What is the unit&apos;s name?
                  </Typography>
                </Box>
                <TextField
                  label="Apt, Fl, Unit, Etc."
                  fullWidth
                  required
                  margin="dense"
                  InputProps={{
                    value: unit.name,
                    name: 'nested_unit_name',
                    onChange: handleTextFieldChange(this),
                  }}
                />
                <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
                  <Typography color="error">{error && error.message}</Typography>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>
            </DialogContent>
          </>
        );
      case 'partnerSubscribed':
        return (
          <>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Partner Portfolio
                </Typography>
              </Box>
              <Typography variant="body1">
                {`Your units are synced to REI Hub from our partner ${partnerName}. 
                Please visit their site to add additional units.`}
              </Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Button
                  onClick={this.closeDialog}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Close
                </Button>
              </Box>
            </DialogContent>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    const {
      loading, key, updateSubscriptionDialog, newUnitCount,
    } = this.state;

    if (updateSubscriptionDialog) {
      return (
        <UpdateSubscriptionDialog
          isOpen
          newUnitCount={newUnitCount}
          closeDialog={() => {
            this.setState({ updateSubscriptionDialog: false });
          }}
        />
      );
    }

    return (
      <Dialog
        open
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && this.getDialogContent(key)}
      </Dialog>
    );
  }
}

AddUnitDialog.contextType = PersonContext;

AddUnitDialog.propTypes = {
  propertyId: PropTypes.node.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddUnit: PropTypes.func.isRequired,
};

export default AddUnitDialog;
