import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Dialog, DialogContent, makeStyles, Typography,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useSegmentTrack } from '../functions/SegmentFunctions';
import PurpleButton from './StyledComponents/PurpleButton';

const styles = makeStyles(() => ({
  closeIcon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    color: 'white',
  },
}));

export default function SkipForNowAccountsDialog({
  isOpen,
  closeDialog,
  onConfirm,
}) {
  const classes = styles();
  const tracking = useSegmentTrack();
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          closeDialog();
        }
      }}
      maxWidth="sm"
      id="skip-for-now-dialog"
      aria-describedby="skip-for-now-dialog"
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ClearIcon
            className={classes.closeIcon}
            onClick={() => {
              tracking(
                'close clicked',
                { location: 'Onboarding - Accounts: Are You Sure Modal' },
              );
              closeDialog();
            }}
          />
        </Box>
        <Box
          style={{
            backgroundColor: 'white',
            marginRight: 25,
            marginLeft: 25,
          }}
        >
          <DialogContent>
            <Box
              marginX="auto"
              marginBottom={2}
              textAlign="center"
              marginTop={2}
            >
              <Typography variant="h6" gutterBottom>
                {'Are you sure you don\'t want to link any accounts?'}
              </Typography>
              <Box marginLeft={1} marginBottom={1} textAlign="left">
                <Typography variant="body1">
                  Creating an automatic feed for your transactions is important for quick and
                  easy bookkeeping.
                </Typography>
              </Box>
              <Box
                maxWidth="400px"
                marginX="auto"
                textAlign="center"
                marginBottom={1}
                marginTop={3}
              >
                <PurpleButton
                  variant="contained"
                  onClick={() => {
                    tracking(
                      'go_back clicked',
                      { location: 'Onboarding - Accounts: Are You Sure Modal' },
                    );
                    closeDialog();
                  }}
                >
                  Go Back
                </PurpleButton>
                <Box marginTop={1}>
                  <PurpleButton
                    variant="text"
                    onClick={() => {
                      tracking(
                        'dont_link_any_accounts clicked',
                        { location: 'Onboarding - Accounts: Are You Sure Modal' },
                      );
                      closeDialog();
                      onConfirm();
                    }}
                  >
                    {'Don\'t Link Any Accounts'}
                  </PurpleButton>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </>
    </Dialog>
  );
}

SkipForNowAccountsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
};
