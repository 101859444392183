import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useOnboardingContext } from '~/contexts/OnboardingProvider';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

import useUpdateUserOnboarding from '../hooks/useUpdateUserOnboarding';

const SEGMENT_LOCATION = 'Onboarding - Questions: Previous Process';
const PREVIOUS_PROCESS = {
  SPREADSHEETS: 'SPREADSHEETS',
  QUICKBOOKS: 'QUICKBOOKS',
  NO_PROCESS: 'NO_PROCESS',
  OTHER_SOFTWARE: 'OTHER_SOFTWARE',
};

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.space.lg,
    width: '100%',
    maxWidth: '600px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginBottom: theme.space.xsm,
  },
  textField: {
    marginTop: theme.space.sm,
  },
  submitButton: {
    marginBottom: theme.space.xsm,
    marginInline: 'auto',
    marginTop: theme.space.lg,
    color: theme.palette.primary.contrast,
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
  backButton: {
    marginInline: 'auto',
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
  },
}));

export default function PreviousProcess() {
  const [previousProcess, setPreviousProcess] = useState('');
  const [otherSoftware, setOtherSoftware] = useState('');

  const { userOnboarding } = useOnboardingContext();
  useEffect(() => {
    if (userOnboarding?.processBeforeReiHub) {
      setPreviousProcess(userOnboarding.processBeforeReiHub);
    }
    if (userOnboarding?.otherSoftwareOpenField) {
      setOtherSoftware(userOnboarding.otherSoftwareOpenField);
    }
  }, [userOnboarding?.processBeforeReiHub, userOnboarding?.otherSoftwareOpenField]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const tracking = useSegmentTrack();
  const updateUserOnboarding = useUpdateUserOnboarding();
  const history = useHistory();
  const { triggerSnackbar } = useContext(PersonContext);

  async function handleSubmit(event) {
    try {
      event.preventDefault();

      if (isSubmitting) {
        return;
      }
      setIsSubmitting(true);

      await updateUserOnboarding({
        processBeforeReiHub: previousProcess,
        otherSoftwareOpenField:
          previousProcess === PREVIOUS_PROCESS.OTHER_SOFTWARE ? otherSoftware : '',
      });

      tracking('next clicked', { location: SEGMENT_LOCATION });

      history.push('/portfolio-setup');
    } catch (error) {
      setIsSubmitting(false);
      triggerSnackbar({
        message: 'Something went wrong. Please try again.',
        type: 'error',
      });
    }
  }

  function handleBack() {
    tracking('go_back clicked', { location: SEGMENT_LOCATION });
    history.goBack();
  }

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Typography id="manage-rentals-title" variant="h6" className={classes.title}>
          How do you currently keep track of your books?
        </Typography>

        <RadioGroup
          aria-labelledby="manage-rentals-title"
          name="previousProcess"
          required
          value={previousProcess}
          onChange={(event) => setPreviousProcess(event.target.value)}
        >
          <FormControlLabel
            control={<Radio required />}
            label="Spreadsheets"
            value={PREVIOUS_PROCESS.SPREADSHEETS}
          />
          <FormControlLabel
            control={<Radio required />}
            label="Quickbooks"
            value={PREVIOUS_PROCESS.QUICKBOOKS}
          />
          <FormControlLabel
            control={<Radio required />}
            label="I don't have a process"
            value={PREVIOUS_PROCESS.NO_PROCESS}
          />
          <FormControlLabel
            control={<Radio required />}
            label="Another software"
            value={PREVIOUS_PROCESS.OTHER_SOFTWARE}
          />
        </RadioGroup>

        {previousProcess === PREVIOUS_PROCESS.OTHER_SOFTWARE && (
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="What other software?"
            required={previousProcess === PREVIOUS_PROCESS.OTHER_SOFTWARE}
            size="medium"
            value={otherSoftware}
            variant="standard"
            onChange={(event) => setOtherSoftware(event.target.value)}
            className={classes.textField}
          />
        )}

        <Button
          color="primary"
          disabled={isSubmitting}
          size="large"
          type="submit"
          variant="contained"
          className={classes.submitButton}
        >
          Next
        </Button>
        <Button
          color="secondary"
          disabled={isSubmitting}
          size="large"
          variant="outlined"
          onClick={() => handleBack()}
          className={classes.backButton}
        >
          Go back
        </Button>
      </form>
    </Card>
  );
}
