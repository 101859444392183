import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {
  handleChange,
  asyncHandleChange,
} from '../functions/InputHandlers';

import { find } from '../feathersWrapper';
import client from '../feathers';
import { PersonContext } from '../contexts/PersonContext';

import GraphsCard from './cards/GraphsCard';
import QuicklinksCard from './cards/QuicklinksCard';
import ValueCard from './cards/ValueCard';
import ExpensesCard from './cards/ExpensesCard';
import NotesCard from './cards/NotesCard';
import BookValueCard from './cards/BookValueCard';
import LeasesCard from './cards/LeasesCard';

import FilterDialog from '../components/FilterDialog';
import CardBase from '../components/CardBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';
import LinkBase from '../components/LinkBase';

const styles = {};

class Property extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const { match } = this.props;
    const { organizationId } = this.context;
    const propertiesService = client.service('properties');

    const { propertyId } = match.params;

    // launch async calls
    const propertyPromise = propertiesService.get(propertyId);

    const unitsPromise = find(this, 'units', {
      query: {
        organizationId,
        propertyId,
        inactive: { $or: [null, false] },
        $limit: 500,
        $sort: {
          name: 1,
        },
      },
    });

    // resolve async results
    const property = await propertyPromise;
    const units = await unitsPromise;
    const collator = new Intl.Collator('en', { numeric: true });
    let propertyManager = null;

    if (property.propertyManagerId) {
      const propertyManagementAccounts = await find(this, 'accounts', {
        query: {
          organizationId,
          propertyManagerId: property.propertyManagerId,
        },
      });
      if (propertyManagementAccounts.data) {
        [propertyManager] = propertyManagementAccounts.data;
      }
    }

    this.setState({
      property,
      unitCount: units.total,
      units: units.data.sort((a, b) => collator.compare(a.name, b.name)).splice(0, 5),
      propertyManager,
      filterDialog: false,
      filter: {
        startDate: null,
        endDate: null,
        startDateSelect: null,
        endDateSelect: null,
      },
      stale: {
        notes: false,
        expenses: false,
        cashflow: false,
      },
      loading: false,
    });
  }

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.setState({ stale: { cashflow: true, expenses: true } });
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  actionButtons = () => {
    const { property } = this.state;
    return (
      [
        { text: 'Edit', link: `/properties/property/${property.id}/edit`, class: 'edit' },
      ]
    );
  }

  render() {
    const { match, theme } = this.props;
    const {
      property, filter, stale, loading, filterDialog,
      units, unitCount,
      propertyManager,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          actionButtons={this.actionButtons()}
        >
          <Typography variant="h5">
            {property.address1}
          </Typography>
          {propertyManager && (
            <LinkBase to={`/accounts/property-managers/${propertyManager.id}`}>
              <Typography variant="subtitle1">{propertyManager.name}</Typography>
            </LinkBase>
          )}
          {property.entityId && (
            <LinkBase to={`/subportfolios/subportfolio/${property.entityId}`}>
              <Typography variant="subtitle1">{property.entity.name}</Typography>
            </LinkBase>
          )}
        </PageHeader>
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          dateRange
        />
        <GraphsCard
          view="property"
          id={property.id}
        />
        <QuicklinksCard
          markStale={this.markStale}
          view="property"
          id={property.id}
        />
        <ValueCard
          view="property"
          id={property.id}
        />
        {property.unitSelection === 'single' && (
          <LeasesCard
            view="property"
            id={property.id}
          />
        )}
        {property.unitSelection === 'multi' && (
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <CardBase>
              <Box display="flex" flexDirection="column" height={1} px={2} pt={2} pb={3}>
                <Typography variant="h6" gutterBottom>
                  Units
                </Typography>
                <Typography variant="h4">
                  {unitCount}
                </Typography>
                <Typography variant="subtitle2">&nbsp;</Typography>
                {units.map((unit) => (
                  <Box borderBottom={`1px solid ${theme.palette.divider}`} paddingBottom={2} key={unit.id}>
                    <LinkBase to={`/properties/property/${property.id}/unit/${unit.id}`}>
                      <Typography variant="subtitle2">
                        {unit.name}
                      </Typography>
                    </LinkBase>
                  </Box>
                ))}
                <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="flex-end">
                  <LinkBase to={`/properties/property/${property.id}/units`}>
                    <Typography variant="body2">{`View All Units (${unitCount})`}</Typography>
                  </LinkBase>
                </Box>
              </Box>
            </CardBase>
          </Grid>
        )}
        <ExpensesCard
          stale={stale.expenses}
          removeStale={this.removeStale}
          view="property"
          id={property.id}
          startDate={filter.startDate ? filter.startDate : undefined}
          endDate={filter.endDate ? filter.endDate : undefined}
        />
        <BookValueCard
          view="property"
          id={property.id}
        />
        <NotesCard
          stale={stale.notes}
          removeStale={this.removeStale}
          view="property"
          id={property.id}
        />
      </PageGrid>
    );
  }
}

Property.contextType = PersonContext;

Property.propTypes = {
  match: PropTypes.objectOf(PropTypes.any).isRequired,
  theme: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default withStyles(styles, { withTheme: true })(Property);
