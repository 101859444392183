import Box from '@material-ui/core/Box';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import CustomStepper from '~/components/CustomStepper';
import { checkIsSmallScreen } from '~/functions/ScreenSizeFunctions';

import authenticateTheme from '~/themes/authenticateTheme';

const useStyles = makeStyles({
  outerContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  logoContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
    width: '100%',
    textAlign: 'center',
  },
  contentContainer: {
    width: '100%',
    maxWidth: '900px',
    flexGrow: 1,
  },
  stepperContainer: {
    maxWidth: '900px',
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
});

export default function GrayLayout({ children }) {
  const classes = useStyles();
  const location = useLocation();

  const checkIfStepperShouldBeDisplayed = () => {
    if (!checkIsSmallScreen()) {
      if (location.pathname === '/register-profile' || location.pathname === '/checkout') {
        return true;
      }
    }
    return false;
  };

  return (
    <MuiThemeProvider theme={authenticateTheme}>
      <Box className={classes.outerContainer}>
        <Box className={classes.logoContainer}>
          <img src="/rei_hub_logo_purple.svg" height="54px" alt="REI Hub" className={classes.logo} />
        </Box>
        {checkIfStepperShouldBeDisplayed() && (
          <Box className={classes.stepperContainer}>
            <CustomStepper
              orientation="horizontal"
              stepProps={[
                {
                  label: 'Pricing',
                  url: '/pricing',
                },
                {
                  label: 'Create Profile',
                  url: '/register-profile',
                },
                {
                  label: 'Review & Pay',
                  url: '/checkout',
                },
              ]}
              allCompletedUrl="/dashboard"
            />
          </Box>
        )}
        <Box className={classes.contentContainer}>
          {children}
        </Box>
      </Box>
    </MuiThemeProvider>
  );
}

GrayLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
