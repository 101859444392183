import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Card } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { create, patchMultiple } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import {
  addressLabel,
  getPropertyOptions,
} from './Autocomplete/Library';

import {
  handleTextFieldChange,
  handleAutocompleteChange,
} from '../functions/InputHandlers';
import PurpleButton from './StyledComponents/PurpleButton';

const styles = () => ({
  onboardingCard: {
    width: '100%',
    maxWidth: '600px',
  },
});

class AddSubportfolioDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      key: 'name',
      subportfolio: {
        name: '',
        properties: [],
      },
    };
    initialState.propertyOptions = await getPropertyOptions(this);
    this.setState(initialState);
  }

  processName = (event) => {
    event.preventDefault();
    this.setState({ key: 'propertySelection' });
  };

  addSubportfolio = async () => {
    const {
      submitting, subportfolio,
    } = this.state;

    const { organizationId } = this.context;
    const { onAdd, closeDialog } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    // eslint-disable-next-line prefer-object-spread
    const submitSubportfolio = Object.assign({}, subportfolio, { organizationId });

    create(this, 'entities', submitSubportfolio)
      .then(async (result) => {
        if (subportfolio.properties.length > 0) {
          await patchMultiple(
            this,
            'properties',
            {
              query: {
                organizationId,
                id: subportfolio.properties.map((property) => property.id),
              },
            },
            { entityId: result.id },
          );
          await patchMultiple(
            this,
            'journals',
            {
              query: {
                organizationId,
                propertyId: subportfolio.properties.map((property) => property.id),
              },
            },
            { entityId: result.id },
          );
          await patchMultiple(
            this,
            'accounts',
            {
              query: {
                organizationId,
                propertyId: subportfolio.properties.map((property) => property.id),
              },
            },
            { entityId: result.id },
          );
        }
        await onAdd(result);
        closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  getDialogContent = (key) => {
    const { closeDialog } = this.props;
    const {
      subportfolio,
      propertyOptions, error,
    } = this.state;

    switch (key) {
      case 'name':
        return (
          <DialogContent>
            <form onSubmit={this.processName}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  What is the sub-portfolio&apos;s name?
                </Typography>
              </Box>
              <TextField
                label="Name"
                fullWidth
                margin="dense"
                required
                InputProps={{
                  value: subportfolio.name,
                  name: 'nested_subportfolio_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <PurpleButton
                  variant="contained"
                >
                  Next
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'propertySelection':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="caption" gutterBottom>
                {subportfolio.name}
              </Typography>
              <Typography variant="h6" gutterBottom>
                (Optional) Select the properties in this sub-portfolio.
              </Typography>
            </Box>
            <Autocomplete
              multiple
              options={propertyOptions}
              getOptionLabel={addressLabel}
              value={subportfolio.subportfolio}
              onChange={handleAutocompleteChange(
                'nested_subportfolio_properties',
                this,
              )}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                  margin="dense"
                  label="Included Properties"
                  placeholder="Type to Search"
                  fullWidth
                />
              )}
            />
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                {`
                      You can select multiple properties using the selector above.
                      Each property can be in only one sub-portfolio.
                    `}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <Typography color="error">{error && error.message}</Typography>
              <PurpleButton
                variant="contained"
                onClick={this.addSubportfolio}
              >
                Add Sub-Portfolio
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  render() {
    const { classes } = this.props;
    const { showWelcome } = this.context;
    const {
      loading, key,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        {!showWelcome && (
          <Dialog
            open
            maxWidth="sm"
            fullWidth
            scroll="body"
            disableBackdropClick
            disableEscapeKeyDown
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {this.getDialogContent(key)}
          </Dialog>
        )}
        {showWelcome && (
          <Card
            open
            className={classes.onboardingCard}
          >
            {this.getDialogContent(key)}
          </Card>
        )}
      </>
    );
  }
}

AddSubportfolioDialog.contextType = PersonContext;

AddSubportfolioDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddSubportfolioDialog);
