import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import withStyles from '@material-ui/core/styles/withStyles';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LinkIcon from '@material-ui/icons/Link';
import Button from '@material-ui/core/Button';
import { Checkbox } from '@material-ui/core';

import { find, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleChange, asyncHandleChange,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
} from '../functions/FilterFunctions';

import CardBase from '../components/CardBase';
import PageGrid from '../components/PageGrid';
import PageHeader from '../components/PageHeader';

import PartnerGraphCard from './cards/PartnerGraphCard';
import PartnerQuicklinksCard from './cards/PartnerQuicklinksCard';
import ViewImportedTransactionDialog from '../components/ViewImportedTransactionDialog';
import ViewBookedTransactionDialog from '../components/ViewBookedTransactionDialog';
import FilterDialog from '../components/FilterDialog';
import FilterCard from './cards/FilterCard';
import { TablePaginationButtons } from '../functions/TableFunctions';
import BatchChangeCard from './cards/BatchChangeCard';
import BatchChangeImportedTransactionDialog from '../components/BatchChangeImportedTransactionDialog';

const styles = (theme) => ({
  red: {
    color: 'red',
  },
  tagButton: {
    padding: 0,
    color: theme.palette.action.active,
    minWidth: '32px',
  },
  tagSpacer: {
    minWidth: '32px',
    display: 'inline-block',
  },
  truncateOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

class Partner extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterJournalsDialog: false,
      selectedJournalId: null,
      viewImportedTransactionDialog: false,
      yodleeTransactionId: null,
      showBalance: false,
      startingPageBalance: {},
      stale: {
        journals: false,
        graph: false,
      },
      filter: {
        startDate: null,
        endDate: null,
        propertyId: null,
        unitId: null,
        transactionType: null,
        partnerAccountId: null,
        description: '',
        startDateSelect: null,
        endDateSelect: null,
        propertyIdSelect: { address1: 'All Properties', id: 'All' },
        unitIdSelect: null,
        transactionTypeSelect: null,
        partnerAccountIdSelect: { name: 'All Accounts', id: 'All' },
        page: 0,
        pageSize: 50,
      },
      batchChangeImportedTransactionDialog: false,
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
      batchPages: [],
    };
  }

  async componentDidMount() {
    const { organizationId } = this.context;

    const partnerAccounts = await find(this, 'accounts', {
      query: {
        organizationId,
        type2: 'Partner',
      },
    });
    const accountIds = partnerAccounts.data.map((account) => account.id);
    await asyncHandleChange('accountIds', accountIds, this);

    await parseQuery(this);

    await this.getJournals();

    this.setState({
      loading: false,
    });
  }

  componentDidUpdate() {
    const { stale } = this.state;
    if (stale.journals) {
      this.getJournals();
      this.removeStale('journals');
    }
  }

  getJournals = async () => {
    const { organizationId, basis } = this.context;
    const { filter, accountIds } = this.state;

    const query = Object.assign(buildQuery(this), {
      basis,
      includeFullJournals: true,
      $sort: {
        date: -1,
        amount: -1,
      },
    });

    let showBalance = true;
    if (!query.accountId) {
      query.accountId = accountIds;
      showBalance = false;
    }

    if (filter.propertyId || filter.unitId
      || filter.transactionType || filter.description) {
      showBalance = false;
    }

    const journalLinesResponse = await find(this, 'journal-lines', { query });
    const journalLines = journalLinesResponse.data;
    const journals = [];
    journalLines.forEach((journalLine) => {
      const { journal } = journalLine;
      // eslint-disable-next-line no-param-reassign
      journal.creditLines = [];
      // eslint-disable-next-line no-param-reassign
      journal.debitLines = [];
      journal.journalLines.forEach((line) => {
        if (line.id === journalLine.id) {
          // eslint-disable-next-line no-param-reassign
          journal.accountLine = line;
        }

        if (line.type === 'credit') {
          journal.creditLines.push(line);
        } else {
          journal.debitLines.push(line);
        }
      });
      journals.push(journal);
    });

    const { startingPageBalance } = this.state;
    if (showBalance) {
      let startingBalance;
      if (filter.page === 0) {
        const balanceReportQuery = {
          organizationId,
          basis,
          accountId: query.accountId,
          reportName: 'accountJournalTotals',
        };
        if (filter.endDate) {
          balanceReportQuery.endDate = filter.endDate;
        }
        const balanceReport = await create(this, 'reports', { ...balanceReportQuery });
        startingPageBalance[filter.page] = -balanceReport[0].netCredits;
      }

      startingBalance = startingPageBalance[filter.page];
      for (let i = 0; i < journals.length; i += 1) {
        journals[i].accountLine.balance = startingBalance;
        if (journals[i].accountLine.type === 'debit') {
          startingBalance -= journals[i].accountLine.debit;
        } else {
          startingBalance += journals[i].accountLine.credit;
        }
      }
      startingPageBalance[filter.page + 1] = startingBalance;
    }
    const totalJournals = journalLinesResponse.total;
    this.setState({
      journals,
      totalJournals,
      showBalance,
      startingPageBalance,
    });
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateJournalsFilter(newFilter);
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getJournals();
  };

  closeTransactionDialogs = () => {
    this.setState({
      selectedJournalId: null,
      viewImportedTransactionDialog: false,
      filterJournalsDialog: false,
    });
  };

  updateJournalsFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.markStale('graph');
    this.getJournals();
  };

  markStale = (area) => {
    handleChange(`nested_stale_${area}`, true, this);
  };

  removeStale = (area) => {
    handleChange(`nested_stale_${area}`, false, this);
  };

  splitAccount = (journal) => {
    if (journal.accountLine.type === 'credit') {
      if (journal.debitLines.length === 1) {
        return journal.debitLines[0].account.name;
      }
      return 'Split';
    }
    if (journal.creditLines.length === 1) {
      return journal.creditLines[0].account.name;
    }
    return 'Split';
  };

  formatNegative = (value, accountLine) => {
    const { classes } = this.props;
    if (accountLine.type === 'credit') {
      return (
        <span className={classes.red}>
          {`(${value})`}
        </span>
      );
    }
    return value;
  };

  formatNegativeBalance = (value) => {
    const { classes } = this.props;
    if (value[0] === '-') {
      return (
        <span className={classes.red}>
          {`(${value.substring(1)})`}
        </span>
      );
    }
    return value;
  };

  setBatchEditState = () => {
    const { batchChange } = this.state;
    if (batchChange === true) {
      this.setState({
        batchChange: false,
        batchChangeTargets: [],
        fullBatchChangeTargets: [],
        batchPages: [],
        batchType: null,
      });
    } else {
      this.setState({ batchChange: true });
    }
  };

  cancelBatchChange = () => {
    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchType: null,
    });
  };

  addAllPageTransactionsToBatch = (event, value) => {
    const {
      journals, batchChangeTargets, filter, batchPages, fullBatchChangeTargets,
    } = this.state;

    const batchArray = [];
    const fullBatchArray = [];

    journals.forEach((journal) => {
      batchArray.push(journal.id);
      fullBatchArray.push(journal);
    });

    if (value) {
      const newBatchTargets = [
        ...new Set([...batchChangeTargets, ...batchArray]),
      ];
      const newFullBatchTargets = [
        ...new Set([...fullBatchChangeTargets, ...fullBatchArray]),
      ];
      const newBatchPage = [...batchPages, filter.page];

      this.setState({
        batchChangeTargets: newBatchTargets,
        fullBatchChangeTargets: newFullBatchTargets,
        batchPages: newBatchPage,
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter(
          (target) => !batchArray.includes(target),
        ),
        fullBatchChangeTargets: fullBatchChangeTargets.filter(
          (target) => !fullBatchArray.includes(target),
        ),
        batchPages: batchPages.filter((page) => page !== filter.page),
      });
    }
  };

  getBatchTransactions = (event, value, transaction) => {
    const { batchChangeTargets, fullBatchChangeTargets } = this.state;
    if (value) {
      this.setState({
        batchChangeTargets: [...batchChangeTargets, transaction.id],
        fullBatchChangeTargets: [...fullBatchChangeTargets, transaction],
      });
    } else {
      this.setState({
        batchChangeTargets: batchChangeTargets.filter(
          (target) => target !== transaction.id,
        ),
        fullBatchChangeTargets: fullBatchChangeTargets.filter(
          (target) => target.id !== transaction.id,
        ),
      });
    }
  };

  closeBatchChangeImportedTransactionDialog = () => {
    this.setState({
      batchChangeImportedTransactionDialog: false,
    });
  };

  onBatchChangeTransactionsDialog = () => {
    this.getJournals();

    this.setState({
      batchChange: false,
      batchChangeTargets: [],
      fullBatchChangeTargets: [],
      batchPages: [],
      batchType: null,
    });
  };

  openBatchChangeImportedTransactionDialog = () => {
    this.setState({ batchChangeImportedTransactionDialog: true });
  };

  actionButtons = () => (
    [
      // { text: 'Export', action: () => this.setState({ downloadDialog: true }), class: 'export' },
      { text: 'Batch Edit', action: () => this.setBatchEditState(), class: 'edit' },
    ]
  );

  render() {
    const { classes, match } = this.props;
    const { partnerName } = this.context;
    const {
      loading,
      journals,
      totalJournals,
      filter,
      selectedJournalId,
      filterJournalsDialog,
      viewImportedTransactionDialog,
      yodleeTransactionId,
      stale,
      showBalance,
      batchChange,
      batchChangeTargets,
      fullBatchChangeTargets,
      batchChangeImportedTransactionDialog,
      batchType,
      batchPages,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <PageGrid>
        <PageHeader
          match={match}
          title={partnerName}
          // appliedFilter={appliedFilter(this.updateJournalsFilter, this)}
          actionButtons={this.actionButtons()}
        />
        {selectedJournalId && (
          <ViewBookedTransactionDialog
            closeDialog={this.closeTransactionDialogs}
            onTransactionModified={this.getJournals}
            journalId={selectedJournalId}
          />
        )}
        <ViewImportedTransactionDialog
          yodleeTransactionId={yodleeTransactionId}
          isOpen={viewImportedTransactionDialog}
          closeDialog={this.closeTransactionDialogs}
          onUnmatchTransaction={this.getJournals}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterJournalsDialog}
          closeDialog={this.closeTransactionDialogs}
          updateFilter={this.updateJournalsFilter}
          dateRange
          transactionType
          partnerAccount
          property
          unit
          description
        />
        <PartnerGraphCard
          stale={stale.graph}
          removeStale={this.removeStale}
          filter={filter}
        />
        <PartnerQuicklinksCard
          markStale={this.markStale}
          accountId={filter.partnerAccountIdSelect.id}
        />
        <FilterCard
          openFilter={() => this.setState({ filterJournalsDialog: true })}
          updateFilter={this.updateJournalsFilter}
          filter={filter}
          fields={['partnerAccount', 'property']}
        />
        {batchChange && (
          <BatchChangeCard
            openBatchChangeImportedTransactionDialog={
              () => this.setState({ batchChangeImportedTransactionDialog: true })
            }
            transactions={batchChangeTargets}
            cancelBatchChange={this.cancelBatchChange}
            yodleeTransactions={journals}
            screen="Register"
            updateBatchType={(type) => this.setState({ batchType: type })}
          />
        )}
        {batchChangeImportedTransactionDialog && (
          <BatchChangeImportedTransactionDialog
            closeDialog={() => this.closeBatchChangeImportedTransactionDialog()}
            transactions={batchChangeTargets}
            fullTransactions={fullBatchChangeTargets}
            onBatchChangeTransactions={this.onBatchChangeTransactionsDialog}
            batchType={batchType}
            screen="Register"
          />
        )}
        <Grid item xs={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableBody>
                  <TableRow>
                    {batchChange && (
                      <TableCell>
                        <Checkbox
                          onChange={(event, value) => {
                            this.addAllPageTransactionsToBatch(event, value);
                          }}
                          checked={
                              !!batchPages.includes(filter.page)
                              && batchChangeTargets.length > 0
                            }
                        />
                      </TableCell>
                    )}
                    <TableCell><Typography variant="subtitle2">Date</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Type</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Description</Typography></TableCell>
                    <TableCell />
                    <TableCell align="right"><Typography variant="subtitle2">Amount</Typography></TableCell>
                    {!batchChange && (
                      <TableCell align="right">
                        <Typography variant="subtitle2">Action</Typography>
                      </TableCell>
                    )}
                    {showBalance && <TableCell align="right"><Typography variant="subtitle2">Balance</Typography></TableCell>}
                  </TableRow>
                  {journals.map((journal) => (
                    <TableRow key={journal.accountLine.id} className="noprintbreak">
                      {batchChange && (
                        <TableCell>
                          <Checkbox
                            onChange={
                              (event, value) => this.getBatchTransactions(event, value, journal)
                            }
                            checked={
                              !!batchChangeTargets.find(
                                (target) => target === journal.id,
                              )
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell>
                        <Typography variant="body2" gutterBottom>
                          {moment(journal.date).format('M/D/YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                          {journal.type}
                        </Typography>
                        <Typography className={classes.truncateOneLine} variant="caption" component="div">
                          {this.splitAccount(journal)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography className={classes.truncateOneLine} variant="body2" gutterBottom>
                          {journal.description ? journal.description : '---'}
                        </Typography>
                        {journal.entityId !== null && journal.propertyId === null && (
                          <Typography variant="caption" component="div">
                            {journal.entity.name}
                          </Typography>
                        )}
                        {journal.propertyId !== null && (
                          <Typography variant="caption" component="div">
                            {journal.property.address1}
                          </Typography>
                        )}
                        {journal.unitId !== null && (
                          <Typography variant="caption" component="div">
                            {journal.unit.name}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell>
                        {journal.accountLine.yodleeTransactionId ? (
                          <Button
                            className={classes.tagButton}
                            aria-label="imported"
                            onClick={() => this.setState({
                              viewImportedTransactionDialog: true,
                              yodleeTransactionId: journal.accountLine.yodleeTransactionId,
                            })}
                          >
                            <LinkIcon fontSize="small" />
                          </Button>
                        ) : <div className={classes.tagSpacer} />}
                        {journal.attachmentURL ? (
                          <Button
                            className={classes.tagButton}
                            aria-label="attachments"
                            href={journal.attachmentURL.replace('~1/', '~1/nth/0/')}
                            target="_blank"
                          >
                            <AttachFileIcon fontSize="small" />
                          </Button>
                        ) : <div className={classes.tagSpacer} />}
                        {journal.accountLine.reconciled ? (
                          <Button
                            className={classes.tagButton}
                            aria-label="imported"
                            onClick={() => {}}
                          >
                            R
                          </Button>
                        ) : <div className={classes.tagSpacer} />}
                      </TableCell>
                      <TableCell align="right">
                        <NumberFormat
                          displayType="text"
                          value={journal.accountLine.type === 'credit'
                            ? journal.accountLine.credit
                            : journal.accountLine.debit}
                          thousandSeparator
                          prefix="$"
                          decimalScale={2}
                          fixedDecimalScale
                          renderText={
                            (value) => this.formatNegative(value, journal.accountLine)
                          }
                        />
                      </TableCell>
                      {!batchChange && (
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() => {
                              this.setState({
                                selectedJournalId: journal.id,
                              });
                            }}
                          >
                            View
                          </Button>
                        </TableCell>
                      )}
                      {showBalance && (
                        <TableCell align="right">
                          <NumberFormat
                            displayType="text"
                            value={journal.accountLine.balance}
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                            renderText={
                              (value) => this.formatNegativeBalance(value)
                            }
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter className="noprint">
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalJournals}
                      page={filter.page}
                      rowsPerPage={50}
                      onPageChange={this.handleChangePage}
                      rowsPerPageOptions={[50]}
                      ActionsComponent={TablePaginationButtons}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </PageGrid>
    );
  }
}

Partner.contextType = PersonContext;

Partner.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Partner);
