import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NumberFormat from 'react-number-format';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import FormGridContainer from './FormGridContainer';
import FormGridItem from './FormGridItem';

import { PersonContext } from '../contexts/PersonContext';
import {
  handleKeyboardDatePickerChange,
  handleNumberFormatChange,
  handleAutocompleteChange,
  handleTextFieldChange,
  handleCheckboxChange,
} from '../functions/InputHandlers';

import {
  getSubportfolioOptions,
  getPropertyOptions,
  getUnitOptions,
  getFilteredUnitOptions,
  getPeriodOptions,
  getDateRangeOptions,
  getScopeOptions,
  getTransactionTypeOptions,
  getAccountOptions,
  getVendorOptions,
  nameLabel,
  addressLabel,
  getRecieptFilterOptions,
  getReconciliationStatusOptions,
  getDateOptions,
} from './Autocomplete/Library';

class FilterDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true, error: null };
  }

  setInitialState = async () => {
    const {
      filter, dateRange, scope, account,
      revenueAccount, expenseAccount, partnerAccount, vendor, date,
    } = this.props;
    const { vendorTracking } = this.context;
    const initialState = { loading: true, error: null };
    if (filter) {
      initialState.filter = filter;
      initialState.loading = false;
      if (dateRange) {
        initialState.dateRangeSelect = this.parseFilterDateRange();
      }
      if (date) {
        initialState.dateSelect = this.parseFilterDate();
      }
      if (scope.length > 0) {
        initialState.filter.scopeSelect = this.parseFilterScope();
      }
    }

    if (account) {
      initialState.accountOptions = await getAccountOptions(this);
      // initialState.accountOptions.unshift({ name: 'All Accounts', id: 'All' });
    }

    if (revenueAccount) {
      initialState.revenueAccountOptions = await getAccountOptions(this, { type: 'Revenue' });
      initialState.revenueAccountOptions.unshift({ name: 'All Revenues', id: 'All' });
    }

    if (expenseAccount) {
      initialState.expenseAccountOptions = await getAccountOptions(this, { type: 'Expense' });
      initialState.expenseAccountOptions.unshift({ name: 'All Expenses', id: 'All' });
    }

    if (partnerAccount) {
      initialState.partnerAccountOptions = await getAccountOptions(this, { type2: 'Partner' });
      initialState.partnerAccountOptions.unshift({ name: 'All Accounts', id: 'All' });
    }

    if (vendor && vendorTracking) {
      initialState.vendorOptions = await getVendorOptions(this);
      // initialState.vendorOptions.unshift({ name: 'All Vendors', id: 'All' });
    }

    initialState.subportfolioOptions = await getSubportfolioOptions(this);
    initialState.propertyOptions = await getPropertyOptions(this);
    initialState.unitOptions = await getUnitOptions(this);

    this.setState(initialState);
  };

  parseFilterDateRange = () => {
    const { filter } = this.props;
    if (!filter.startDateSelect && !filter.endDateSelect) {
      return null;
    }
    if (!filter.startDateSelect || !filter.endDateSelect) {
      return { name: 'Custom', id: 'Custom' };
    }
    if (filter.endDateSelect.isSame(moment(), 'day')) {
      if (filter.startDateSelect.isSame(moment().startOf('year'), 'day')) {
        return { name: 'This Year to Date', id: 'This Year to Date' };
      }
      if (filter.startDateSelect.isSame(moment().startOf('month'), 'day')) {
        return { name: 'This Month to Date', id: 'This Month to Date' };
      }
    }
    if (filter.endDateSelect.isSame(moment().subtract(1, 'years').endOf('year'), 'day')
      && filter.startDateSelect.isSame(moment().subtract(1, 'years').startOf('year'), 'day')) {
      return { name: 'Last Year', id: 'Last Year' };
    }
    if (filter.endDateSelect.isSame(moment().subtract(1, 'months').endOf('month'), 'day')
      && filter.startDateSelect.isSame(moment().subtract(1, 'months').startOf('month'), 'day')) {
      return { name: 'Last Month', id: 'Last Month' };
    }
    return { name: 'Custom', id: 'Custom' };
  };

  parseFilterDate = () => {
    const { filter } = this.props;
    if (filter.endDateSelect.isSame(moment(), 'day')) {
      return { name: 'Today', id: 'Today' };
    }
    if (filter.endDateSelect.isSame(moment().subtract(1, 'months').endOf('month'), 'day')) {
      return { name: 'Last Month', id: 'Last Month' };
    }
    if (filter.endDateSelect.isSame(moment().subtract(1, 'years').endOf('year'), 'day')) {
      return { name: 'Last Year', id: 'Last Year' };
    }
    return { name: 'Custom', id: 'Custom' };
  };

  parseFilterScope = () => {
    const { filter } = this.props;
    if (filter.unitId) {
      return 'Unit';
    }
    if (filter.propertyId) {
      return 'Property';
    }
    if (filter.entityId) {
      return 'Sub-Portfolio';
    }
    return 'Full Portfolio';
  };

  updateFilter = async (event) => {
    event.preventDefault();
    const { filter, dateRangeSelect, dateSelect } = this.state;
    const {
      updateFilter, closeDialog, required, dateRange, date,
    } = this.props;

    if (required.includes('period') && !filter.periodSelect) {
      return;
    }

    this.setState({ updating: true });

    if (dateRange) {
      if (!dateRangeSelect) {
        filter.startDateSelect = null;
        filter.endDateSelect = null;
      } else if (dateRangeSelect.id === 'This Year to Date') {
        filter.startDateSelect = moment().startOf('year');
        filter.endDateSelect = moment();
      } else if (dateRangeSelect.id === 'This Month to Date') {
        filter.startDateSelect = moment().startOf('month');
        filter.endDateSelect = moment();
      } else if (dateRangeSelect.id === 'Last Year') {
        filter.startDateSelect = moment().subtract(1, 'years').startOf('year');
        filter.endDateSelect = moment().subtract(1, 'years').endOf('year');
      } else if (dateRangeSelect.id === 'Last Month') {
        filter.startDateSelect = moment().subtract(1, 'months').startOf('month');
        filter.endDateSelect = moment().subtract(1, 'months').endOf('month');
      }
      filter.startDate = filter.startDateSelect ? filter.startDateSelect.format('YYYY-MM-DD') : null;
      filter.endDate = filter.endDateSelect ? filter.endDateSelect.format('YYYY-MM-DD') : null;
      if (required.includes('dateRange') && !(filter.startDate && filter.endDate)) {
        return;
      }
    } else if (date) {
      if (!dateSelect) {
        filter.endDateSelect = null;
      } else if (dateSelect.id === 'Today') {
        filter.endDateSelect = moment();
      } else if (dateSelect.id === 'Last Month') {
        filter.endDateSelect = moment().subtract(1, 'months').endOf('month');
      } else if (dateSelect.id === 'Last Year') {
        filter.endDateSelect = moment().subtract(1, 'years').endOf('year');
      }
      filter.endDate = filter.endDateSelect ? filter.endDateSelect.format('YYYY-MM-DD') : null;
      if (required.includes('date') && !filter.endDate) {
        return;
      }
    }

    // check for invalid date
    if ((filter.startDateSelect && !filter.startDateSelect.isValid())
      || (filter.endDateSelect && !filter.endDateSelect.isValid())) {
      return;
    }

    if (filter.scopeSelect && !['Sub-Portfolio'].includes(filter.scopeSelect)) {
      filter.entityIdSelect = null;
    }

    if (filter.scopeSelect && !['Property', 'Unit'].includes(filter.scopeSelect)) {
      filter.propertyIdSelect = null;
    }

    if (filter.scopeSelect && !['Unit'].includes(filter.scopeSelect)) {
      filter.unitIdSelect = null;
    }

    if (filter.entityIdSelect) {
      filter.entityId = filter.entityIdSelect.id;
    } else {
      filter.entityId = null;
    }

    if (filter.propertyIdSelect && filter.propertyIdSelect.id !== 'All') {
      filter.propertyId = filter.propertyIdSelect.id;
      if (filter.propertyIdSelect.unitSelection === 'multi' && filter.unitIdSelect) {
        filter.unitId = filter.unitIdSelect.id;
      } else {
        filter.unitId = null;
        filter.unitIdSelect = null;
      }
    } else {
      filter.propertyId = null;
      filter.unitId = null;
      filter.unitIdSelect = null;
    }
    filter.period = filter.periodSelect ? filter.periodSelect.id : null;
    filter.page = 0;
    const result = await updateFilter(filter);

    if (result && !result.success && result.message) {
      this.setState({ error: { message: result.message } });
    } else {
      this.setState({ updating: false });
      closeDialog();
    }
  };

  render() {
    const {
      isOpen, closeDialog,
      dateRange, scope, period, transactionType, subportfolio, property, unit,
      account, revenueAccount, expenseAccount, partnerAccount, vendor,
      amount, description, proration, mileage, inactive, required,
      receiptStatus, reconciliationStatus, date,
    } = this.props;
    const {
      loading, filter, error,
      subportfolioOptions, propertyOptions, unitOptions,
      accountOptions, revenueAccountOptions, expenseAccountOptions, partnerAccountOptions,
      vendorOptions, dateRangeSelect, dateSelect, updating,
    } = this.state;
    const { vendorTracking } = this.context;

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        maxWidth="sm"
        fullWidth
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.setInitialState}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!loading && (
          <form onSubmit={this.updateFilter}>
            <DialogTitle id="alert-dialog-title">Update Filter</DialogTitle>
            <DialogContent>
              {date && (
                <Autocomplete
                  options={getDateOptions}
                  getOptionLabel={nameLabel}
                  value={dateSelect}
                  onChange={handleAutocompleteChange(
                    'dateSelect',
                    this,
                  )}
                  disableClearable={required.includes('date')}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Date"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('date')}
                    />
                  )}
                />
              )}
              {dateSelect && dateSelect.id === 'Custom' && (
                <FormGridContainer>
                  <FormGridItem xs={12}>
                    <KeyboardDatePicker
                      label="Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={filter.endDateSelect}
                      onChange={handleKeyboardDatePickerChange('nested_filter_endDate', this)}
                      margin="dense"
                      required={required.includes('date')}
                      fullWidth
                      clearable
                    />
                  </FormGridItem>
                </FormGridContainer>
              )}
              {dateRange && (
                <Autocomplete
                  options={getDateRangeOptions}
                  getOptionLabel={nameLabel}
                  value={dateRangeSelect}
                  onChange={handleAutocompleteChange(
                    'dateRangeSelect',
                    this,
                  )}
                  disableClearable={required.includes('dateRange')}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Date Range"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('dateRange')}
                    />
                  )}
                />
              )}
              {dateRangeSelect && dateRangeSelect.id === 'Custom' && (
                <FormGridContainer>
                  <FormGridItem xs={6}>
                    <KeyboardDatePicker
                      label="Start Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={filter.startDateSelect}
                      onChange={handleKeyboardDatePickerChange('nested_filter_startDate', this)}
                      margin="dense"
                      required={required.includes('dateRange')}
                      fullWidth
                      clearable
                    />
                  </FormGridItem>
                  <FormGridItem xs={6}>
                    <KeyboardDatePicker
                      label="End Date"
                      format="MM/DD/YYYY"
                      placeholder="MM/DD/YYYY"
                      value={filter.endDateSelect}
                      onChange={handleKeyboardDatePickerChange('nested_filter_endDate', this)}
                      margin="dense"
                      required={required.includes('dateRange')}
                      fullWidth
                      clearable
                    />
                  </FormGridItem>
                </FormGridContainer>
              )}
              {period && (
                <Autocomplete
                  options={getPeriodOptions}
                  getOptionLabel={nameLabel}
                  value={filter.periodSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_periodSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Reporting Period"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('period')}
                    />
                  )}
                />
              )}
              {transactionType && (
                <Autocomplete
                  options={getTransactionTypeOptions}
                  getOptionLabel={nameLabel}
                  value={filter.transactionTypeSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_transactionTypeSelect',
                    this,
                  )}
                  disableClearable
                  blurOnSelect
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Transaction Type"
                      placeholder="Type to Search"
                      fullWidth
                    />
                  )}
                />
              )}
              {scope.length > 0 && (
                <Autocomplete
                  options={getScopeOptions(this, scope)}
                  value={filter.scopeSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_scopeSelect',
                    this,
                  )}
                  disableClearable={required.includes('scope')}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Report Scope"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('scope')}
                    />
                  )}
                />
              )}
              {(subportfolio || ['Sub-Portfolio'].includes(filter.scopeSelect)) && (
                <Autocomplete
                  options={subportfolioOptions}
                  getOptionLabel={nameLabel}
                  value={filter.entityIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_entityIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable={required.includes('subportfolio') || filter.scopeSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Sub-Portfolio"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('subportfolio') || filter.scopeSelect}
                    />
                  )}
                />
              )}
              {(property || ['Property', 'Unit'].includes(filter.scopeSelect)) && (
                <Autocomplete
                  options={propertyOptions}
                  getOptionLabel={addressLabel}
                  value={filter.propertyIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_propertyIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable={required.includes('property') || filter.scopeSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Property"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('property') || filter.scopeSelect}
                    />
                  )}
                />
              )}
              {(unit || ['Unit'].includes(filter.scopeSelect)) && filter.propertyIdSelect && filter.propertyIdSelect.unitSelection === 'multi' && (
                <Autocomplete
                  options={getFilteredUnitOptions(unitOptions, filter.propertyIdSelect.id)}
                  getOptionLabel={nameLabel}
                  value={filter.unitIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_unitIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable={required.includes('unit') || filter.scopeSelect}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Unit"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('unit') || filter.scopeSelect}
                    />
                  )}
                />
              )}
              {receiptStatus && (
                <Autocomplete
                  options={getRecieptFilterOptions}
                  getOptionLabel={nameLabel}
                  value={filter.receiptStatusSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_receiptStatusSelect',
                    this,
                  )}
                  disableClearable
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Receipt Status"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {reconciliationStatus && (
                <Autocomplete
                  options={getReconciliationStatusOptions}
                  getOptionLabel={nameLabel}
                  value={filter.reconciliationStatusSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_reconciliationStatusSelect',
                    this,
                  )}
                  disableClearable
                  getOptionSelected={(option, value) => option.id === value.id}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Reconciliation Status"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {account && (
                <Autocomplete
                  options={accountOptions}
                  getOptionLabel={nameLabel}
                  value={filter.accountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_accountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable={required.includes('account')}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Account"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('account')}
                    />
                  )}
                />
              )}
              {revenueAccount && (
                <Autocomplete
                  options={revenueAccountOptions}
                  getOptionLabel={nameLabel}
                  value={filter.revenueAccountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_revenueAccountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Revenue Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {expenseAccount && (
                <Autocomplete
                  options={expenseAccountOptions}
                  getOptionLabel={nameLabel}
                  value={filter.expenseAccountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_expenseAccountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Expense Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {partnerAccount && (
                <Autocomplete
                  options={partnerAccountOptions}
                  getOptionLabel={nameLabel}
                  value={filter.partnerAccountIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_partnerAccountIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Partner Payment Account"
                      placeholder="Type to Search"
                      fullWidth
                      required
                    />
                  )}
                />
              )}
              {vendor && vendorTracking && (
                <Autocomplete
                  options={vendorOptions}
                  getOptionLabel={nameLabel}
                  value={filter.vendorIdSelect}
                  onChange={handleAutocompleteChange(
                    'nested_filter_vendorIdSelect',
                    this,
                  )}
                  getOptionSelected={(option, value) => option.id === value.id}
                  disableClearable={required.includes('vendor')}
                  renderInput={(params) => (
                    <TextField
                      {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                      margin="dense"
                      label="Vendor"
                      placeholder="Type to Search"
                      fullWidth
                      required={required.includes('vendor')}
                    />
                  )}
                />
              )}
              {amount && (
                <FormControl margin="dense" fullWidth>
                  <InputLabel
                    required={required.includes('amount')}
                  >
                    Transaction Amount
                  </InputLabel>
                  <NumberFormat
                    value={filter.amount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    required={required.includes('amount')}
                    onValueChange={handleNumberFormatChange('nested_filter_amount', this)}
                    customInput={Input}
                  />
                </FormControl>
              )}
              {description && (
                <TextField
                  label="Description"
                  fullWidth
                  margin="dense"
                  required={required.includes('description')}
                  InputProps={{
                    value: filter.description,
                    name: 'nested_filter_description',
                    onChange: handleTextFieldChange(this),
                  }}
                />
              )}
              {proration && (
                <FormControlLabel
                  control={<Checkbox checked={filter.proration} onChange={handleCheckboxChange(this)} name="nested_filter_proration" />}
                  label="Include prorated portfolio transactions not assigned to a property"
                />
              )}
              {mileage && (
                <FormControlLabel
                  control={<Checkbox checked={filter.mileage} onChange={handleCheckboxChange(this)} name="nested_filter_mileage" />}
                  label="Include mileage expenses from the mileage log"
                />
              )}
              {inactive && (
                <FormControlLabel
                  control={<Checkbox checked={filter.inactive} onChange={handleCheckboxChange(this)} name="nested_filter_inactive" />}
                  label="Show inactive"
                />
              )}
              <Typography color="error">{error && error.message}</Typography>
            </DialogContent>
            <DialogActions>
              {!updating && (
                <Button type="submit" variant="contained" color="primary" disableElevation>
                  Update Filter
                </Button>
              )}
              {updating && (
                <Button disabled variant="contained" color="primary" disableElevation>
                  Updating Filter
                </Button>
              )}
              <Button onClick={closeDialog} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    );
  }
}

FilterDialog.contextType = PersonContext;

FilterDialog.defaultProps = {
  dateRange: false,
  date: false,
  period: false,
  account: false,
  revenueAccount: false,
  expenseAccount: false,
  partnerAccount: false,
  transactionType: false,
  subportfolio: false,
  property: false,
  unit: false,
  vendor: false,
  amount: false,
  description: false,
  proration: false,
  mileage: false,
  inactive: false,
  scope: [],
  required: [],
  receiptStatus: false,
  reconciliationStatus: false,
};

FilterDialog.propTypes = {
  filter: PropTypes.objectOf(PropTypes.any).isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  dateRange: PropTypes.bool,
  date: PropTypes.bool,
  period: PropTypes.bool,
  account: PropTypes.bool,
  revenueAccount: PropTypes.bool,
  expenseAccount: PropTypes.bool,
  partnerAccount: PropTypes.bool,
  transactionType: PropTypes.bool,
  subportfolio: PropTypes.bool,
  property: PropTypes.bool,
  unit: PropTypes.bool,
  vendor: PropTypes.bool,
  amount: PropTypes.bool,
  description: PropTypes.bool,
  proration: PropTypes.bool,
  mileage: PropTypes.bool,
  inactive: PropTypes.bool,
  scope: PropTypes.arrayOf(PropTypes.string),
  required: PropTypes.arrayOf(PropTypes.string),
  receiptStatus: PropTypes.bool,
  reconciliationStatus: PropTypes.bool,
};

export default FilterDialog;
