import { makeStyles, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React from 'react';

import OnboardingProvider from '~/contexts/OnboardingProvider';
import onboardingTheme from '~/themes/onboardingTheme';
import OnboardingRoutes from '~/routes/OnboardingRoutes';

import OnboardingLayout from './Layout';

const useStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    padding: '32px',
  },
}));

function Container() {
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={onboardingTheme}>
      <OnboardingLayout className={classes.content}>
        <OnboardingProvider>
          <OnboardingRoutes />
        </OnboardingProvider>
      </OnboardingLayout>
    </MuiThemeProvider>
  );
}

export default Container;
