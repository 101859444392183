import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import ButtonBase from '@material-ui/core/ButtonBase';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import {
  asyncHandleChange,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';

import AddNoteDialog from '../components/AddNoteDialog';
import EditNoteDialog from '../components/EditNoteDialog';
import FilterDialog from '../components/FilterDialog';

const styles = (theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  reportTitle: {
    textDecoration: 'underline',
    textDecorationStyle: 'double',
  },
  textButton: {
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  attachmentButton: {
    marginLeft: theme.spacing(1.5),
  },
});

class Notes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addNoteDialog: false,
      editNoteDialog: false,
      filterDialog: false,
      filter: {
        page: 0,
        propertyId: null,
        unitId: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.getNotes();
  }

  getNotes = async () => {
    const query = Object.assign(buildQuery(this), {
      $sort: {
        date: -1,
        id: -1,
      },
    });

    const notes = await find(this, 'notes', { query });

    this.setState({
      totalNotes: notes.total,
      notes: notes.data,
      loading: false,
    });
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getNotes();
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getNotes();
  };

  closeNoteDialogs = () => {
    this.setState({
      filterDialog: false,
      addNoteDialog: false,
      editNoteDialog: false,
    });
  };

  onNoteDialogs = async () => {
    await this.getNotes();
    this.closeNoteDialogs();
  };

  actionButtons = () => {
    const buttons = [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Add Note', action: (() => this.setState({ addNoteDialog: true })), class: 'add' },
    ];
    return buttons;
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading, notes, totalNotes,
      filter, filterDialog, addNoteDialog, editNoteDialog, noteId,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <Grid spacing={3} alignItems="center" justify="center" container className={classes.grid}>
        <PageHeader
          match={match}
          title="Documents & Notes"
          appliedFilter={appliedFilter(this.updateFilter, this)}
          actionButtons={this.actionButtons()}
        />
        <AddNoteDialog
          // propertyId={propertyId}
          // unitId={unitId}
          isOpen={addNoteDialog}
          closeDialog={this.closeNoteDialogs}
          onAddNote={this.onNoteDialogs}
        />
        <EditNoteDialog
          noteId={noteId}
          isOpen={editNoteDialog}
          closeDialog={this.closeNoteDialogs}
          onEditNote={this.onNoteDialogs}
        />
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          dateRange
          property
          unit
        />
        <Grid item xs={12} md={12}>
          <CardBase>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography variant="subtitle2">Date</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Property</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Title</Typography></TableCell>
                    <TableCell><Typography variant="subtitle2">Text</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {notes.map((note) => (
                    <TableRow key={`note-${note.id}`}>
                      <TableCell>{moment(note.date).format('M/D/YYYY')}</TableCell>
                      <TableCell>
                        {note.property && <Typography variant="body2" component="div">{note.property.address1}</Typography>}
                        {note.unit && <Typography variant="body2" component="div">{note.unit.name}</Typography>}
                      </TableCell>
                      <TableCell>
                        <ButtonBase
                          className={classes.textButton}
                          onClick={() => {
                            this.setState({ noteId: note.id, editNoteDialog: true });
                          }}
                        >
                          <Typography variant="body2">{note.title}</Typography>
                        </ButtonBase>
                        {note.attachmentURL && (
                          <Link href={note.attachmentURL.replace('~1/', '~1/nth/0/')} target="_blank">
                            <IconButton
                              className={classes.attachmentButton}
                              aria-label="attachments"
                            >
                              <AttachFileIcon fontSize="small" />
                            </IconButton>
                          </Link>
                        )}
                      </TableCell>
                      <TableCell>{note.text}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      variant="footer"
                      count={totalNotes}
                      page={filter.page}
                      rowsPerPage={20}
                      onChangePage={this.handleChangePage}
                      rowsPerPageOptions={[20]}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </CardContent>
          </CardBase>
        </Grid>
      </Grid>
    );
  }
}

Notes.contextType = PersonContext;

Notes.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Notes);
