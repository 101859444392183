const GET_STARTED_CHECKILIST_ITEM_STATUS = {
  SKIPPED: 'SKIPPED',
  COMPLETED: 'COMPLETED',
};

const GET_STARTED_CHECKLIST_ITEMS = [
  {
    id: 1,
    title: 'Create Your Portfolio',
    completionTime: null,
    description: null,
    column: null,
    status: GET_STARTED_CHECKILIST_ITEM_STATUS.COMPLETED,
  },
  {
    id: 2,
    title: 'Add Your Property',
    completionTime: '~1 minute',
    description: 'Book transactions based on your rentals and access property-specific reports',
    buttonText: 'Add',
    column: 'addProperty',
    status: null,
  },
  {
    id: 3,
    title: 'Import Your Transactions',
    completionTime: '~3 minutes',
    description: 'Eliminate manual data entry and securely sync your financial data',
    buttonText: 'Link',
    column: 'importTransaction',
    status: null,
  },
  {
    id: 4,
    title: 'Track Your Loans',
    completionTime: '~2 minutes',
    description: 'Understand the impact of financing and track principal paydown, mortgage interest, and escrow transfers',
    buttonText: 'Add',
    column: 'trackLoans',
    status: null,
  },
  {
    id: 5,
    title: 'Book Your First Transaction',
    completionTime: '~1 minute',
    description: 'Keep your books accurate by categorizing transactions and setting up automation with matching rules',
    buttonText: 'Book',
    column: 'bookTransactions',
    status: null,
  },
  {
    id: 6,
    title: 'Subscribe to REI Hub',
    completionTime: '~2 minutes',
    description: 'Always have access to everything you need for your rental property books',
    buttonText: 'Subscribe',
    column: 'subscribe',
    status: null,
  },
];

export { GET_STARTED_CHECKLIST_ITEMS, GET_STARTED_CHECKILIST_ITEM_STATUS };
