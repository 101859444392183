import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import IdleTimer from 'react-idle-timer';
import { withStyles } from '@material-ui/core/styles';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import AddIcon from '@material-ui/icons/Add';
import LaunchIcon from '@material-ui/icons/Launch';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import BarChartIcon from '@material-ui/icons/BarChart';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

import ScheduleOnboardingCall from '~/components/ScheduleOnboardingCall';
import { CircularProgress } from '@material-ui/core';
import client from '~/feathers';
import { find } from '~/feathersWrapper';
import { GET_STARTED_CHECKILIST_ITEM_STATUS, GET_STARTED_CHECKLIST_ITEMS } from '~/constants/get-started';
import InventoryOutlined from '../icons/InventoryOutlined';
import history from '../history';
import { PersonContext } from '../contexts/PersonContext';
import DashboardRoutes from '../routes/DashboardRoutes';

import DemoTour from '../components/DemoTour';
import ContactUsDialog from '../components/ContactUsDialog';
import ReferralProgramDialog from '../components/ReferralProgramDialog';
import WhoReferredDialog from '../components/WhoReferredDialog';
import SiteFeedbackDialog from '../components/SiteFeedbackDialog';
import SubscribeDialog from '../components/SubscribeDialog';
import AddBookedTransactionDialog from '../components/AddBookedTransactionDialog';
import UpdateFailedPaymentMethodDialog from '../components/UpdateFailedPaymentMethodDialog';
import WelcomeTour from '../components/WelcomeTour';
import SearchBar from '../components/UniversalSearchBar';
import PurpleButton from '../components/StyledComponents/PurpleButton';
import { checkIsSmallScreen } from '../functions/ScreenSizeFunctions';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    zIndex: theme.zIndex.drawer - 1,
  },
  spacer: {
    width: '235px',
    transition: '0.4s ease-in',
  },
  minimizedSpacer: {
    width: '50px',
    transition: '0.4s ease-out',
  },
  smallScreenSpacer: {
    width: '10px',
  },
  welcomeAppBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    zIndex: theme.zIndex.drawer + 3,
  },
  spacingToolbar: {
    marginTop: '1px',
  },
  container: {
    padding: 0,
    display: 'flex',
    minHeight: '100vh',
    maxWidth: '100vw',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  freeTrialAppBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  freeTrialToolBar: {
    minHeight: 'auto',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  bottomAppBar: {
    zIndex: 1,
  },
  footer: {
    justifyContent: 'space-between',
  },
  inline: {
    display: 'inline',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  accountIcon: {
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    lineHeight: 1,
  },
  logo: {
    // imageRendering: '-webkit-optimize-contrast',
  },
  logoBox: {
    marginTop: '5px',
    justifySelf: 'center',
    alignSelf: 'center',
  },
  flexGrow: {
    flexGrow: '1',
  },
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  permanentDrawerPaper: {
    width: 250,
    left: 'auto',
  },
  sideNav: {
    width: '250px',
    transition: '0.4s ease-in',
    overflowX: 'hidden',
  },
  sideNavMini: {
    width: '65px',
    transition: '0.4s ease-out',
    overflowX: 'hidden',
  },
  tempDrawerPaper: {
    width: 275,
  },
  minimizedAddButton: {
    height: '34px',
    width: '34px',
    display: 'flex',
    justifyContent: 'center',
    borderColor: theme.palette.secondary.main,
    border: 1,
    borderRadius: '10px',
    paddingBottom: '10px',
  },
  toggleDrawerButton: {
    height: '25px',
    width: '25px',
    display: 'flex',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: '50%',
    borderWidth: '1px',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  nestedList: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.action.selected,
    borderLeftWidth: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  activeNav: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.secondary.main,
    borderLeftWidth: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.action.selected,
  },
  activeCollapse: {
    color: theme.palette.secondary.main,
  },
  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: '#F5F5F5',
    zIndex: theme.zIndex.drawer + 2,
  },
  addIcon: {
    color: theme.palette.secondary.main,
  },
  searchBarContainer: {
    height: 'auto',
    width: '50%',
    marginLeft: theme.spacing(3),
  },
  progressIndicatorContainer: {
    position: 'relative',
    display: 'inline-flex',
  },
  progressIndicatorLabelContainer: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progressIndicatorLabel: {
    fontSize: '10px',
    color: theme.palette.primary.main,
  },
  circle: {
    strokeLinecap: 'round',
    color: '#431C5D',
  },
});

class Dashboard extends React.Component {
  drawerRef = React.createRef();

  timeOutRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      right: false,
      left: false,
      portfolioDrawer: false,
      transactionsDrawer: false,
      accountsDrawer: false,
      organizationDrawer: false,
      reportsDrawer: false,
      helpDrawer: false,
      helpUsDrawer: false,
      contactUs: false,
      siteFeedback: false,
      referralProgram: false,
      whoReferred: false,
      addTransactionDialog: false,
      highlightCollapse: null,
      paymentFade: false,
      sideNavMinimized: false,
      progressBarPercentage: 0,
      isGetStartedDisplayed: false,
    };
  }

  async componentDidMount() {
    const {
      dismissPayment,
      setContextState,
      showWelcome,
      promptPayment,
      organizationId,
      // partnerSubscribed,
    } = this.context;
    this.resetDrawers();
    setContextState({ autoSelectOrganization: false });
    if (dismissPayment) {
      setTimeout(() => { this.setState({ paymentFade: true }); }, 3500);
    } else {
      this.setState({ paymentFade: true });
    }
    if (localStorage.getItem('page')
      && !showWelcome
      && !(promptPayment && !dismissPayment)
    ) {
      history.replace(localStorage.getItem('page'));
    }
    localStorage.removeItem('page');

    const checklistStatusesResponse = await find(this, 'get-started-checklist', { query: { organizationId } });
    const statuses = checklistStatusesResponse.data[0];

    if (statuses) {
      this.setState({ isGetStartedDisplayed: true });
      this.getProgressPercentage(statuses);
    }

    client.service('get-started-checklist').on('patched', (data) => {
      this.getProgressPercentage(data);
    });
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: oldLocation } = prevProps;
    if (location.pathname === '/reload') { return; }
    if (location !== oldLocation) {
      this.resetDrawers();
    }
  }

  getProgressPercentage = (data) => {
    const { partnerSubscribed } = this.context;
    if (data.completedAt) {
      this.setState({ isGetStartedComplete: true });
      return;
    }

    const checklistItems = [...GET_STARTED_CHECKLIST_ITEMS];

    // If user is partner subscribed, remove the subscribe item from the checklist
    if (partnerSubscribed) {
      const subscribeIndex = checklistItems.findIndex((checklistItem) => checklistItem.column === 'subscribe');
      checklistItems.splice(subscribeIndex, 1);
    }

    const finishedItemCount = checklistItems.filter(
      (checklistItem) => [
        GET_STARTED_CHECKILIST_ITEM_STATUS.COMPLETED,
        GET_STARTED_CHECKILIST_ITEM_STATUS.SKIPPED,
      ].includes(data[checklistItem.column]),
    ).length;
    const percentage = ((finishedItemCount + 1) / checklistItems.length) * 100;
    this.setState({ progressBarPercentage: percentage });
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  toggleDropdown = (dropdown) => () => {
    const { [dropdown]: current } = this.state;
    this.setState({
      [dropdown]: !current,
    });
  };

  navigateTo = (link) => {
    const { location } = this.props;
    if (location.pathname === link) {
      history.push(`/reload?link=${link}`);
    } else {
      history.push(link);
    }
  };

  reloadPage = (event, link) => {
    const { location } = this.props;
    if (location.pathname === link) {
      event.preventDefault();
      history.push(`/reload?link=${link}`);
    }
  };

  goToRegister = async () => {
    const { logOut, partnerName } = this.context;
    await logOut();
    if (partnerName === 'TurboTenant') {
      window.location.replace('https://rental.turbotenant.com/owners/payments/accounting');
      return;
    }
    if (partnerName === 'RentRedi') {
      window.location.replace('https://app.rentredi.com/accounting');
      return;
    }
    history.replace('/register');
  };

  resetDrawers = () => {
    const { location } = this.props;
    const newState = {
      getStartedDrawer: false,
      portfolioDrawer: false,
      transactionsDrawer: false,
      accountsDrawer: false,
      organizationDrawer: false,
      reportsDrawer: false,
      helpDrawer: false,
      helpUsDrawer: false,
      right: false,
      left: false,
      highlightCollapse: null,
    };
    if (location.pathname === '/get-started') {
      newState.getStartedDrawer = true;
      newState.highlightCollapse = 'getStarted';
    }
    if (location.pathname === '/dashboard'
      || location.pathname.startsWith('/properties')
      || location.pathname.startsWith('/subportfolios')) {
      newState.portfolioDrawer = true;
      newState.highlightCollapse = 'portfolio';
    } else if (location.pathname.startsWith('/accounts')) {
      newState.accountsDrawer = true;
      newState.highlightCollapse = 'accounts';
    } else if (location.pathname.startsWith('/transactions')) {
      newState.transactionsDrawer = true;
      newState.highlightCollapse = 'transactions';
    } else if (location.pathname.startsWith('/organization')) {
      newState.organizationDrawer = true;
      newState.highlightCollapse = 'organization';
    } else if (location.pathname.startsWith('/reports')) {
      newState.reportsDrawer = true;
      newState.highlightCollapse = 'reports';
    }
    this.setState(newState);
  };

  getNavList = () => {
    const { classes } = this.props;
    const {
      viewDemoPortfolio,
      multiEntity,
      partnerName,
      airbnbAccountActive,
      airbnbAccountId,
      vrboAccountActive,
      vrboAccountId,
    } = this.context;
    const {
      portfolioDrawer,
      accountsDrawer,
      transactionsDrawer,
      organizationDrawer,
      reportsDrawer,
      helpDrawer,
      helpUsDrawer,
      highlightCollapse,
      sideNavMinimized,
      progressBarPercentage,
      isGetStartedComplete,
      isGetStartedDisplayed,
    } = this.state;

    return (
      <>
        <List>
          <ListItem>
            <Hidden smDown>
              {!sideNavMinimized && (
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                fullWidth
                startIcon={<AddIcon />}
                onClick={() => this.setState({ addTransactionDialog: true })}
                style={{ whiteSpace: 'nowrap' }}
              >
                Add Transaction
              </Button>
              )}
              {sideNavMinimized && (
              <IconButton
                className={classes.minimizedAddButton}
                onClick={() => this.setState({ addTransactionDialog: true })}
              >
                <AddIcon className={classes.addIcon} />
              </IconButton>
              )}
            </Hidden>
            <Hidden mdUp>
              <Button
                color="secondary"
                variant="outlined"
                size="small"
                fullWidth
                startIcon={<AddIcon />}
                onClick={() => this.setState({ addTransactionDialog: true })}
                style={{ whiteSpace: 'nowrap' }}
              >
                Add Transaction
              </Button>
            </Hidden>
          </ListItem>
          {isGetStartedComplete || !isGetStartedDisplayed
            ? null
            : (
              <ListItem
                button
                onClick={() => {
                  this.navigateTo('/get-started');
                }}
              >
                <ListItemIcon className={highlightCollapse === 'getStarted' ? classes.activeCollapse : ''}>
                  <InventoryOutlined
                    className={highlightCollapse === 'getStarted' ? classes.activeCollapse : ''}
                    height={27.42}
                    width={27.42}
                  />
                </ListItemIcon>
                <ListItemText primary="Get Started" />
                <Box className={classes.progressIndicatorContainer}>
                  <CircularProgress
                    size={32}
                    variant="determinate"
                    value={progressBarPercentage}
                    thickness={5}
                    className={classes.circle}
                  />
                  <Box className={classes.progressIndicatorLabelContainer}>
                    <Typography className={classes.progressIndicatorLabel} variant="caption">{`${Number(progressBarPercentage).toFixed()}%`}</Typography>
                  </Box>
                </Box>
              </ListItem>
            )}
          <ListItem button onClick={this.toggleDropdown('portfolioDrawer')}>
            <ListItemIcon className={highlightCollapse === 'portfolio' ? classes.activeCollapse : ''}>
              <HomeWorkOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Portfolio" />
            {portfolioDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {!sideNavMinimized && (
            <Collapse in={portfolioDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/dashboard"
                  onClick={(event) => this.reloadPage(event, '/dashboard')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Dashboard" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/properties"
                  onClick={(event) => this.reloadPage(event, '/properties')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Properties" />
                </ListItem>
                {multiEntity && (
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/subportfolios"
                  onClick={(event) => this.reloadPage(event, '/subportfolios')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Sub-Portfolios" />
                </ListItem>
                )}
              </List>
            </Collapse>
          )}
          <ListItem button onClick={this.toggleDropdown('accountsDrawer')}>
            <ListItemIcon className={highlightCollapse === 'accounts' ? classes.activeCollapse : ''}>
              <AccountBalanceOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Accounts" />
            {accountsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {!sideNavMinimized && (
          <Collapse in={accountsDrawer} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nestedList}
                component={NavLink}
                to="/accounts/banking"
                onClick={(event) => this.reloadPage(event, '/accounts/banking')}
                activeClassName={classes.activeNav}
              >
                <ListItemText primary="Banking" />
              </ListItem>
              <ListItem
                button
                className={classes.nestedList}
                component={NavLink}
                to="/accounts/loans"
                onClick={(event) => this.reloadPage(event, '/accounts/loans')}
                activeClassName={classes.activeNav}
              >
                <ListItemText primary="Loans" />
              </ListItem>
              {partnerName && (
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/accounts/partner"
                  onClick={(event) => this.reloadPage(event, '/accounts/partner')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary={partnerName} />
                </ListItem>
              )}
              <ListItem
                button
                className={classes.nestedList}
                component={NavLink}
                to="/accounts/property-managers"
                onClick={(event) => this.reloadPage(event, '/accounts/property-managers')}
                activeClassName={classes.activeNav}
              >
                <ListItemText primary="Property Managers" />
              </ListItem>
              {airbnbAccountActive && (
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to={`/accounts/airbnb/${airbnbAccountId}`}
                  onClick={
                    (event) => {
                      this.reloadPage(event, `/accounts/airbnb/${airbnbAccountId}`);
                    }
                  }
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Airbnb" />
                </ListItem>
              )}
              {vrboAccountActive && (
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to={`/accounts/vrbo/${vrboAccountId}`}
                  onClick={
                  (event) => {
                    this.reloadPage(event, `/accounts/vrbo/${vrboAccountId}`);
                  }
                }
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="VRBO" />
                </ListItem>
              )}
            </List>
          </Collapse>
          )}
          <ListItem button onClick={this.toggleDropdown('transactionsDrawer')}>
            <ListItemIcon className={highlightCollapse === 'transactions' ? classes.activeCollapse : ''}>
              <AttachMoneyIcon />
            </ListItemIcon>
            <ListItemText primary="Transactions" />
            {transactionsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {!sideNavMinimized && (
            <Collapse in={transactionsDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/revenues"
                  onClick={(event) => this.reloadPage(event, '/transactions/revenues')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Revenues" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/expenses"
                  onClick={(event) => this.reloadPage(event, '/transactions/expenses')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Expenses" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/booked"
                  onClick={(event) => this.reloadPage(event, '/transactions/booked')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Booked Transactions" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/transactions/imported"
                  onClick={(event) => this.reloadPage(event, '/transactions/imported')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Import Feed" />
                </ListItem>
              </List>
            </Collapse>
          )}
          <ListItem button onClick={this.toggleDropdown('organizationDrawer')}>
            <ListItemIcon className={highlightCollapse === 'organization' ? classes.activeCollapse : ''}>
              <CloudUploadOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Organization" />
            {organizationDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {!sideNavMinimized && (
            <Collapse in={organizationDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/fixed-assets"
                  onClick={(event) => this.reloadPage(event, '/organization/fixed-assets')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Fixed Assets" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/leases"
                  onClick={(event) => this.reloadPage(event, '/organization/leases')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Leases" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/vendors"
                  onClick={(event) => this.reloadPage(event, '/organization/vendors')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Vendors" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/mileage"
                  onClick={(event) => this.reloadPage(event, '/organization/mileage')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Mileage Log" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/time-tracking"
                  onClick={(event) => this.reloadPage(event, '/organization/time-tracking')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Time Tracking" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/notes"
                  onClick={(event) => this.reloadPage(event, '/organization/notes')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Documents & Notes" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/organization/contacts"
                  onClick={(event) => this.reloadPage(event, '/organization/contacts')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="Contacts" />
                </ListItem>
              </List>
            </Collapse>
          )}
          <ListItem
            button
            onClick={
              (event) => {
                if (reportsDrawer) {
                  this.toggleDropdown('reportsDrawer')(event);
                  return;
                }
                this.navigateTo('/reports');
              }
            }
          >
            <ListItemIcon className={highlightCollapse === 'reports' ? classes.activeCollapse : ''}>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" />
            {reportsDrawer ? <ExpandLessIcon /> : null}
          </ListItem>
          {!sideNavMinimized && (
            <Collapse in={reportsDrawer} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={NavLink}
                  to="/reports"
                  onClick={(event) => this.reloadPage(event, '/reports')}
                  activeClassName={classes.activeNav}
                >
                  <ListItemText primary="All" />
                </ListItem>
              </List>
            </Collapse>
          )}
        </List>
        <div className={classes.flexGrow} />
        <Divider />
        <List>
          <ListItem button onClick={this.toggleDropdown('helpDrawer')}>
            <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
            <ListItemText style={{ whiteSpace: 'nowrap' }} primary="Get Help" />
            {helpDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {!sideNavMinimized && (
            <Collapse in={helpDrawer} timeout="auto" unmountOnExit onClick={this.toggleDropdown('helpDrawer')}>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  component={Link}
                  underline="none"
                  href="https://support.reihub.net"
                  target="_blank"
                >
                  <ListItemText primary="Knowledge Base" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  onClick={viewDemoPortfolio}
                >
                  <ListItemText primary="Demo Portfolio" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  onClick={() => this.setState({ contactUs: true })}
                >
                  <ListItemText primary="Contact Us" />
                </ListItem>
              </List>
            </Collapse>
          )}
          <ListItem button onClick={this.toggleDropdown('helpUsDrawer')}>
            <ListItemIcon><VerifiedUserOutlinedIcon /></ListItemIcon>
            <ListItemText style={{ whiteSpace: 'nowrap' }} primary="Help Us" />
            {helpUsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          {!sideNavMinimized && (
            <Collapse in={helpUsDrawer} timeout="auto" unmountOnExit onClick={this.toggleDropdown('helpUsDrawer')}>
              <List component="div" disablePadding>
                <ListItem
                  button
                  className={classes.nestedList}
                  onClick={() => this.setState({ referralProgram: true })}
                >
                  <ListItemText primary="Referral Program" />
                </ListItem>
                <ListItem
                  button
                  className={classes.nestedList}
                  onClick={() => this.setState({ siteFeedback: true })}
                >
                  <ListItemText primary="Site Feedback" />
                </ListItem>
              </List>
            </Collapse>
          )}
        </List>
      </>
    );
  };

  nullOrganizationText = () => {
    const { adminLogin, accountantLogin } = this.context;
    if (adminLogin) {
      return 'Admin';
    }
    if (accountantLogin) {
      return 'Accountant';
    }
    return 'Portfolios';
  };

  subscriptionMessage = () => {
    const { organizationCreated, freeTrialExpires } = this.context;
    const isSmallScreen = checkIsSmallScreen();

    if (moment().isSameOrBefore(moment(organizationCreated).add(7, 'days'), 'day')) {
      if (isSmallScreen) return 'Subscribe And Save';

      return `Subscribe And Save 40% - Ends ${moment(organizationCreated).add(7, 'days').format('MMM D')}`;
    }
    if (moment().isSameOrBefore(moment(freeTrialExpires), 'day')) {
      if (isSmallScreen) return 'Subscribe And Save';

      return `Subscribe And Save 10% - Ends ${moment(freeTrialExpires).format('MMM D')}`;
    }
    return 'Subscribe';
  };

  handleDrawerChange = () => {
    const { sideNavMinimized } = this.state;
    if (sideNavMinimized) {
      this.drawerRef.current.collapsed = false;
      this.setState({ sideNavMinimized: false });
    } else {
      this.drawerRef.current.collapsed = true;
      this.setState({ sideNavMinimized: true });
    }
  };

  handleHover = (type) => {
    if (this.drawerRef.current.collapsed === true) {
      this.timeOutRef = setTimeout(() => {
        if (type === 'enter') {
          this.setState({ sideNavMinimized: false });
          clearTimeout();
        }
        if (type === 'leave') {
          this.setState({ sideNavMinimized: true });
          clearTimeout();
        }
      }, 750);
    }
  };

  render() {
    const { classes, location } = this.props;
    const {
      logOut, promptPayment, dismissPayment, setDismissPayment, freeTrialExpires,
      organizationName, setOrganizationId, demo, id, adminLogin, organizationId,
      promptUpdatePaymentMethod, dismissUpdateFailedPaymentMethod,
      setDismissUpdateFailedPaymentMethod, isTourVisible, setAdminUserSelect,
      adminUserSelect, isScheduleCallDialogVisible,
    } = this.context;
    const {
      right, left, paymentFade,
      contactUs, siteFeedback, referralProgram, whoReferred, addTransactionDialog, sideNavMinimized,
    } = this.state;

    return (
      <div className={classes.root}>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref; }}
          element={document}
          // onActive={this.onActive}
          onIdle={logOut}
          // onAction={this.onAction}
          // debounce={250}
          // 30 minute logout
          timeout={1000 * 60 * 120}
        />
        <ContactUsDialog
          isOpen={contactUs}
          closeDialog={() => { this.setState({ contactUs: false }); }}
        />
        <WhoReferredDialog
          isOpen={whoReferred}
          closeDialog={() => { this.setState({ whoReferred: false }); }}
        />
        <ReferralProgramDialog
          isOpen={referralProgram}
          closeDialog={() => { this.setState({ referralProgram: false }); }}
          wasReferred={() => { this.setState({ whoReferred: true, referralProgram: false }); }}
        />
        <SiteFeedbackDialog
          isOpen={siteFeedback}
          closeDialog={() => { this.setState({ siteFeedback: false }); }}
          location={location}
        />
        <SubscribeDialog
          isOpen={!contactUs && promptPayment && !dismissPayment}
          closeDialog={() => { setDismissPayment(true); }}
          contactUs={() => { this.setState({ contactUs: true }); }}
        />
        <UpdateFailedPaymentMethodDialog
          isOpen={promptUpdatePaymentMethod && !dismissUpdateFailedPaymentMethod}
          closeDialog={() => { setDismissUpdateFailedPaymentMethod(true); }}
        />
        <AddBookedTransactionDialog
          isOpen={addTransactionDialog}
          closeDialog={() => this.setState({
            addTransactionDialog: false,
          })}
          onAddTransaction={() => this.navigateTo(location.pathname)}
        />
        <AppBar
          position="fixed"
          color="inherit"
          className={`${classes.appBar} noprint`}
        >
          <Toolbar>
            <Hidden mdUp>
              <IconButton color="inherit" onClick={this.toggleDrawer('left', true)} aria-label="Menu" className={classes.menuIcon}>
                <MenuIcon />
              </IconButton>
              <div className={classes.flexGrow} />
              <RouterLink to="/dashboard" className={classes.link}>
                <img src="/rei_hub_logo_purple.svg" height="54px" alt="REI Hub" className={classes.logo} />
              </RouterLink>
            </Hidden>
            <Hidden smDown>
              {!sideNavMinimized ? (
                <Box className={classes.spacer} />
              ) : (
                <Box className={classes.minimizedSpacer} />
              )}
              <IconButton
                className={classes.toggleDrawerButton}
                onClick={() => { this.handleDrawerChange(); }}
              >
                {!sideNavMinimized && <ChevronLeft fontSize="small" />}
                {sideNavMinimized && <ChevronRight fontSize="small" />}
              </IconButton>
              <Box
                className={classes.searchBarContainer}
              >
                <SearchBar />
              </Box>
              <div className={classes.flexGrow} />
            </Hidden>
            <div className={classes.flexGrow} />
            <Hidden smDown>
              <div className={classes.inline}>
                <Typography variant="body2" color="inherit">
                  <span className={classes.inlineBlock}>
                    {organizationName}
                    {adminLogin && ` (${organizationId})`}
                  </span>
                </Typography>
              </div>
            </Hidden>
            <div className={classes.inline}>
              <IconButton
                aria-label="Settings Menu"
                color="inherit"
                onClick={this.toggleDrawer('right', true)}
                className={classes.accountIcon}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Drawer
              anchor="right"
              open={right}
              onClose={this.toggleDrawer('right', false)}
              classes={{
                paper: classes.tempDrawerPaper,
              }}
            >
              <List>
                <ListItem button onClick={logOut}>
                  <ListItemIcon><LockOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to="/settings" onClick={this.toggleDrawer('right', false)}>
                  <ListItemIcon><SettingsOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="Portfolio Settings" />
                </ListItem>
                <ListItem button component={RouterLink} to="/chart-of-accounts" onClick={this.toggleDrawer('right', false)}>
                  <ListItemIcon><ListOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="Chart of Accounts" />
                </ListItem>
                <ListItem button component={RouterLink} to="/subscription" onClick={this.toggleDrawer('right', false)}>
                  <ListItemIcon><CheckCircleOutlineOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="Subscription" />
                </ListItem>
                <ListItem button component={RouterLink} to="/access" onClick={this.toggleDrawer('right', false)}>
                  <ListItemIcon><PersonAddOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="Access" />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to="/my-profile" onClick={this.toggleDrawer('right', false)}>
                  <ListItemIcon><FaceOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="My Profile" />
                </ListItem>
                {
                  adminLogin
                  && adminUserSelect
                  && (
                  <ListItem
                    button
                    onClick={() => {
                      setOrganizationId(null);
                    }}
                  >
                    <ListItemIcon><LaunchIcon /></ListItemIcon>
                    <ListItemText primary="Portfolios" />
                  </ListItem>
                  )
                }
                <ListItem
                  button
                  onClick={
                    () => {
                      if (adminLogin) {
                        setAdminUserSelect(null);
                      }
                      setOrganizationId(null);
                    }
                  }
                >
                  <ListItemIcon><LaunchIcon /></ListItemIcon>
                  <ListItemText primary={this.nullOrganizationText()} />
                </ListItem>
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
        <Container className={classes.container}>
          <Hidden smDown>
            <Box
              onTouchEnd={() => { this.setState({ sideNavMinimized: false }); }}
              onMouseEnter={() => { this.handleHover('enter'); }}
              onMouseLeave={() => {
                if (this.timeOutRef) {
                  clearTimeout(this.timeOutRef);
                }
                this.handleHover('leave');
              }}
            >
              <Drawer
                className={
                sideNavMinimized
                  ? `${classes.sideNavMini} noprint`
                  : `${classes.sideNav} noprint`
                }
                variant="permanent"
                open
                anchor="left"
                classes={{
                  paper: sideNavMinimized ? classes.sideNavMini : classes.sideNav,
                }}
                ref={this.drawerRef}
              >
                {!sideNavMinimized && (
                <Box className={classes.logoBox}>
                  <RouterLink to="/dashboard" className={classes.link}>
                    <img src="/rei_hub_logo_purple.svg" height="54px" alt="REI Hub" className={classes.logo} />
                  </RouterLink>
                </Box>
                )}
                {sideNavMinimized && (
                <Box className={classes.logoBox}>
                  <RouterLink to="/dashboard" className={classes.link}>
                    <img src="/rei_hub_favicon_purple.png" height="54px" alt="REI Hub" className={classes.logo} />
                  </RouterLink>
                </Box>
                )}
                {this.getNavList()}
              </Drawer>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <Drawer
              anchor="left"
              open={left}
              onClose={this.toggleDrawer('left', false)}
              classes={{
                paper: classes.tempDrawerPaper,
              }}
            >
              {this.getNavList()}
            </Drawer>
          </Hidden>

          <div className={classes.content}>
            <Toolbar className={`${classes.spacingToolbar} noprint`} />
            {promptPayment && !isTourVisible && (
              <Fade in={paymentFade} timeout={1500}>
                <AppBar color="default" position="static" className={`${classes.freeTrialAppBar} noprint`}>
                  <Toolbar variant="dense" className={classes.freeTrialToolBar}>
                    <Typography variant="body2" align="center">
                      {`Trial: ${moment().diff(moment(freeTrialExpires).endOf('day')) < 0 ? `${moment(freeTrialExpires).diff(moment(), 'days') + 1} days left` : 'Expired'}`}
                    </Typography>
                    <div className={classes.flexGrow} />
                    <Box paddingY={0.5}>
                      <PurpleButton
                        variant="contained"
                        onClick={() => { setDismissPayment(false); }}
                      >
                        {this.subscriptionMessage()}
                      </PurpleButton>
                    </Box>
                  </Toolbar>
                </AppBar>
              </Fade>
            )}
            {demo && (
              <>
                <AppBar color="default" position="static" className={`${classes.freeTrialAppBar} noprint`}>
                  <Toolbar variant="dense" className={classes.freeTrialToolBar}>
                    <Typography variant="body2" align="center">
                      Demo Portfolio
                    </Typography>
                    <div className={classes.flexGrow} />
                    {id && (
                      <Box paddingY={0.5}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => setOrganizationId(null)}
                        >
                          Go To My Portfolios
                        </Button>
                      </Box>
                    )}
                    {!id && (
                      <Box paddingY={0.5}>
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => this.goToRegister()}
                        >
                          Get Started Now
                        </Button>
                      </Box>
                    )}
                  </Toolbar>
                </AppBar>
                <DemoTour />
              </>
            )}
            {isScheduleCallDialogVisible && (
              <ScheduleOnboardingCall />
            )}
            {!isScheduleCallDialogVisible && isTourVisible && (
              <WelcomeTour />
            )}
            <DashboardRoutes location={location} />
            <div className={classes.flexGrow} />
            <AppBar position="static" className={`${classes.bottomAppBar} noprint`}>
              <Toolbar className={classes.footer}>
                <Typography variant="body2" color="inherit">
                  {`© ${moment().format('YYYY')}, `}
                  <Link href="https://www.reihub.net" target="_blank>" color="inherit" underline="always">
                    REI Hub
                  </Link>
                  <br />
                  <Link href="https://support.reihub.net" target="_blank>" color="inherit" underline="always">
                    Knowledge Base
                  </Link>
                </Typography>
                <Typography variant="body2" color="inherit" align="right">
                  <Link href="https://www.reihub.net/terms" target="_blank>" color="inherit" underline="always">
                    Terms of Service
                  </Link>
                  <br />
                  <Link href="https://www.reihub.net/privacy" target="_blank>" color="inherit" underline="always">
                    Privacy Policy
                  </Link>
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
        </Container>
      </div>
    );
  }
}

Dashboard.contextType = PersonContext;

Dashboard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Dashboard);
