import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import {
  Box, Button, Divider, makeStyles, TextField, useTheme,
} from '@material-ui/core';
import planOptions from '~/constants/buy-it-now';
import DISCOUNTS from '~/constants/discounts';
import { Autocomplete } from '@material-ui/lab';
import {
  AccountBalanceOutlined, Check, CheckCircleOutline, CreditCard,
} from '@material-ui/icons';
import Lottie from 'lottie-react';
import { useSegmentTrack } from '~/functions/SegmentFunctions';
import { create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import PurpleButton from './StyledComponents/PurpleButton';
import AddSubscribePaymentCardDialog from './AddSubscribePaymentCardDialog';
import PlaidLinkAuth from './Plaid/PlaidLinkAuth';
import ContactUsDialog from './ContactUsDialog';

import success from '../lottie/success.json';

const useStyles = makeStyles((theme) => ({
  dialogComplete: {
    maxWidth: '400px',
    width: '100%',
    margin: 'auto',
    color: theme.palette.success.main,
  },
  dialogContentContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '600px',
    width: '100%',
    backgroundColor: '#FAFAFA',
  },
  limitedTimeOfferButton: {
    backgroundColor: '#0288D1',
    color: 'white',
    cursor: 'pointer',
    width: 'fit-content',
    pointerEvents: 'none',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  expiredTextContainer: {
    color: theme.palette.error.main,
  },
  autocomplete: {
    backgroundColor: 'white',
  },
  autoCompleteContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  autocompleteCaption: {
    color: theme.palette.text.secondary,
    marginLeft: '14px',
  },
  subscriptionTotalCard: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    border: '1px solid #C4C4C4',
    borderRadius: '10px',
    marginBottom: '24px',
    backgroundColor: 'white',
  },
  subscriptionLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  subscriptionLineLeft: {
    marginLeft: 0,
    marginRight: 'auto',
  },
  subscriptionLineRight: {
    marginRight: 0,
    marginLeft: 'auto',
  },
  totalTextContainer: {
    color: '#441c5d',
    fontWeight: 'bold',
  },
  savingsBox: {
    backgroundColor: '#EDF7ED',
    padding: theme.spacing(1.5),
    borderRadius: '4px',
    color: '#1E4620',
  },
  bulletsContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    gap: theme.spacing(1),
  },
  bulletContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  paymentMethodsContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  paymentMethodCaption: {
    color: theme.palette.text.secondary,
  },
  paymentMethodButtonsContainer: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    gap: theme.spacing(2.75),
    marginBottom: '16px',
  },
  paymentMethodButton: {
    border: '1px solid #C4C4C4',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    width: '100%',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  paymentMethodButtonSelected: {
    border: '1px solid #4CAF51',
    backgroundColor: '#EDF7ED',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    gap: theme.spacing(1.5),
    width: '100%',
    cursor: 'pointer',
    alignItems: 'center',
  },
  paymentMethodButtonSelectedIcon: {
    color: '#2E7D32',
    height: '35px',
    width: '35px',
  },
  paymentMethodButtonIcon: {
    color: '#441C5D',
    height: '35px',
    width: '35px',
  },
  dialogButtonsContainer: {
    marginTop: '24px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: theme.spacing(1),
    justifyContent: 'center',
  },
  dialogButtonsSecondRowContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    maxWidth: '400px',
    width: '100%',
    margin: 'auto',
  },
  completedStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    width: '100%',
  },
  completedAnimationContainer: {
    width: '120px',
    height: '120px',
    margin: 'auto',
    alignContent: 'center',
  },
  completedStepTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  secondaryStepContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  boldText: {
    fontWeight: '700',
  },
  semiBoldText: {
    fontWeight: '600',
  },
}));

export default function SubscribeDialog({
  isOpen,
  closeDialog,
  welcomeOffer,
  onWelcomeSubscribed,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const context = useContext(PersonContext);
  const {
    organizationId,
    organizationCreated,
    subscriptionDate,
    freeTrialExpires,
    partnerName,
    setOrganizationId,
  } = context;
  const tracking = useSegmentTrack();

  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [planSelect, setPlanSelect] = useState(null);
  const [unitCount, setUnitCount] = useState(null);
  const [discount, setDiscount] = useState(null);
  const [chargeAmount, setChargeAmount] = useState(0);
  const [error, setError] = useState(null);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [isLimitedTimeOffer, setIsLimitedTimeOffer] = useState(false);
  const [isTrialExpired, setIsTrialExpired] = useState(false);
  const [isDiscountEligible, setIsDiscountEligible] = useState(false);
  const [savings, setSavings] = useState(0);
  const [isContactUsDialogOpen, setIsContactUsDialogOpen] = useState(false);

  const plans = planOptions;
  const discountOptions = DISCOUNTS;

  useEffect(() => {
    const getUnitCount = async () => {
      const unitCountReport = await create(
        this,
        'reports',
        {
          organizationId,
          reportName: 'unitCount',
        },
      );

      setUnitCount(unitCountReport);
    };
    getUnitCount();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }
    let newStep;
    let newDiscount;
    let newPlanSelect;

    if (step === null) {
      newStep = 'select';
      if (['TurboTenant', 'RentRedi'].includes(partnerName)) {
        newStep = partnerName;
      }

      if (welcomeOffer) {
        newDiscount = discountOptions.find((option) => option.id === 'early_subscription_60');
        setIsLimitedTimeOffer(true);
        setIsDiscountEligible(true);
      } else if (moment().isSameOrBefore(moment(organizationCreated).add(7, 'days'), 'day')) {
        setIsLimitedTimeOffer(true);
        setIsDiscountEligible(true);
        newDiscount = discountOptions.find((option) => option.id === 'early_subscription_40');
      } else if (
        moment().isSameOrAfter(moment(organizationCreated).add(8, 'days'), 'day')
        && moment().isSameOrBefore(moment(freeTrialExpires), 'day')
      ) {
        setIsDiscountEligible(true);
        newDiscount = discountOptions.find((option) => option.id === 'early_subscription_10');
      } else if (
        moment().isSameOrAfter(moment(freeTrialExpires).add('8', 'days'), 'day')
        && !subscriptionDate
        && !partnerName
      ) {
        setIsLimitedTimeOffer(true);
        setIsDiscountEligible(true);
        newDiscount = discountOptions.find((option) => option.id === 'trial_expired_60');
      }

      if (moment().isSameOrAfter(moment(freeTrialExpires))) {
        setIsTrialExpired(true);
      }

      if (unitCount > 20) {
        newPlanSelect = plans.find((plan) => plan.id === 'professional');
      } else if (unitCount > 10) {
        newPlanSelect = plans.find((plan) => plan.id === 'investor');
      } else if (unitCount > 3) {
        newPlanSelect = plans.find((plan) => plan.id === 'growth');
      } else {
        newPlanSelect = plans.find((plan) => plan.id === 'essential');
      }
    }

    setStep(newStep);
    setDiscount(newDiscount);
    setPlanSelect(newPlanSelect);
  }, [unitCount]);

  useEffect(() => {
    if (planSelect && discount) {
      setSavings((planSelect.price * (discount.discountPercent / 100)).toFixed(2));
    }
  }, [planSelect, discount]);

  useEffect(() => {
    if (error && planSelect.maxUnits >= unitCount) {
      setError(null);
    }
  }, [planSelect]);

  const continueToPayment = () => {
    if (planSelect.maxUnits !== undefined && unitCount > planSelect.maxUnits) {
      setError(
        `You currently have ${unitCount} units in REI Hub. Please select a
        plan that includes enough units.`,
      );
      return;
    }

    if (paymentMethod === 'card') {
      const amount = planSelect.price - savings;

      setStep('card');
      setChargeAmount(amount);
    }

    if (paymentMethod === 'ach') {
      setStep('ach');
    }
  };

  const subscribe = async () => {
    try {
      await create(
        this,
        'stripe-subscriptions',
        {
          organizationId,
          plan: planSelect.id,
          maxUnits: planSelect.maxUnits,
          discount: discount ? discount.id : null,
        },
        true, // throw error
      );
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newSubscriptionComplete',
        plan: planSelect.id,
        value: planSelect.price * 36,
        organizationId,
      });
      if (welcomeOffer) {
        onWelcomeSubscribed();
      }
      setStep('complete');
    } catch (err) {
      setStep('subscriptionError');
      setSubscriptionError(err);
    }
  };

  const addACHPaymentMethod = async (stripeBankAccountToken) => {
    await create(
      this,
      'stripe-sources',
      {
        organizationId,
        stripeSource: stripeBankAccountToken,
      },
    );
    subscribe();
  };

  const getDialogContent = () => {
    switch (step) {
      case 'select':
        return (
          <>
            <Box className={classes.messageContainer}>
              {isLimitedTimeOffer && (
              <Button
                size="small"
                variant="contained"
                disableElevation
                className={classes.limitedTimeOfferButton}
              >
                LIMITED TIME OFFER
              </Button>
              )}
              {isTrialExpired ? (
                <Box>
                  <Typography variant="h6">
                    <Box className={classes.expiredTextContainer} component="span">
                      {'Your Trial Expired: '}
                    </Box>
                    Keep your bookkeeping on autopilot
                  </Typography>
                </Box>
              ) : (
                <Typography variant="h5">
                  Keep your bookkeeping on autopilot
                </Typography>
              )}
              <Typography variant="body1">
                Select a plan and payment method and get ready to turn next tax season into “relax”
                season!
              </Typography>
            </Box>
            <Box className={classes.autoCompleteContainer}>
              <Autocomplete
                options={plans}
                getOptionSelected={(option, value) => option.id === value.id}
                getOptionLabel={(option) => `${option.units} Units`}
                value={planSelect}
                onChange={(event, newValue) => setPlanSelect(newValue)}
                disableClearable
                variant="outlined"
                size="medium"
                className={classes.autocomplete}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Plan Tier"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
              <Box className={classes.autocompleteCaption}>
                <Typography variant="caption">
                  Based on the number of units in your portfolio.
                </Typography>
              </Box>
            </Box>
            <Box className={classes.subscriptionTotalCard}>
              <Box className={classes.subscriptionLineContainer}>
                <Box className={classes.subscriptionLineLeft}>
                  <Typography variant="subtitle2">
                    Monthly Plan
                  </Typography>
                </Box>
                <Typography variant="subtitle2" className={classes.subscriptionLineRight}>
                  {`$${planSelect.price}.00/month`}
                </Typography>
              </Box>
              {isDiscountEligible && (
                <Box className={classes.subscriptionLineContainer}>
                  <Box className={classes.subscriptionLineLeft}>
                    <Typography variant="body2" classes={classes.semiBoldText}>
                      {`${discount.discountPercent}% Discount (${discount.duration} months)`}
                    </Typography>
                  </Box>
                  <Typography variant="subtitle2" className={classes.subscriptionLineRight}>
                    {`($${savings})`}
                  </Typography>
                </Box>
              )}
              <Divider />
              <Box className={classes.subscriptionLineContainer}>
                <Box className={classes.subscriptionLineLeft}>
                  <Typography variant="subtitle2" className={classes.semiBoldText}>
                    Your Total
                  </Typography>
                </Box>
                <Box className={classes.subscriptionLineRight}>
                  <Typography variant="subtitle2" className={classes.totalTextContainer}>
                    {isDiscountEligible
                      ? `$${(planSelect.price - savings).toFixed(2)}/month`
                      : `$${planSelect.price}.00/month`}
                  </Typography>
                </Box>
              </Box>
              {isDiscountEligible && (
                <Box className={classes.savingsBox}>
                  <Typography variant="subtitle2">
                    {`You are saving a total of $${(savings * discount.duration).toFixed(2)}`}
                  </Typography>
                </Box>
              )}
              <Box className={classes.bulletsContainer}>
                <Box className={classes.bulletContainer}>
                  <Check fontSize="small" />
                  <Typography variant="body2">
                    Billed monthly
                  </Typography>
                </Box>
                <Box className={classes.bulletContainer}>
                  <Check fontSize="small" />
                  <Typography variant="body2">
                    Cancel anytime
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box className={classes.paymentMethodsContainer}>
              <Typography variant="caption" className={classes.paymentMethodCaption}>
                Payment Method
              </Typography>
              <Box
                className={classes.paymentMethodButtonsContainer}
              >
                <Box
                  className={
                    paymentMethod === 'ach'
                      ? classes.paymentMethodButtonSelected
                      : classes.paymentMethodButton
                  }
                  onClick={() => {
                    tracking(
                      'ach clicked',
                      { location: 'Subscribe Flow' },
                    );
                    setPaymentMethod('ach');
                  }}
                >
                  {
                    paymentMethod === 'ach'
                      ? (<CheckCircleOutline className={classes.paymentMethodButtonSelectedIcon} />)
                      : (<AccountBalanceOutlined className={classes.paymentMethodButtonIcon} />)
                  }
                  <Typography variant="subtitle1">
                    ACH (Preferred)
                  </Typography>
                </Box>
                <Box
                  className={
                    paymentMethod === 'card'
                      ? classes.paymentMethodButtonSelected
                      : classes.paymentMethodButton
                  }
                  onClick={() => {
                    tracking(
                      'card clicked',
                      { location: 'Subscribe Flow' },
                    );
                    setPaymentMethod('card');
                  }}
                >
                  {
                    paymentMethod === 'card'
                      ? (<CheckCircleOutline className={classes.paymentMethodButtonSelectedIcon} />)
                      : (<CreditCard className={classes.paymentMethodButtonIcon} />)
                  }
                  <Typography variant="subtitle1">
                    Credit/Debit Card
                  </Typography>
                </Box>
              </Box>
            </Box>
            {error && <Typography variant="body1" color="error">{error}</Typography>}
            <Box className={classes.dialogButtonsContainer}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'continue clicked',
                    { location: 'Subscribe Flow' },
                  );
                  continueToPayment();
                }}
                disabled={!paymentMethod}
              >
                Continue
              </PurpleButton>
              <Box className={isTrialExpired ? classes.dialogButtonsSecondRowContainer : null}>
                {isTrialExpired && (
                  <PurpleButton
                    variant="text"
                    onClick={() => {
                      tracking(
                        'contact_us clicked',
                        { location: 'Subscribe Flow' },
                      );
                      setIsContactUsDialogOpen(true);
                    }}
                  >
                    Contact Us
                  </PurpleButton>
                )}
                {isTrialExpired ? (
                  <PurpleButton
                    variant="text"
                    onClick={() => {
                      tracking(
                        'view_portfolios clicked',
                        { location: 'Subscribe Flow' },
                      );
                      setStep('select');
                      closeDialog();
                      if (isTrialExpired) {
                        setOrganizationId(null);
                      }
                    }}
                  >
                    View Porfolios
                  </PurpleButton>
                ) : (
                  <PurpleButton
                    variant="text"
                    onClick={() => {
                      tracking(
                        'not_right_now clicked',
                        { location: 'Subscribe Flow' },
                      );
                      setStep('select');
                      closeDialog();
                      if (isTrialExpired) {
                        setOrganizationId(null);
                      }
                    }}
                  >
                    Not Right Now
                  </PurpleButton>
                )}
              </Box>
            </Box>
          </>
        );
      case 'complete':
        return (
          <Box textAlign="center" className={classes.completedStepContainer}>
            <Box className={classes.completedAnimationContainer}>
              <Lottie animationData={success} loop={false} />
            </Box>
            <Box className={classes.completedStepTextContainer}>
              <Typography variant="h5">
                You&apos;re Subscribed!
              </Typography>
              <Typography variant="body1">
                Get ready to have the books you need for tax season, reporting, and more.
              </Typography>
            </Box>
            <PurpleButton
              variant="contained"
              onClick={() => {
                tracking(
                  'sounds_good clicked',
                  { location: 'Subscribe Flow' },
                );
                setStep(null);
                closeDialog();
                setOrganizationId(organizationId);
              }}
            >
              Sounds Good
            </PurpleButton>
          </Box>
        );
      case 'subscriptionError':
        return (
          <Box className={classes.secondaryStepContainer}>
            <Typography variant="h5">
              Subscription Error
            </Typography>
            <Typography variant="body1" color="error" gutterBottom>
              {`Sorry, we were unable to process your subscription with the payment information
                provided. Please try again. Message: ${subscriptionError}`}
            </Typography>
            <PurpleButton
              variant="contained"
              onClick={() => {
                setStep('select');
              }}
            >
              Go Back
            </PurpleButton>
          </Box>
        );
      case 'RentRedi':
        return (
          <Box className={classes.secondaryStepContainer}>
            <Typography variant="h5">
              Partner Subscription Ended
            </Typography>
            <Typography variant="body1" gutterBottom>
              {`Your subscription to REI Hub was canceled by our partner RentRedi. If you intend to
              continue using RentRedi's integration with REI Hub please visit the RentRedi
              dashboard to check that your accounting add-on is still active and that the payment
              for your most recent invoice completed successfully. To continue using REI Hub
              without additional data synced from RentRedi, you may subscribe this portfolio
              directly through REI Hub.`}
            </Typography>
            <PurpleButton
              variant="contained"
              onClick={() => {
                setStep('select');
              }}
            >
              Subscribe Through REI Hub
            </PurpleButton>
            <Box className={classes.dialogButtonsSecondRowContainer}>
              <PurpleButton
                variant="text"
                onClick={() => {
                  setIsContactUsDialogOpen(true);
                }}
              >
                Contact Us
              </PurpleButton>
              <PurpleButton
                variant="text"
                href="https://app.rentredi.com/accounting"
                onClick={() => { }}
              >
                Go To RentRedi
              </PurpleButton>
            </Box>
          </Box>
        );
      case 'TurboTenant':
        return (
          <Box className={classes.secondaryStepContainer}>
            <Typography variant="h5">
              Partner Subscription Ended
            </Typography>
            <Typography variant="body1" gutterBottom>
              {`Your subscription to REI Hub was canceled by our partner TurboTenant. If you intend
              to continue using TurboTenants's integration with REI Hub please visit the
              TurboTenant dashboard to check that your accounting add-on is still active and that
              the payment for your most recent invoice completed successfully. To continue using
              REI Hub without additional data synced from TurboTenant, you may subscribe this
              portfolio directly through REI Hub.`}
            </Typography>
            <PurpleButton
              variant="contained"
              onClick={() => {
                setStep('select');
              }}
            >
              Subscribe Through REI Hub
            </PurpleButton>
            <Box className={classes.dialogButtonsSecondRowContainer}>
              <PurpleButton
                variant="text"
                onClick={() => {
                  setIsContactUsDialogOpen(true);
                }}
              >
                Contact Us
              </PurpleButton>
              <PurpleButton
                variant="text"
                href="https://rental.turbotenant.com/owners/payments/accounting"
                onClick={() => { }}
              >
                Go To TurboTenant
              </PurpleButton>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return null;
  }

  if (isContactUsDialogOpen) {
    return (
      <ContactUsDialog
        isOpen
        closeDialog={() => { setIsContactUsDialogOpen(false); }}
      />
    );
  }

  if (step === 'ach') {
    return (
      <PlaidLinkAuth
        closeDialog={() => { setStep('select'); }}
        onSuccess={addACHPaymentMethod}
      />
    );
  }

  if (step === 'card') {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <AddSubscribePaymentCardDialog
            elements={elements}
            stripe={stripe}
            onSuccess={subscribe}
            onCancel={() => { setStep('select'); }}
            cancelLabel="Go Back"
            amount={chargeAmount}
            isInSubscribeFlow
          />
        )}
      </ElementsConsumer>
    );
  }

  return (
    <Dialog
      open={isOpen}
      scroll="body"
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setStep('null');
          closeDialog();
        }
      }}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={step === 'complete' ? classes.dialogComplete : null}
    >
      <Box className={step === 'complete' ? null : classes.dialogContentContainer}>
        {getDialogContent()}
      </Box>
    </Dialog>
  );
}

SubscribeDialog.defaultProps = {
  welcomeOffer: false,
  onWelcomeSubscribed: () => { },
};

SubscribeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  welcomeOffer: PropTypes.bool,
  onWelcomeSubscribed: PropTypes.func,
};
