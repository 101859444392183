import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';

const styles = {
  root: {
    width: '100%',
    margin: 0,
  },
};

class PageGrid extends React.PureComponent {
  render() {
    const { classes, children } = this.props;
    return (
      <Grid spacing={3} container className={classes.root}>
        {children}
      </Grid>
    );
  }
}

PageGrid.defaultProps = {
  children: null,
};

PageGrid.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  children: PropTypes.node,
};

export default withStyles(styles)(PageGrid);
