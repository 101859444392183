import React from 'react';
import PropTypes from 'prop-types';
import { Widget } from '@uploadcare/react-widget/dist/cjs';
import withStyles from '@material-ui/core/styles/withStyles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Card } from '@material-ui/core';

import { get, create } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
  handleAutocompleteChange,
  handleUploaderChange,
  handleRadioGroupChange,
} from '../functions/InputHandlers';

import {
  nameLabel,
  getUploadAccountOptions,
} from './Autocomplete/Library';

import LinkBase from './LinkBase';
import PurpleButton from './StyledComponents/PurpleButton';

const styles = {
  hideWidget: {
    display: 'none',
  },
  onboardingCard: {
    width: '100%',
    maxWidth: '600px',
  },
};

class UploadTransactionsDialog extends React.PureComponent {
  uploadcareWidget = React.createRef();

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  async componentDidMount() {
    await this.setInitialState();
  }

  setInitialState = async () => {
    const { accountId } = this.props;

    const initialState = {
      loading: false,
      submitting: false,
      error: null,
      data: {
        fileType: 'OFX',
        accountIdSelect: null,
        yodleeContainer: null,
        filePath: '',
        filePath_info: {},
      },
    };
    if (accountId) {
      initialState.data.accountIdSelect = await get(this, 'accounts', accountId);
      initialState.data.accountId = accountId;
      initialState.key = 'selectFileType';
    } else {
      initialState.key = 'selectAccount';
      initialState.uploadAccountOptions = await getUploadAccountOptions(this);
    }

    this.setState(initialState);
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ key: null });
    closeDialog();
  }

  chooseAccount = async (event) => {
    event.preventDefault();
    const { data } = this.state;
    await asyncHandleChange('nested_data_accountId', data.accountIdSelect.id, this);
    this.setState({ key: 'selectFileType' });
  }

  chooseFileType = async () => {
    const { data } = this.state;
    if (['OFX', 'QFX', 'QBO'].includes(data.fileType)) {
      this.setState({ key: 'automatedUpload' });
    } else {
      this.setState({ key: 'downloadTemplate' });
    }
  }

  downloadTemplate = () => {
    const { data } = this.state;
    if (data.accountIdSelect.type2 === 'Bank') {
      document.getElementById('bank_template_link').click();
    } else if (data.accountIdSelect.type2 === 'Credit Card') {
      document.getElementById('card_template_link').click();
    }
    this.setState({ key: 'completeTemplate' });
  }

  completeTemplate = () => {
    this.setState({ key: 'manualUpload' });
  }

  retryUpload = async () => {
    await this.removeUploadedFile();
    this.setState({ key: 'selectFileType' });
  }

  xlsxOnly = (fileInfo) => {
    if (fileInfo.mimeType) {
      if (fileInfo.mimeType === 'application/octet-stream') {
        this.setState({
          error: { message: 'Please save the template as an .xlsx document to use the uploader. In Numbers, save your spreadsheet using File -> Export to -> Excel' },
        });
        throw new Error('Wrong file type');
      } else if (fileInfo.mimeType && fileInfo.mimeType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.setState({
          error: { message: 'The template is not in the correct file format. Please save the template as an .xlsx document to use the transaction uploader' },
        });
        throw new Error('Wrong file type');
      } else {
        this.setState({
          error: {},
        });
      }
    }
  };

  uploadOFXTransactions = async () => {
    const {
      submitting, data,
    } = this.state;

    const { organizationId } = this.context;
    const { onUploadTransactions } = this.props;

    if (submitting) {
      return;
    }
    if (!data.filePath) {
      this.setState({ error: { message: 'Please add a .OFX, .QBO, or .QFX transaction file.' } });
      return;
    }

    this.setState({ submitting: true });

    const account = await get(this, 'accounts', data.accountId);
    let yodleeContainer;

    if (account.type2 === 'Bank') {
      yodleeContainer = 'bank';
    } else if (account.type2 === 'Credit Card') {
      yodleeContainer = 'creditCard';
    }

    Object.assign(data, { organizationId, yodleeContainer });

    create(this, 'upload-ofx-transactions', data, true)
      .then((result) => {
        onUploadTransactions(result);
        this.setState({ key: 'success' });
      })
      .catch(async (error) => {
        if (error.message === 'There are errors in your xml file: mismatched tag') {
          this.setState({
            submitting: false,
            key: 'ofxError',
          });
        } else {
          this.setState({ error: { message: `There was an error uploading your transactions. Please check that a properly formatted file was selected. Error message: ${error.message}` } });
          await this.removeUploadedFile();
          this.setState({ submitting: false });
        }
      });
  };

  uploadExcelTransactions = async () => {
    const {
      submitting, data,
    } = this.state;

    const { organizationId } = this.context;
    const { onUploadTransactions } = this.props;

    if (submitting) {
      return;
    }
    if (!data.filePath) {
      this.setState({ error: { message: 'Please add a completed transaction template file.' } });
      return;
    }

    this.setState({ submitting: true });

    const account = await get(this, 'accounts', data.accountId);
    let yodleeContainer;

    if (account.type2 === 'Bank') {
      yodleeContainer = 'bank';
    } else if (account.type2 === 'Credit Card') {
      yodleeContainer = 'creditCard';
    }

    Object.assign(data, { organizationId, yodleeContainer });

    create(this, 'upload-transactions', data, true)
      .then((result) => {
        onUploadTransactions(result);
        this.setState({ key: 'success' });
      })
      .catch(async (error) => {
        this.setState({ error: { message: `There was an error uploading your transactions. Please edit and re-save the transaction file then try uploading it again. Error message: ${error.message}` } });
        await this.removeUploadedFile();
        this.setState({ submitting: false });
      });
  };

  removeUploadedFile = async () => {
    await asyncHandleChange('nested_data_filePath', '', this);
    // this.uploadcareWidget.current.reloadInfo();
  }

  getDialogContent = () => {
    const { classes } = this.props;
    const { showWelcome } = this.context;
    const {
      key, data, uploadAccountOptions, error,
    } = this.state;

    switch (key) {
      case 'selectAccount':
        return (
          <DialogContent>
            <form onSubmit={this.chooseAccount}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Which account are you uploading transactions from?
                </Typography>
              </Box>
              <Autocomplete
                options={uploadAccountOptions}
                getOptionLabel={nameLabel}
                value={data.accountIdSelect}
                onChange={handleAutocompleteChange('nested_data_accountIdSelect', this)}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Upload Account"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <PurpleButton
                  variant="contained"
                >
                  Next
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'selectFileType':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Which file type contains your downloaded transaction data?
              </Typography>
            </Box>
            <FormControl component="fieldset">
              <RadioGroup aria-label="File Types" name="nested_data_fileType" value={data.fileType} onChange={handleRadioGroupChange(this)}>
                <FormControlLabel value="OFX" control={<Radio required />} label="Open Financial Exchange (.ofx)" />
                <FormControlLabel value="QBO" control={<Radio required />} label="QuickBooks Web Connect (.qbo)" />
                <FormControlLabel value="QFX" control={<Radio required />} label="Quicken Web Connect (.qfx)" />
                <FormControlLabel value="Excel" control={<Radio required />} label="Excel (.xlsx - manual)" />
                <FormControlLabel value="CSV" control={<Radio required />} label="Comma Separated Values (.csv - manual)" />
              </RadioGroup>
            </FormControl>
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                {`Please visit your bank's website to download the desired transactions from your
                account. Transactions can typically be downloaded in a variety of file formats.
                OFX, QBO, or QFX file types are recommended because transaction information is
                presented in standard formatting that can be consumed as is. Manual file types will
                require moving your transaction data from your download into REI Hub's transaction
                template before uploading.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.chooseFileType}
              >
                Next
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'automatedUpload':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Please select the transaction file from your computer
              </Typography>
            </Box>
            <FormControl margin="dense">
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction File
              </FormLabel>
              <div className={data.filePath ? null : classes.hideWidget}>
                <Widget
                  ref={this.uploadcareWidget}
                  tabs="file"
                  value={data.filePath}
                  onChange={handleUploaderChange('nested_data_filePath', this)}
                />
              </div>
              {!data.filePath && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to add your transaction file
              </Button>
              )}
              {data.filePath && (
              <Button
                color="primary"
                onClick={() => this.removeUploadedFile()}
              >
                Remove transaction file
              </Button>
              )}
            </FormControl>
            <Typography color="error">{error && error.message}</Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.uploadOFXTransactions}
              >
                Upload Transactions
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'downloadTemplate':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Please download the transaction template to your computer
              </Typography>
            </Box>
            <Box display="none">
              <LinkBase id="bank_template_link" to="/bank_import_transaction_template_v1.xlsx" target="_blank">
                <Typography variant="body2">Bank Transaction Template</Typography>
              </LinkBase>
              <LinkBase id="card_template_link" to="/credit_card_import_transaction_template_v1.xlsx" target="_blank">
                <Typography variant="body2">Credit Card Transaction Template</Typography>
              </LinkBase>
            </Box>
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                {`Click below to download REI Hub's transaction template to your computer. You will
                move the transaction data from the file downloaded from your bank into this
                transaction template for processing.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.downloadTemplate}
              >
                Download Template
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'completeTemplate':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Please add your transactions to the downloaded template
              </Typography>
            </Box>
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                {`Open the downloaded template to add your transaction data. You can copy and
                  paste from the file that you've downloaded from your bank. Don't include any
                  blank lines, and be sure to save your changes to the template when you are
                  done.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.completeTemplate}
              >
                Continue
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'manualUpload':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Please select the completed transaction template file from your computer
              </Typography>
            </Box>
            <FormControl margin="dense">
              <FormLabel shrink style={{ position: 'relative' }} component={InputLabel}>
                Transaction File
              </FormLabel>
              <div className={data.filePath ? null : classes.hideWidget}>
                <Widget
                  ref={this.uploadcareWidget}
                  tabs="file"
                  value={data.filePath}
                  onChange={handleUploaderChange('nested_data_filePath', this)}
                  validators={[this.xlsxOnly]}
                />
              </div>
              {!data.filePath && (
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  this.uploadcareWidget.current.openDialog();
                }}
              >
                Click to add your transaction file
              </Button>
              )}
              {data.filePath && (
              <Button
                color="primary"
                onClick={() => this.removeUploadedFile()}
              >
                Remove transaction file
              </Button>
              )}
            </FormControl>
            <Typography color="error">{error && error.message}</Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.uploadExcelTransactions}
              >
                Upload Transactions
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'ofxError':
        return (
          <>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Error processing upload
                </Typography>
              </Box>
              <Typography variant="body1" color="error">
                {`There was an error while procesing your upload. The data contained in the file
                does not match the expected ${data.fileType} format. If your institution offers
                different export options for transaction files, please export a new file using a
                different format and try again.`}
              </Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <PurpleButton
                  variant="contained"
                  onClick={this.retryUpload}
                >
                  Try Again
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.closeDialog}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </DialogContent>
          </>
        );
      case 'success':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Your transactions have been successfully uploaded
              </Typography>
            </Box>
            <Typography variant="body1">
              {showWelcome
                && 'We have processed your upload and added these transactions to your import feed.'}
              {!showWelcome
                && `We have processed your upload and added these transactions to your import feed.
                Transactions matching autobook rules have been categorized and booked automatically.
                Click 'Book' to categorize and add additional transactions to your financials.`}
            </Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.closeDialog}
              >
                Finish
              </PurpleButton>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  render() {
    const { classes } = this.props;
    const { showWelcome } = this.context;
    const {
      loading,
    } = this.state;

    if (loading) {
      return null;
    }

    return (
      <>
        {!showWelcome && (
          <Dialog
            open
            scroll="body"
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="sm"
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            id="uploadTransactionsDialog"
          >
            {this.getDialogContent()}
          </Dialog>
        )}
        {showWelcome && (
          <Card
            open
            className={classes.onboardingCard}
          >
            {this.getDialogContent()}
          </Card>
        )}
      </>
    );
  }
}

UploadTransactionsDialog.contextType = PersonContext;

UploadTransactionsDialog.defaultProps = {
  accountId: null,
};

UploadTransactionsDialog.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  closeDialog: PropTypes.func.isRequired,
  onUploadTransactions: PropTypes.func.isRequired,
  accountId: PropTypes.node,
};

export default withStyles(styles)(UploadTransactionsDialog);
