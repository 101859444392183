import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import makeStyles from '@material-ui/styles/makeStyles';
import React, { useContext, useEffect, useState } from 'react';

import PurpleButton from '~/components/StyledComponents/PurpleButton';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const ONBOARDING_DASHBOARD_SEGMENT_LOCATION = 'Onboarding - Dashboard';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '450px',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  fieldset: {
    paddingLeft: theme.spacing(1),
  },
  button: {
    marginInline: 'auto',
    marginTop: theme.spacing(3),
    width: '250px',
  },
  buttonIcon: {
    color: '#F0E4F0',
    fontSize: '22px',
  },
}));

export default function ScheduleOnboardingCall() {
  const [selectedOption, setSelectedOption] = useState('');

  const {
    setIsScheduleCallDialogVisible,
    setIsTourVisible,
  } = useContext(PersonContext);

  const tracking = useSegmentTrack();

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    tracking(`${event.target.value} selected`, { location: ONBOARDING_DASHBOARD_SEGMENT_LOCATION });
  };

  const handleClose = () => {
    setIsTourVisible(true);
    setIsScheduleCallDialogVisible(false);
  };

  const handleScheduleCall = () => {
    window.open('https://calendly.com/d/cmhm-ymy-tfj/rei-hub-onboarding-call', '_blank', 'noopener,noreferrer');
    tracking('schedule_call clicked', { location: ONBOARDING_DASHBOARD_SEGMENT_LOCATION });
    handleClose();
  };

  const handleStartTour = () => {
    tracking('start_tour clicked', { location: ONBOARDING_DASHBOARD_SEGMENT_LOCATION });
    handleClose();
  };

  useEffect(() => {
    tracking('large_portfolio_schedule_onboarding_call_modal loaded', { location: ONBOARDING_DASHBOARD_SEGMENT_LOCATION });
  }, []);

  const classes = useStyles();

  return (
    <Dialog aria-labelledby="schedule-onboarding-call-title" open>
      <div className={classes.container}>
        <Typography id="schedule-onboarding-call-title" variant="h6" className={classes.title}>
          Do you want to schedule a call to learn how to get the most out of REI Hub?
        </Typography>

        <FormControl component="fieldset" className={classes.fieldset}>
          <RadioGroup aria-label="Select an option" name="schedule-onboarding-call" value={selectedOption} onChange={handleChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        {Boolean(selectedOption) && (
          <PurpleButton
            endIcon={selectedOption === 'yes' && <OpenInNewIcon className={classes.buttonIcon} />}
            variant="contained"
            onClick={selectedOption === 'yes' ? handleScheduleCall : handleStartTour}
            className={classes.button}
          >
            {selectedOption === 'yes' ? 'Schedule Call' : 'Start Tour'}
          </PurpleButton>
        )}
      </div>
    </Dialog>
  );
}
