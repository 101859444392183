import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import IdleTimer from 'react-idle-timer';
import { withStyles } from '@material-ui/core/styles';
import { NavLink, Link as RouterLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import LaunchIcon from '@material-ui/icons/Launch';
// import AccountCircle from '@material-ui/icons/AccountCircle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import Collapse from '@material-ui/core/Collapse';

import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import GroupIcon from '@material-ui/icons/Group';
// import PlaceIcon from '@material-ui/icons/Place';
// import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';

import history from '../history';
import { PersonContext } from '../contexts/PersonContext';
import AccountantRoutes from '../routes/AccountantRoutes';

import ContactUsDialog from '../components/ContactUsDialog';
import SiteFeedbackDialog from '../components/SiteFeedbackDialog';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
    zIndex: theme.zIndex.drawer + 1,
  },
  container: {
    padding: 0,
    display: 'flex',
    minHeight: '100vh',
    maxWidth: 1680,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  freeTrialAppBar: {
    backgroundColor: 'white',
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0, 0, 0, 0.23)',
  },
  freeTrialToolBar: {
    minHeight: 'auto',
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  bottomAppBar: {
    zIndex: 1,
  },
  footer: {
    justifyContent: 'space-between',
  },
  inline: {
    display: 'inline',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  menuIcon: {
    marginRight: theme.spacing(2),
  },
  accountIcon: {
    marginLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    lineHeight: 1,
  },
  logo: {
    // imageRendering: '-webkit-optimize-contrast',
  },
  flexGrow: {
    flexGrow: '1',
  },
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  permanentDrawerPaper: {
    width: 250,
    left: 'auto',
  },
  tempDrawerPaper: {
    width: 250,
  },
  nestedList: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.action.selected,
    borderLeftWidth: theme.spacing(1),
    paddingLeft: theme.spacing(3),
  },
  activeNav: {
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.secondary.main,
    borderLeftWidth: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.action.selected,
  },
  activeCollapse: {
    color: theme.palette.secondary.main,
  },
});

class AccountantDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      right: false,
      left: false,
      clientsDrawer: false,
      helpDrawer: false,
      helpUsDrawer: false,
      contactUs: false,
      siteFeedback: false,
      highlightCollapse: null,
    };
  }

  componentDidMount() {
    this.resetDrawers();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const { location: oldLocation } = prevProps;
    if (location.pathname === '/reload') { return; }
    if (location !== oldLocation) {
      this.resetDrawers();
    }
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  toggleDropdown = (dropdown) => () => {
    const { [dropdown]: current } = this.state;
    this.setState({
      [dropdown]: !current,
    });
  };

  navigateTo = (link) => {
    const { location } = this.props;
    if (location.pathname === link) {
      history.push(`/reload?link=${link}`);
    } else {
      history.push(link);
    }
  };

  reloadPage = (event, link) => {
    const { location } = this.props;
    if (location.pathname === link) {
      event.preventDefault();
      history.push(`/reload?link=${link}`);
    }
  };

  resetDrawers = () => {
    const { location } = this.props;
    const newState = {
      clientsDrawer: false,
      helpDrawer: false,
      helpUsDrawer: false,
      right: false,
      left: false,
      highlightCollapse: null,
    };
    if (location.pathname.startsWith('/accountant/clients')) {
      newState.clientsDrawer = true;
      newState.highlightCollapse = 'clients';
    }
    this.setState(newState);
  };

  getNavList = () => {
    const { classes } = this.props;
    const {
      clientsDrawer,
      helpDrawer,
      helpUsDrawer,
      highlightCollapse,
    } = this.state;

    return (
      <>
        <List>
          <ListItem button onClick={this.toggleDropdown('clientsDrawer')}>
            <ListItemIcon className={highlightCollapse === 'clients' ? classes.activeCollapse : ''}>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Clients" />
            {clientsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={clientsDrawer} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nestedList}
                component={NavLink}
                to="/accountant/clients"
                onClick={(event) => this.reloadPage(event, '/accountant/clients')}
                activeClassName={classes.activeNav}
              >
                <ListItemText primary="Portfolios" />
              </ListItem>
            </List>
          </Collapse>
        </List>
        <div className={classes.flexGrow} />
        <Divider />
        <List>
          <ListItem button onClick={this.toggleDropdown('helpDrawer')}>
            <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
            <ListItemText primary="Get Help" />
            {helpDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={helpDrawer} timeout="auto" unmountOnExit onClick={this.toggleDropdown('helpDrawer')}>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nestedList}
                onClick={() => this.setState({ contactUs: true })}
              >
                <ListItemText primary="Contact Us" />
              </ListItem>
              <ListItem
                button
                className={classes.nestedList}
                component={Link}
                underline="none"
                href="https://support.reihub.net"
                target="_blank"
              >
                <ListItemText primary="Knowledge Base" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem button onClick={this.toggleDropdown('helpUsDrawer')}>
            <ListItemIcon><VerifiedUserOutlinedIcon /></ListItemIcon>
            <ListItemText primary="Help Us" />
            {helpUsDrawer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse in={helpUsDrawer} timeout="auto" unmountOnExit onClick={this.toggleDropdown('helpUsDrawer')}>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nestedList}
                onClick={() => this.setState({ siteFeedback: true })}
              >
                <ListItemText primary="Site Feedback" />
              </ListItem>
            </List>
          </Collapse>
        </List>
      </>
    );
  };

  render() {
    const { classes, location } = this.props;
    const {
      logOut, firstName, setContextState,
    } = this.context;
    const {
      right, left,
      contactUs, siteFeedback,
    } = this.state;

    return (
      <div className={classes.root}>
        <IdleTimer
          ref={(ref) => { this.idleTimer = ref; }}
          element={document}
          // onActive={this.onActive}
          onIdle={logOut}
          // onAction={this.onAction}
          // debounce={250}
          // 30 minute logout
          timeout={1000 * 60 * 30}
        />
        <ContactUsDialog
          isOpen={contactUs}
          closeDialog={() => { this.setState({ contactUs: false }); }}
        />
        <SiteFeedbackDialog
          isOpen={siteFeedback}
          closeDialog={() => { this.setState({ siteFeedback: false }); }}
          location={location}
        />
        <AppBar position="fixed" color="inherit" className={`${classes.appBar} noprint`}>
          <Toolbar>
            <Hidden mdUp>
              <IconButton color="inherit" onClick={this.toggleDrawer('left', true)} aria-label="Menu" className={classes.menuIcon}>
                <MenuIcon />
              </IconButton>
            </Hidden>
            <RouterLink to="/clients/all" className={classes.link}>
              <img src="/rei_hub_logo_purple.svg" height="54px" alt="REI Hub" className={classes.logo} />
            </RouterLink>
            <div className={classes.flexGrow} />
            <Hidden smDown>
              <div className={classes.inline}>
                <Typography variant="body2" color="inherit">
                  <span className={classes.inlineBlock}>{firstName}</span>
                </Typography>
              </div>
            </Hidden>
            <div className={classes.inline}>
              <IconButton
                aria-label="Settings Menu"
                color="inherit"
                onClick={this.toggleDrawer('right', true)}
                className={classes.accountIcon}
              >
                <MenuIcon />
              </IconButton>
            </div>
            <Drawer
              anchor="right"
              open={right}
              onClose={this.toggleDrawer('right', false)}
              classes={{
                paper: classes.tempDrawerPaper,
              }}
            >
              <List>
                <ListItem button onClick={logOut}>
                  <ListItemIcon><LockOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="Sign Out" />
                </ListItem>
                <Divider />
                <ListItem button component={RouterLink} to="/accountant/settings" onClick={this.toggleDrawer('right', false)}>
                  <ListItemIcon><SettingsOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="Firm Settings" />
                </ListItem>
                <ListItem button component={RouterLink} to="/accountant/my-profile" onClick={this.toggleDrawer('right', false)}>
                  <ListItemIcon><FaceOutlinedIcon /></ListItemIcon>
                  <ListItemText primary="My Profile" />
                </ListItem>
                <ListItem
                  button
                  onClick={() => {
                    localStorage.setItem('accountantLogin', false);
                    localStorage.removeItem('accountingFirmId');
                    setContextState({ accountingFirmId: null, accountantLogin: false });
                  }}
                >
                  <ListItemIcon><LaunchIcon /></ListItemIcon>
                  <ListItemText primary="Portfolios" />
                </ListItem>
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
        <Container className={classes.container}>
          <Hidden smDown>
            <Drawer
              className={`${classes.drawer} noprint`}
              variant="permanent"
              open
              anchor="left"
              classes={{
                paper: classes.permanentDrawerPaper,
              }}
            >
              <Toolbar />
              {this.getNavList()}
            </Drawer>
          </Hidden>
          <Hidden mdUp>
            <Drawer
              anchor="left"
              open={left}
              onClose={this.toggleDrawer('left', false)}
              classes={{
                paper: classes.tempDrawerPaper,
              }}
            >
              {this.getNavList()}
            </Drawer>
          </Hidden>

          <div className={classes.content}>
            <Toolbar className="noprint" />
            <AccountantRoutes location={location} />
            <div className={classes.flexGrow} />
            <AppBar position="static" className={`${classes.bottomAppBar} noprint`}>
              <Toolbar className={classes.footer}>
                <Typography variant="body2" color="inherit">
                  {`© ${moment().format('YYYY')}, `}
                  <Link href="https://www.reihub.net" target="_blank>" color="inherit" underline="always">
                    REI Hub
                  </Link>
                  <br />
                  <Link href="https://support.reihub.net" target="_blank>" color="inherit" underline="always">
                    Knowledge Base
                  </Link>
                </Typography>
                <Typography variant="body2" color="inherit" align="right">
                  <Link href="https://www.reihub.net/terms" target="_blank>" color="inherit" underline="always">
                    Terms of Service
                  </Link>
                  <br />
                  <Link href="https://www.reihub.net/privacy" target="_blank>" color="inherit" underline="always">
                    Privacy Policy
                  </Link>
                </Typography>
              </Toolbar>
            </AppBar>
          </div>
        </Container>
      </div>
    );
  }
}

AccountantDashboard.contextType = PersonContext;

AccountantDashboard.propTypes = {
  classes: PropTypes.objectOf(PropTypes.any).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(AccountantDashboard);
