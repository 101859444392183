import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PropTypes from 'prop-types';
import React from 'react';

import { MESSAGES, REQUIREMENTS, STATUSES } from '~/constants/password-requirements';

const usePasswordStrengthCheckerStyles = makeStyles((theme) => ({
  list: {
    margin: '0',
    padding: '0',
    listStyle: 'none',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.space.xsm,
    marginBottom: theme.space.xxsm,

    '&:last-of-type': {
      marginBottom: '0',
    },
  },
  message: {
    color: theme.palette.text.secondary,
  },
}));

export default function PasswordStrengthChecker({ className, requirements }) {
  const classes = usePasswordStrengthCheckerStyles();

  return (
    <ul className={cx(classes.list, className)}>
      {Object.entries(requirements).map(([requirement, status]) => (
        <li key={requirement} className={classes.item}>
          <ConditionIcon condition={status} />
          <Typography component="span" variant="caption" className={classes.message}>{MESSAGES[requirement]}</Typography>
        </li>
      ))}
    </ul>
  );
}

PasswordStrengthChecker.propTypes = {
  className: PropTypes.string,
  requirements: PropTypes.shape({
    [REQUIREMENTS.LENGTH]: PropTypes.oneOf(Object.values(STATUSES)),
    [REQUIREMENTS.LETTER_CASE]: PropTypes.oneOf(Object.values(STATUSES)),
    [REQUIREMENTS.NUMBER]: PropTypes.oneOf(Object.values(STATUSES)),
    [REQUIREMENTS.SPECIAL_CHAR]: PropTypes.oneOf(Object.values(STATUSES)),
  }).isRequired,
};

PasswordStrengthChecker.defaultProps = {
  className: '',
};

const useConditionIconStyles = makeStyles((theme) => ({
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: '18px',
  },
  initIcon: {
    color: theme.palette.action.active,
    fontSize: '18px',
  },
  metIcon: {
    color: theme.palette.success.main,
    fontSize: '18px',
  },
}));

function ConditionIcon({ condition }) {
  const classes = useConditionIconStyles();

  switch (condition) {
    case STATUSES.ERROR:
      return <ErrorIcon className={classes.errorIcon} />;
    case STATUSES.INIT:
      return <ErrorOutlineIcon className={classes.initIcon} />;
    case STATUSES.MET:
      return <CheckCircleIcon className={classes.metIcon} />;
    default:
      return null;
  }
}

ConditionIcon.propTypes = {
  condition: PropTypes.oneOf(Object.values(STATUSES)).isRequired,
};
