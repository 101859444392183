import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { create, patch } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';

import ContactUsDialog from './ContactUsDialog';

import {
  handleAutocompleteChange,
} from '../functions/InputHandlers';

// const styles = {};

class UpdateSubscriptionDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      step: null,
      contactUs: false,
      planSelect: null,
      unitCount: null,
      planOptions: [],
    };
  }

  getPlanOptions = async () => {
    const { step } = this.state;
    const { organizationId } = this.context;
    const { newUnitCount } = this.props;

    let planSelect = null;

    if (step === null) {
      let unitCount = await create(
        this,
        'reports',
        {
          organizationId,
          reportName: 'unitCount',
        },
      );

      unitCount += newUnitCount;

      const planOptions = [
        {
          name: 'Essential',
          price: 15.00,
          units: 'Up to 3 units',
          maxUnits: 3,
          billing: 'Billed monthly',
          id: 'essential',
        },
        {
          name: 'Growth',
          price: 25.00,
          units: 'Up to 10 units',
          maxUnits: 10,
          billing: 'Billed monthly',
          id: 'growth',
        },
        {
          name: 'Investor',
          price: 45.00,
          units: 'Up to 20 units',
          maxUnits: 20,
          billing: 'Billed monthly',
          id: 'investor',
        },
        {
          name: 'Professional',
          price: 80.00,
          units: 'Unlimited units',
          billing: 'Billed monthly',
          id: 'professional',
        },
      ];

      if (unitCount > 20) {
        // eslint-disable-next-line prefer-destructuring
        planSelect = planOptions[3];
      } else if (unitCount > 10) {
        // eslint-disable-next-line prefer-destructuring
        planSelect = planOptions[2];
      } else if (unitCount > 3) {
        // eslint-disable-next-line prefer-destructuring
        planSelect = planOptions[1];
      } else if (unitCount > 0) {
        // eslint-disable-next-line prefer-destructuring
        planSelect = planOptions[0];
      }

      this.setState({
        planOptions,
        planSelect,
        unitCount,
        step: 'select',
        contactUs: false,
      });
    }
    this.setState({
      error: null,
    });
  };

  updateSubscription = async (event) => {
    event.preventDefault();

    const { planSelect, unitCount } = this.state;
    const { newUnitCount } = this.props;
    const { organizationId } = this.context;

    if (planSelect.maxUnits !== undefined && unitCount > planSelect.maxUnits) {
      this.setState({
        error: {
          message: `You ${newUnitCount ? 'will ' : ''}have ${unitCount} active units in REI Hub. Please select a plan that includes enough units.`,
        },
      });
      return;
    }

    try {
      await patch(
        this,
        'stripe-subscriptions',
        organizationId,
        {
          plan: planSelect.id,
          maxUnits: planSelect.maxUnits,
        },
        true, // throw error
      );
      this.goToThanks();
    } catch (subscriptionErr) {
      this.setState({
        error: {
          message: `Your subscription could not be updated - please contact us. Message: ${subscriptionErr.message}`,
        },
      });
    }
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({
      step: null,
      contactUs: false,
      planSelect: null,
    });
    closeDialog();
  };

  goToThanks = () => {
    const { organizationId, setOrganizationId } = this.context;

    setOrganizationId(organizationId);
    this.setState({ step: 'thanks' });
  };

  getDialogContent = () => {
    const {
      step,
      planOptions, planSelect, error,
    } = this.state;

    if (step === 'select') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Update Subscription Plan</DialogTitle>
          <DialogContent>
            <form onSubmit={this.updateSubscription}>
              <Autocomplete
                options={planOptions}
                getOptionLabel={(option) => `${option.name} (${option.units})`}
                value={planSelect}
                onChange={handleAutocompleteChange(
                  'planSelect',
                  this,
                )}
                getOptionSelected={(option, value) => option.id === value.id}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Plan"
                    fullWidth
                    required
                  />
                )}
              />
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body1">
                  {`When updating your subscription you will receive prorated credit for any time remaining on your 
                    existing subscription plan and your billing cycle will be reset to today.`}
                </Typography>
              </Box>
              <Box
                marginTop={2}
              >
                {error && <Typography color="error">{error.message}</Typography>}
                <Button
                  variant="outlined"
                  color="secondary"
                  type="submit"
                  size="large"
                  fullWidth
                  disableElevation
                >
                  Update
                </Button>
              </Box>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ contactUs: true })} color="primary">
              Contact Us
            </Button>
            <Button onClick={this.closeDialog} color="primary">
              Cancel
            </Button>
          </DialogActions>
        </>
      );
    }

    if (step === 'thanks') {
      return (
        <>
          <DialogTitle id="alert-dialog-title">Update Successful</DialogTitle>
          <DialogContent>
            <Typography variant="body1" gutterBottom>
              Thank you. We have successfully updated your subscription.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </>
      );
    }

    return null;
  };

  render() {
    const { isOpen } = this.props;
    const { contactUs } = this.state;

    if (contactUs) {
      return (
        <ContactUsDialog
          isOpen
          closeDialog={() => this.closeDialog()}
        />
      );
    }

    return (
      <Dialog
        open={isOpen}
        scroll="body"
        disableBackdropClick
        disableEscapeKeyDown
        onEnter={this.getPlanOptions}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {this.getDialogContent()}
      </Dialog>
    );
  }
}

UpdateSubscriptionDialog.contextType = PersonContext;

UpdateSubscriptionDialog.defaultProps = {
  newUnitCount: 0,
};

UpdateSubscriptionDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  newUnitCount: PropTypes.node,
};

export default UpdateSubscriptionDialog;
