import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import HomeWorkOutlinedIcon from '@material-ui/icons/HomeWorkOutlined';
import NumberFormat from 'react-number-format';

import { Card, Fab, makeStyles } from '@material-ui/core';
import { PlayCircleFilled, PlayCircleOutline } from '@material-ui/icons';
import AddPropertyDialog from './AddPropertyDialog';
import AddSubportfolioDialog from './AddSubportfolioDialog';
import VideoPlayerDialog from './VideoPlayerDialog';

import { find, create, patch } from '../feathersWrapper';

import { PersonContext } from '../contexts/PersonContext';
import history from '../history';
import { useSegmentTrack } from '../functions/SegmentFunctions';
import PurpleButton from './StyledComponents/PurpleButton';

const styles = makeStyles((theme) => ({
  root: { paddingTop: '55px' },
  videoWrapper: {
    position: 'relative',
    paddingBottom: '56.25%',
    paddingTop: '25px',
    height: 0,
  },
  videoIFrame: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  heroImage: {
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '248px',
    },
  },
  onboardingCard: {
    maxWidth: '600px',
    width: '100%',
  },
}));

export default function ViewWelcomePropertiesDialog({ isOpen, refreshProperties }) {
  const classes = styles();
  const context = useContext(PersonContext);
  const {
    organizationId,
    partnerName,
    multiEntity,
    setOrganizationId,
  } = context;

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [addPropertyDialog, setAddPropertyDialog] = useState(false);
  const [addSubportfolioDialog, setAddSubportfolioDialog] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [state, setState] = useState({});
  const tracking = useSegmentTrack();

  useEffect(() => {
    async function getData() {
      const query = { organizationId };
      const properties = await find(this, 'properties', { query });
      if (partnerName) {
        const unitCount = await create(
          this,
          'reports',
          { organizationId, reportName: 'unitCount' },
        );
        setState((prevState) => ({
          ...prevState,
          unitCount,
          key: 'partnerImported',
          properties: properties.data,
        }));
      } else if (properties.total > 0) {
        setState((prevState) => ({
          ...prevState,
          propertyCount: properties.total,
          key: 'addAnotherProperty',
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          key: 'propertiesOverview',
        }));
      }
      setLoading(false);
    }
    getData();
  }, [organizationId, partnerName]);

  const processPropertyValues = async (event) => {
    event.preventDefault();
    if (submitting) {
      return;
    }
    setSubmitting(true);

    const promises = [];
    state.properties.forEach((property) => {
      promises.push(patch(this, 'properties', property.id, {
        organizationId: property.organizationId,
        marketValue: property.marketValue,
      }));
    });
    await Promise.all(promises);
    setSubmitting(false);
    setState({ ...state, key: 'addSubportfolios' });
  };

  const goToAccounts = () => {
    history.push('/accounts/banking');
  };

  const onAddProperty = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onboardingAddProperty',
      organizationId,
    });

    refreshProperties();
    const query = { organizationId };
    const properties = await find(this, 'properties', { query });

    setState({
      ...state,
      key: 'addAnotherProperty',
      propertyCount: properties.total,
      properties: properties.data,
    });
  };

  const onAddSubportfolio = async () => {
    if (!multiEntity) {
      const patchData = {
        multiEntity: true,
      };
      await patch(this, 'organizations', organizationId, patchData);
      await setOrganizationId(organizationId);
    }
    const query = { organizationId };
    const subportfolios = await find(this, 'entities', { query });

    setState({
      ...state,
      key: 'addAnotherSubportfolio',
      subportfolioCount: subportfolios.total,
    });
  };

  const handleMarketValueChange = (value, index) => {
    const property = state.properties[index];
    property.marketValue = value;
    const newProperties = [...state.properties];
    newProperties[index] = property;
    setState({ ...state, properties: newProperties });
  };

  const getDialogContent = (key) => {
    switch (key) {
      case 'propertiesOverview':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Box>
                <Box style={{ position: 'relative' }} mb={1}>
                  <img className={classes.heroImage} src="./rei_hub_properties_intro_hero.png" alt="Properties" />
                  <Fab
                    color="secondary"
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      color: 'white',
                    }}
                    onClick={() => {
                      tracking(
                        'watch_properties_overview clicked',
                        { location: 'Onboarding - Add Properties: Intro' },
                      );
                      setVideoDialogOpen(true);
                    }}
                  >
                    <PlayCircleFilled color="white" fontSize="24px" />
                  </Fab>
                </Box>
                <Box mb={3}>
                  <Box mb={2} textAlign="center">
                    <Typography variant="h6">
                      Organize your portfolio by property
                    </Typography>
                  </Box>
                  <Box mb={1} textAlign="center">
                    <Typography variant="body2">
                      {'You\'ll use REI Hub to categorize transactions by property and create property-specific reports.'}
                    </Typography>
                  </Box>
                </Box>
                <Box maxWidth="400px" marginX="auto" textAlign="center" mb={2}>
                  <PurpleButton
                    variant="contained"
                    onClick={() => {
                      tracking(
                        'add_first_property clicked',
                        { location: 'Onboarding - Add Properties: Intro' },
                      );
                      setAddPropertyDialog(true);
                    }}
                  >
                    Add First Property
                  </PurpleButton>
                  <Box mt={1} mb={1}>
                    <PurpleButton
                      variant="text"
                      onClick={() => {
                        tracking(
                          'skip_for_now clicked',
                          { location: 'Onboarding - Add Properties: Intro' },
                        );
                        goToAccounts();
                      }}
                    >
                      Skip For Now
                    </PurpleButton>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'addAnotherProperty':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Typography color="secondary"><HomeWorkOutlinedIcon /></Typography>
              <Typography variant="caption" gutterBottom>
                STEP 2: ADD YOUR PROPERTIES
              </Typography>
              <Typography variant="h6" gutterBottom>
                {`You've created ${pluralize('property', state.propertyCount, true)} in your
                portfolio.`}
              </Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`You'll use REI Hub to categorize transactions by property as they are added to
                your books.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'finished clicked',
                    { location: 'Onboarding - Add Properties: Success' },
                  );
                  setState({ ...state, key: 'addSubportfolios' });
                }}
              >
                Finished
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    tracking(
                      'add_another_property clicked',
                      { location: 'Onboarding - Add Properties: Success' },
                    );
                    setAddPropertyDialog(true);
                  }}
                >
                  Add Another Property
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'addSubportfolios':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Would you like to add sub-portfolios to group your properties?
              </Typography>
            </Box>
            <Box mb={1}>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2">
                  {`Sub-portfolios provide an additional layer of reporting between the overall
                  portfolio and individual properties. They are most commonly used by investors
                  with multiple passthrough LLC's tracked within a single portfolio or by property
                  managers to group and track properties by owner.`}
                </Typography>
              </Box>
            </Box>
            <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
              <Box marginBottom={1}>
                <PurpleButton
                  variant="contained"
                  onClick={() => {
                    tracking(
                      'add_sub-portfolio clicked',
                      { location: 'Onboarding - Add Sub-Portfolio: Intro' },
                    );
                    setAddSubportfolioDialog(true);
                  }}
                >
                  Add A Sub-Portfolio
                </PurpleButton>
              </Box>
              <Button
                color="secondary"
                variant="outlined"
                size="large"
                fullWidth
                onClick={() => {
                  tracking(
                    'no_sub-portfolios clicked',
                    { location: 'Onboarding - Add Sub-Portfolio: Intro' },
                  );
                  goToAccounts();
                }}
              >
                No Sub-Portfolios
              </Button>
            </Box>
          </DialogContent>
        );
      case 'addAnotherSubportfolio':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Typography color="secondary"><HomeWorkOutlinedIcon /></Typography>
              <Typography variant="caption" gutterBottom>
                ADD YOUR SUB-PORTFOLIOS
              </Typography>
              <Typography variant="h6" gutterBottom>
                {`You've created ${pluralize('sub-portfolio', state.subportfolioCount, true)}.`}
              </Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`Sub-portfolios provide an additional layer of reporting between the overall
                portfolio and individual properties.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'finished clicked',
                    { location: 'Onboarding - Add Sub-Portfolio: Success' },
                  );
                  goToAccounts();
                }}
              >
                Finished
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    tracking(
                      'add_another_sub-portfolio clicked',
                      { location: 'Onboarding - Add Sub-Portfolio: Success' },
                    );
                    setAddSubportfolioDialog(true);
                  }}
                >
                  Add Another Sub-Portfolio
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'partnerImported':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Typography color="secondary"><HomeWorkOutlinedIcon /></Typography>
              <Typography variant="caption" gutterBottom>
                STEP 2: REVIEW YOUR PROPERTIES
              </Typography>
              <Typography variant="h6" gutterBottom>
                {`We've imported ${pluralize('property', state.propertyCount, true)} ${state.unitCount > state.propertyCount ? `containing ${pluralize('unit', state.unitCount, true)} ` : ''}from ${partnerName} into your portfolio.`}
              </Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`You'll use REI Hub to categorize transactions by property as they are added to
                your books. You can select a property to view its dashboard including financial
                summaries and organizational items from this screen. Add additional properties
                anytime through the ${partnerName} dashboard; they will automatically sync to REI
                Hub.`}
              </Typography>
            </Box>
            <Box marginBottom={1} maxWidth="400px" marginX="auto" textAlign="center">
              <Button
                color="secondary"
                variant="text"
                size="large"
                fullWidth
                startIcon={<PlayCircleOutline />}
                onClick={() => setVideoDialogOpen(true)}
              >
                Watch Properties Overview
              </Button>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={setState({ ...state, key: 'setPropertyValues' })}
              >
                Add Property Values
              </PurpleButton>
            </Box>
          </DialogContent>
        );
      case 'setPropertyValues':
        return (
          <DialogContent>
            <form onSubmit={processPropertyValues}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Please provide an estimated current market value for each property.
                </Typography>
              </Box>
              <Box mb={2}>
                {state.properties.map((property, index) => (
                  <FormControl margin="dense" fullWidth key={property.id}>
                    <InputLabel required>
                      {`Market Value of ${property.address1}`}
                    </InputLabel>
                    <NumberFormat
                      value={property.marketValue}
                      required
                      thousandSeparator
                      prefix="$"
                      decimalScale={2}
                      onValueChange={(values) => {
                        handleMarketValueChange(values.floatValue, index);
                      }}
                      customInput={Input}
                      isAllowed={(values) => {
                        const { formattedValue, floatValue } = values;
                        return formattedValue === '' || floatValue <= 99999999;
                      }}
                    />
                  </FormControl>
                ))}
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="body2">
                    {`REI Hub uses the current market value of properties in your portfolio to help
                    calculate your investment retuns.`}
                  </Typography>
                </Box>
              </Box>
              <Box maxWidth="400px" marginX="auto" mt={3} mb={2}>
                <PurpleButton
                  variant="contained"
                >
                  Save and Continue
                </PurpleButton>
              </Box>
            </form>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  if (loading) {
    return null;
  }

  if (addPropertyDialog) {
    return (
      <AddPropertyDialog
        isOpen
        closeDialog={() => setAddPropertyDialog(false)}
        onAddProperty={onAddProperty}
      />
    );
  }

  if (addSubportfolioDialog) {
    return (
      <AddSubportfolioDialog
        closeDialog={() => setAddSubportfolioDialog(false)}
        onAdd={onAddSubportfolio}
      />
    );
  }

  return (
    <>
      <VideoPlayerDialog
        open={videoDialogOpen}
        onClose={() => {
          setVideoDialogOpen(false);
        }}
        source="https://www.youtube.com/embed/BpbnZBbruhk?rel=0&amp;wmode=opaque"
        allowFullScreen={false}
      />
      <Card
        open={isOpen}
        scroll="body"
        className={classes.onboardingCard}
      >
        {getDialogContent(state.key)}
      </Card>
    </>
  );
}

ViewWelcomePropertiesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  refreshProperties: PropTypes.func.isRequired,
};
