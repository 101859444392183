import mediaBreakpoints from '../constants/media-breakpoints';

// eslint-disable-next-line import/prefer-default-export
export const checkIsSmallScreen = () => {
  if (typeof window !== 'undefined') {
    return window.innerWidth <= mediaBreakpoints.small;
  }

  return false;
};
