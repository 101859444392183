import React, { useState } from 'react';
import {
  Box,
  Button,
  CardContent, Fab, Grid, makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { PlayCircleFilled } from '@material-ui/icons';
import { useSegmentTrack } from '../functions/SegmentFunctions';
import CardBase from './CardBase';
import PurpleButton from './StyledComponents/PurpleButton';
import VideoPlayerDialog from './VideoPlayerDialog';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '3px',
    display: 'flex',
    flexDirection: 'column',
    padding: '5px',
    gap: theme.spacing(3),
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-5px',
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '600px',
    gap: theme.spacing(2),
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '100%',
    maxWidth: '300px',
  },
  videoPlayButton: {
    position: 'absolute',
    alignSelf: 'center',
    color: 'white',
  },
  image: {
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '248px',
    },
  },
  playIconContainer: {
    display: 'flex',
    color: 'white',
  },
  iconContainer: {
    justifySelf: 'center',
    width: '56px',
    height: '56px',
  },
  icon: {
    width: '100%',
    height: '100%',
  },
}));

export default function EmptyState({
  image,
  icon,
  title,
  body,
  buttonProps,
  videoProps,
}) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const tracking = useSegmentTrack();
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

  const getButtons = () => buttonProps.map((button) => {
    if (button.color === 'purple') {
      return (
        <PurpleButton
          key={button.text}
          variant={button.variant}
          onClick={() => {
            if (button.segmentProps) {
              tracking(
                button.segmentProps.event,
                { location: button.segmentProps.location },
              );
            }
            button.onClickFunction();
          }}
        >
          {button.text}
        </PurpleButton>
      );
    }
    return (
      <Button
        key={button.text}
        color={button.color}
        variant={button.variant}
        size="large"
        onClick={() => {
          if (button.segmentProps) {
            tracking(
              button.segmentProps.event,
              { location: button.segmentProps.location },
            );
          }
          button.onClickFunction();
        }}
        fullWidth
      >
        {button.text}
      </Button>
    );
  });

  return (
    <>
      {videoProps && (
        <VideoPlayerDialog
          open={isVideoDialogOpen}
          onClose={() => {
            setIsVideoDialogOpen(false);
          }}
          source={videoProps.source}
          allowFullScreen={false}
        />
      )}
      <Grid item xs={12}>
        <CardBase>
          <CardContent>
            <Box className={classes.container}>
              {image && (
                <Box className={classes.imageContainer}>
                  <img className={classes.image} src={image} alt="Empty State" />
                  {videoProps && (
                    <Fab
                      color="secondary"
                      className={classes.videoPlayButton}
                      onClick={() => {
                        if (videoProps.videoSegmentProps) {
                          tracking(
                            videoProps.videoSegmentProps.event,
                            { location: videoProps.videoSegmentProps.location },
                          );
                        }
                        setIsVideoDialogOpen(true);
                      }}
                    >
                      <Box className={classes.playIconContainer}>
                        <PlayCircleFilled fontSize="medium" />
                      </Box>
                    </Fab>
                  )}
                </Box>
              )}
              {icon && (
                <Box className={classes.iconContainer}>
                  <img className={classes.icon} src={icon} alt="Empty State Icon" />
                </Box>
              )}
              <Box className={classes.textContainer}>
                <Typography variant="h6" align="center">
                  {title}
                </Typography>
                {body}
              </Box>
              <Box className={classes.buttonContainer}>
                {getButtons()}
              </Box>
            </Box>
          </CardContent>
        </CardBase>
      </Grid>
    </>
  );
}

EmptyState.defaultProps = {
  image: '',
  icon: '',
  videoProps: null,
};

EmptyState.propTypes = {
  image: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.object.isRequired,
  buttonProps: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string.isRequired,
    variant: PropTypes.string,
    text: PropTypes.string.isRequired,
    segmentProps: PropTypes.shape({
      event: PropTypes.string,
      location: PropTypes.string,
    }),
    onClickFunction: PropTypes.func.isRequired,
  })).isRequired,
  videoProps: PropTypes.shape({
    source: PropTypes.string.isRequired,
    videoSegmentProps: PropTypes.shape({
      event: PropTypes.string,
      location: PropTypes.string,
    }),
  }),
};
