import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import queryString from 'query-string';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import ButtonBase from '@material-ui/core/ButtonBase';
import CardContent from '@material-ui/core/CardContent';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from '@material-ui/core/Button';

import history from '../history';
import { get, find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  asyncHandleChange,
} from '../functions/InputHandlers';

import ErrorCard from '../components/ErrorCard';
import PageHeader from '../components/PageHeader';
import CardBase from '../components/CardBase';
import LinkBase from '../components/LinkBase';
import FilterLeasesDialog from '../components/FilterLeasesDialog';
import EditContactDialog from '../components/EditContactDialog';

const styles = (theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  textButton: {
    display: 'block',
    textDecoration: 'underline',
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
  inlineButton: {
    padding: 0,
    color: theme.palette.action.active,
  },
});

class Leases extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filterDialog: false,
      editContactDialog: false,
      filter: {
        page: 0,
        propertyId: null,
        unitId: null,
        propertyIdSelect: null,
        unitIdSelect: null,
      },
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    const { filter } = this.state;
    const parsedQuery = queryString.parse(location.search);

    if (parsedQuery.propertyId) {
      const property = await get(this, 'properties', parsedQuery.propertyId);
      filter.propertyId = parsedQuery.propertyId;
      filter.propertyIdSelect = { label: property.address1, value: property.id };
      filter.selectedProperty = property;
      await asyncHandleChange('filter', filter, this);
    }

    if (parsedQuery.unitId) {
      const unit = await get(this, 'units', parsedQuery.unitId);
      filter.unitId = parsedQuery.unitId;
      filter.unitIdSelect = { label: unit.name, value: unit.id };
      await asyncHandleChange('filter', filter, this);
    }

    if (Object.keys(parsedQuery).length > 0) {
      history.replace('/organization/leases');
    }

    await this.getLeases();
  }

  getLeases = async () => {
    const { organizationId } = this.context;
    const { filter } = this.state;
    try {
      const query = {
        organizationId,
        $limit: 20,
        $skip: filter.page * 20,
        $sort: {
          endDate: -1,
        },
      };

      if (filter.propertyId !== null) {
        query.propertyId = filter.propertyId;
      }

      if (filter.unitId !== null) {
        query.unitId = filter.unitId;
      }

      const leases = await find(this, 'leases', { query });

      this.setState({
        total: leases.total,
        leases: leases.data,
        loading: false,
      });
    } catch (error) {
      this.setState({ error });
      this.setState({ loading: false });
    }
  };

  appliedFilter = () => {
    const { filter } = this.state;
    const filterArray = [];
    if (filter.propertyId) {
      filterArray.push({
        label: 'Property',
        value: filter.propertyIdSelect.label,
        clear: () => this.clearFilterFields(['propertyId', 'propertyIdSelect', 'unitId', 'unitIdSelect']),
      });
    }
    if (filter.unitId) {
      filterArray.push({
        label: 'Unit',
        value: filter.unitIdSelect.label,
        clear: () => this.clearFilterFields(['unitId', 'unitIdSelect']),
      });
    }
    return filterArray;
  };

  clearFilterFields = (fieldList) => {
    const { filter } = this.state;
    const newFilter = { ...filter };
    fieldList.forEach((fieldName) => {
      newFilter[fieldName] = null;
    });
    newFilter.page = 0;
    this.updateFilter(newFilter);
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getLeases();
  };

  handleChangePage = async (event, newPage) => {
    await asyncHandleChange('nested_filter_page', newPage, this);
    this.getLeases();
  };

  actionButtons = () => {
    const buttons = [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Add Lease', link: '/organization/leases/add', class: 'add' },
    ];
    return buttons;
  };

  render() {
    const { classes, match } = this.props;
    const {
      leases, total, filter, filterDialog,
      editContactDialog, personId, loading, error,
    } = this.state;

    return (
      <>
        {!loading && error && (
          <ErrorCard error={error.message} />
        )}
        {!loading && !error && (
          <Grid spacing={3} alignItems="flex-start" justify="flex-start" container className={classes.grid}>
            <PageHeader
              match={match}
              title="Leases"
              appliedFilter={this.appliedFilter()}
              actionButtons={this.actionButtons()}
            />
            <FilterLeasesDialog
              filter={filter}
              isOpen={filterDialog}
              closeDialog={() => this.setState({ filterDialog: false })}
              updateFilter={this.updateFilter}
            />
            <EditContactDialog
              isOpen={editContactDialog}
              closeDialog={() => this.setState({ editContactDialog: false })}
              onEditContact={this.getLeases}
              personId={personId}
            />
            <Grid item xs={12} md={12}>
              <CardBase>
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><Typography variant="subtitle2">Dates</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2">Property</Typography></TableCell>
                        <TableCell><Typography variant="subtitle2">Tenants</Typography></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leases.map((lease) => (
                        <TableRow key={lease.id}>
                          <TableCell>
                            <LinkBase to={`/organization/leases/lease/${lease.id}`}>
                              {`Start: ${moment(lease.startDate).format('MM/DD/YYYY')}`}
                              <br />
                              {`End: ${lease.endDate ? moment(lease.endDate).format('MM/DD/YYYY') : 'None'}`}
                            </LinkBase>
                            {lease.attachmentURL && (
                              <Button
                                className={classes.inlineButton}
                                aria-label="attachments"
                                href={lease.attachmentURL.replace('~1/', '~1/nth/0/')}
                                target="_blank"
                              >
                                <AttachFileIcon fontSize="small" />
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>
                            {lease.property && <Typography variant="body2" component="div">{lease.property.address1}</Typography>}
                            {lease.unit && <Typography variant="body2" component="div">{lease.unit.name}</Typography>}
                          </TableCell>
                          <TableCell>
                            {lease.people.map((person) => (
                              <ButtonBase
                                key={person.id}
                                className={classes.textButton}
                                onClick={() => {
                                  this.setState({
                                    personId: person.id,
                                    editContactDialog: true,
                                  });
                                }}
                              >
                                <Typography variant="body2">
                                  {`${person.firstName} ${person.lastName}`}
                                </Typography>
                              </ButtonBase>
                            ))}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          variant="footer"
                          count={total}
                          page={filter.page}
                          rowsPerPage={20}
                          onChangePage={this.handleChangePage}
                          rowsPerPageOptions={[20]}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </CardContent>
              </CardBase>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

Leases.contextType = PersonContext;

Leases.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Leases);
