import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import PlaceIcon from '@material-ui/icons/Place';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import EmptyState from '~/components/EmptyState';
import { find } from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import history from '../history';

import {
  asyncHandleChange,
} from '../functions/InputHandlers';
import {
  parseQuery,
  buildQuery,
  appliedFilter,
} from '../functions/FilterFunctions';

import CardBase from '../components/CardBase';
import PageHeader from '../components/PageHeader';

import ViewWelcomePropertiesDialog from '../components/ViewWelcomePropertiesDialog';
import AddPropertyDialog from '../components/AddPropertyDialog';
import SelectPropertiesMoreDialog from '../components/SelectPropertiesMoreDialog';
import FilterDialog from '../components/FilterDialog';

const PROPERTIES_EMPTY_STATE_SEGMENT_LOCATION = 'Properties Empty State';

const styles = (theme) => ({
  grid: {
    width: '100%',
    margin: 0,
  },
  outlinedCard: {
    height: '100%',
    border: '5px dashed rgba(0,0,0,.23)',
    boxShadow: 'none',
    background: '#f5f5f5',
  },
  imagePaper: {
    padding: '0',
    backgroundColor: theme.palette.grey['200'],
    position: 'relative',
  },
  noImageDiv: {
    paddingTop: 'calc(((100% * 3 / 7) - 72px)/2)',
    paddingBottom: 'calc(((100% * 3 / 7) - 72px)/2)',
    fontSize: '72px',
    display: 'flex',
    justifyContent: 'center',
  },
  multiUnitChip: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
    border: '1px solid #431C5D',
    color: '#431C5D',
    backgroundColor: '#FFF',
  },
  rentalImage: {
    display: 'block',
  },
});

class Properties extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      addPropertyDialog: false,
      filterDialog: false,
      moreDialog: false,
      properties: [],
      allPropertiesCount: 0,
      filter: {
        entityId: null,
        entityIdSelect: null,
        inactive: undefined,
        page: 0,
        pageSize: 200,
      },
    };
  }

  async componentDidMount() {
    await parseQuery(this);
    await this.getProperties();

    const { showWelcome } = this.context;
    const { location } = this.props;
    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.add) {
      this.setState({
        addPropertyDialog: true,
      });
      history.replace('/properties');
    }

    this.setState({
      showWelcome,
      loading: false,
    });
  }

  getProperties = async () => {
    const { organizationId } = this.context;

    const query = {
      inactive: false,
      ...buildQuery(this),
      organizationId,
      $sort: {
        address1: 1,
        id: 1,
      },
    };
    const propertiesPromise = find(this, 'properties', { query });

    const allPropertiesCountPromise = find(this, 'properties', {
      query: {
        organizationId,
        $limit: 0,
      },
    });

    const [properties, allPropertiesCount] = await Promise.all([
      propertiesPromise, allPropertiesCountPromise,
    ]);

    this.setState({
      allPropertiesCount: allPropertiesCount.total,
      properties: properties.data,
    });
  };

  updateFilter = async (filter) => {
    await asyncHandleChange('filter', filter, this);
    this.getProperties();
  };

  openMore = (propertyId) => {
    this.setState({
      moreDialog: true,
      morePropertyId: propertyId,
    });
  };

  actionButtons = () => {
    const { allPropertiesCount, filter, properties } = this.state;

    const actionButtons = [
      { text: 'Filter', action: (() => this.setState({ filterDialog: true })), class: 'filter' },
      { text: 'Add Property', action: (() => this.setState({ addPropertyDialog: true })), class: 'add' },
    ];

    if (properties.length === 0 && !filter.entityId && !filter.inactive && !allPropertiesCount) {
      return [];
    }

    return actionButtons;
  };

  clearFilters = async () => {
    this.setState({ loading: true });
    await asyncHandleChange('filter', {
      entityId: null,
      entityIdSelect: null,
      inactive: undefined,
      page: 0,
      pageSize: 200,
    }, this);
    await this.getProperties();
    this.setState({ loading: false });
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading, showWelcome, addPropertyDialog,
      properties, moreDialog, morePropertyId,
      filter, filterDialog,
    } = this.state;

    const hasNoProperties = properties.length === 0 && !filter.entityId && !filter.inactive;
    const hasNoFilteredProperties = properties.length === 0 && (filter.entityId || filter.inactive);

    if (loading) {
      return null;
    }

    if (showWelcome && !addPropertyDialog) {
      return (
        <ViewWelcomePropertiesDialog
          isOpen
          refreshProperties={this.getProperties}
        />
      );
    }

    return (
      <Grid spacing={3} container className={classes.grid}>
        <PageHeader
          match={match}
          actionButtons={this.actionButtons()}
          appliedFilter={appliedFilter(this.updateFilter, this)}
          title="Properties"
        />
        {addPropertyDialog && (
          <AddPropertyDialog
            isOpen
            closeDialog={() => this.setState({ addPropertyDialog: false })}
            onAddProperty={this.getProperties}
          />
        )}
        <FilterDialog
          filter={filter}
          isOpen={filterDialog}
          closeDialog={() => this.setState({ filterDialog: false })}
          updateFilter={this.updateFilter}
          subportfolio
          inactive
        />
        <SelectPropertiesMoreDialog
          propertyId={morePropertyId}
          isOpen={moreDialog}
          closeDialog={() => this.setState({
            moreDialog: false,
            morePropertyId: null,
          })}
        />
        {properties.map((property) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={property.id}>
            <CardBase>
              <Box
                display="flex"
                flexDirection="column"
                height={1}
                pb={2}
              >
                <Paper width="100%" elevation={0} className={classes.imagePaper}>
                  {property.image && property.image !== '' ? (
                    <img src={property.image} width="100%" alt="Unit" className={classes.rentalImage} />
                  ) : (
                    <div className={classes.noImageDiv}>
                      <PlaceIcon color="disabled" fontSize="inherit" />
                    </div>
                  )}
                  {property.unitSelection === 'multi' && (
                    <Chip
                      className={classes.multiUnitChip}
                      label={pluralize('UNIT', property.unitCount, true)}
                    />
                  )}
                </Paper>
                <Box display="flex" flexDirection="column" height={1} px={2} pt={1}>
                  <Box flexGrow={1} pb={1}>
                    <Typography variant="h6">
                      {`${property.address1}${property.address2 && property.unitSelection === 'single' ? `, ${property.address2}` : ''}`}
                    </Typography>
                    <Typography variant="body2">{`${property.city}, ${property.state} ${property.zip}`}</Typography>
                  </Box>
                  <ButtonGroup color="secondary" variant="outlined" fullWidth>
                    <Button fullWidth component={Link} to={`/properties/property/${property.id}`}>
                      Dashboard
                    </Button>
                    <Button fullWidth onClick={() => this.openMore(property.id)}>
                      More
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </CardBase>
          </Grid>
        ))}
        {hasNoProperties && (
          <EmptyState
            body={(
              <Typography variant="body1" align="center">
                You&apos;ll use REI Hub to categorize transactions by property-specific reports
                like the IRS Schedule E.
              </Typography>
            )}
            buttonProps={[
              {
                color: 'purple',
                segmentProps: { event: 'add_property clicked', location: PROPERTIES_EMPTY_STATE_SEGMENT_LOCATION },
                variant: 'contained',
                text: 'Add Property',
                onClickFunction: () => this.setState({ addPropertyDialog: true }),
              },
            ]}
            image="/rei_hub_properties_empty_state.jpg"
            title="Organize your portfolio by property"
            videoProps={{
              source: 'https://www.youtube.com/embed/BpbnZBbruhk?rel=0&amp;wmode=opaque',
              videoSegmentProps: { event: 'watch_overview_video clicked', location: PROPERTIES_EMPTY_STATE_SEGMENT_LOCATION },
            }}
          />
        )}
        {hasNoFilteredProperties && (
          <EmptyState
            body={(
              <Typography variant="body1" align="center">
                Try adjusting your filter options to find what you are looking for or add a new
                property below.
              </Typography>
            )}
            buttonProps={[
              {
                color: 'purple',
                variant: 'contained',
                text: 'Clear Filters',
                onClickFunction: this.clearFilters,
              },
              {
                color: 'secondary',
                segmentProps: { event: 'add_property clicked', location: PROPERTIES_EMPTY_STATE_SEGMENT_LOCATION },
                variant: 'outlined',
                text: 'Add Property',
                onClickFunction: () => this.setState({ addPropertyDialog: true }),
              },
            ]}
            icon="/icons/user_action_empty_state_icon.svg"
            title="No properties were found"
          />
        )}
      </Grid>
    );
  }
}

Properties.contextType = PersonContext;

Properties.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(Properties);
