import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'element-remove';
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import { Router } from 'react-router-dom';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import * as atatus from 'atatus-spa';
import App from './App';
import history from './history';
import theme from './themes/theme';
import * as serviceWorker from './serviceWorker';
// import packageJson from '../package.json';
import metaPrivate from './metaPrivate.json';

// sets the app version variable to be used in cache invalidation
global.appVersion = metaPrivate.version;

// logging package
if (process.env.NODE_ENV === 'production') {
  atatus.config('945eb4b6d1d74e36bbfaefddc6e0ebc8').install();
}

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <App />
      </Router>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
