import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import CardBase from '~/components/CardBase';
import PageHeader from '~/components/PageHeader';
import { PersonContext } from '~/contexts/PersonContext';
import client from '~/feathers';
import { handleTextFieldChange } from '~/functions/InputHandlers';
import history from '~/history';

import UpdatePassword from './UpdatePassword';

const styles = {
  grid: {
    width: '100%',
    margin: 0,
  },
  updatePasswordButton: {
    display: 'block',
    marginBlock: '8px',
  },
};

const actionButtons = [
  { text: 'Cancel', link: '/', class: 'cancel' },
];

class MyProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      submitting: false,
      isPasswordUpdateVisible: false,
    };
  }

  componentDidMount() {
    const { context } = this;
    this.setState({
      firstName: context.firstName,
      lastName: context.lastName,
      email: context.email,
      phone: context.phone,
      loading: false,
    });
  }

  updateProfile = async (event) => {
    event.preventDefault();
    const {
      submitting,
      email,
      firstName,
      lastName,
      phone,
    } = this.state;
    const { context } = this;

    if (submitting) {
      return;
    }

    this.setState({ submitting: true });

    const usersService = client.service('users');
    const peopleService = client.service('people');
    let caughtError = false;
    const userSubmitObject = {
      firstName,
      lastName,
      phone,
      email: email.trim().toLowerCase(),
    };

    await usersService
      .patch(context.user.id, userSubmitObject)
      .catch((error) => {
        caughtError = true;
        this.setState({ error });
        this.setState({ submitting: false });
      });

    if (!caughtError) {
      const submitObject = { ...this.state };
      submitObject.email = submitObject.email.trim().toLowerCase();
      peopleService
        .patch(context.id, submitObject)
        .then(() => {
          history.push('/');
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    }
  };

  render() {
    const { classes, match } = this.props;
    const {
      loading,
      submitting,
      error,
      firstName,
      lastName,
      phone,
      email,
      isPasswordUpdateVisible,
    } = this.state;

    return (
      <>
        {!loading && (
          <Grid spacing={3} alignItems="center" justify="center" container className={classes.grid}>
            <PageHeader match={match} actionButtons={actionButtons} title="My Profile" />
            <Grid item xs={12}>
              <CardBase>
                <CardContent>
                  <form onSubmit={this.updateProfile}>
                    <TextField
                      label="First Name"
                      id="firstName"
                      fullWidth
                      margin="dense"
                      InputProps={{
                        value: firstName,
                        name: 'firstName',
                        onChange: handleTextFieldChange(this),
                        required: true,
                      }}
                    />
                    <TextField
                      label="Last Name"
                      id="lastName"
                      fullWidth
                      margin="dense"
                      InputProps={{
                        value: lastName,
                        name: 'lastName',
                        onChange: handleTextFieldChange(this),
                        required: true,
                      }}
                    />
                    <TextField
                      label="Phone (recommended)"
                      id="phone"
                      fullWidth
                      margin="dense"
                      helperText="Our US-based customer support is here to help if you need us"
                      InputProps={{
                        value: phone,
                        name: 'phone',
                        onChange: handleTextFieldChange(this),
                        type: 'tel',
                        autoComplete: 'tel-national',
                      }}
                    />
                    <TextField
                      label="Email"
                      id="email"
                      fullWidth
                      margin="dense"
                      InputProps={{
                        value: email,
                        name: 'email',
                        onChange: handleTextFieldChange(this),
                        required: true,
                        type: 'email',
                      }}
                    />

                    <Button
                      color="primary"
                      onClick={() => this.setState({ isPasswordUpdateVisible: true })}
                      className={classes.updatePasswordButton}
                    >
                      Update Password
                    </Button>
                    {isPasswordUpdateVisible && (
                      <UpdatePassword
                        onClose={() => this.setState({ isPasswordUpdateVisible: false })}
                      />
                    )}

                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      disabled={submitting}
                    >
                      Update Profile
                    </Button>
                    <Typography color="error">{error && error.message}</Typography>
                  </form>

                </CardContent>
              </CardBase>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

MyProfile.contextType = PersonContext;

MyProfile.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  match: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withStyles(styles)(MyProfile);
