import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { PlayCircleFilled } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import VideoPlayerDialog from '~/components/VideoPlayerDialog';
import { PersonContext } from '~/contexts/PersonContext';
import { useSegmentTrack } from '~/functions/SegmentFunctions';

const CARD_KEYS = {
  WELCOME: 'welcome',
  PARTNER_CONNECTED: 'partnerConnected',
};

export default function Welcome() {
  const [cardKey, setCardKey] = useState(undefined);
  const { partnerName, setContextState } = useContext(PersonContext);

  useEffect(() => {
    setCardKey(partnerName ? CARD_KEYS.PARTNER_CONNECTED : CARD_KEYS.WELCOME);
  }, [partnerName]);

  const skipWelcome = useCallback(() => {
    setContextState({ showWelcome: false });
  }, [setContextState]);

  const showWelcomeCard = useCallback(() => setCardKey(CARD_KEYS.WELCOME), [setCardKey]);

  if (cardKey === CARD_KEYS.PARTNER_CONNECTED) {
    return <PartnerConnectedCard showWelcomeCard={showWelcomeCard} skipWelcome={skipWelcome} />;
  }
  if (cardKey === CARD_KEYS.WELCOME) {
    return <WelcomeCard skipWelcome={skipWelcome} />;
  }

  return null;
}

const SEGMENT_LOCATION = 'Onboarding: Get Started Intro';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    maxWidth: '600px',
    padding: theme.space.lg,
  },
  heroImageContainer: {
    position: 'relative',
    marginBottom: theme.space.md,
  },
  heroImage: {
    maxWidth: '248px',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
    },
  },
  playButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    color: 'white',
    transform: 'translate(-50%, -50%)',
  },
  title: {
    marginBottom: theme.space.md,
    textAlign: 'center',
  },
  subtitle: {
    marginBottom: theme.space.lg,
    textAlign: 'center',
  },
  getStartedButton: {
    color: theme.palette.primary.contrast,
    width: '100%',
    fontWeight: 400,

    [theme.breakpoints.up('md')]: {
      width: '400px',
    },
  },
}));

function WelcomeCard({ skipWelcome }) {
  const [isVideoDialogOpen, setIsVideoDialogOpen] = useState(false);

  const { adminLogin } = useContext(PersonContext);

  const tracking = useSegmentTrack();

  function openVideoDialog() {
    tracking(
      'watch_overview_video clicked',
      { location: 'Onboarding: Get Started Intro' },
    );
    setIsVideoDialogOpen(true);
  }

  const history = useHistory();

  function handleGetStarted() {
    tracking(
      'get_started clicked',
      { location: SEGMENT_LOCATION },
    );
    history.push('/describes-you');
  }

  const classes = useStyles();

  return (
    <>
      <Card className={classes.card}>
        <Box className={classes.heroImageContainer}>
          <img className={classes.heroImage} src="./rei_hub_welcome_intro_hero.png" alt="Welcome" />
          <Fab
            color="secondary"
            onClick={() => openVideoDialog()}
            className={classes.playButton}
          >
            <PlayCircleFilled />
          </Fab>
        </Box>

        <Typography variant="h6" className={classes.title}>
          Let&apos;s personalize your bookkeeping experience
        </Typography>
        <Typography variant="body1" className={classes.subtitle}>
          We&apos;ll ask a few questions to set up your books based on your real estate situation.
        </Typography>

        <Button
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={() => handleGetStarted()}
          className={classes.getStartedButton}
        >
          Get Started
        </Button>
        {adminLogin && (
          <Button
            color="primary"
            onClick={() => skipWelcome()}
          >
            Close (Admin)
          </Button>
        )}
      </Card>

      <VideoPlayerDialog
        allowFullScreen={false}
        open={isVideoDialogOpen}
        source="https://www.youtube.com/embed/NsSJQXfe46g?rel=0&amp;wmode=opaque"
        onClose={() => {
          setIsVideoDialogOpen(false);
        }}
      />
    </>
  );
}

WelcomeCard.propTypes = {
  skipWelcome: PropTypes.func.isRequired,
};

function PartnerConnectedCard({ showWelcomeCard, skipWelcome }) {
  const { adminLogin, partnerName } = useContext(PersonContext);

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Box mx="auto" textAlign="center" mb={2}>
        <Typography variant="caption" gutterBottom>WELCOME TO REI HUB!</Typography>
        <Typography variant="h6" gutterBottom>
          {`We successfully imported your data from ${partnerName}`}
        </Typography>
      </Box>

      <Typography variant="body1" className={classes.subtitle}>
        {`REI Hub works with ${partnerName} to simplify your bookkeeping. We've started by
        setting up the properties in your portfolio from your ${partnerName} account. We've
        also imported your existing rent collections. We'll continue importing new data as
        it becomes available to help you keep your books.`}
      </Typography>

      <Button
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        onClick={() => showWelcomeCard()}
        className={classes.getStartedButton}
      >
        Next
      </Button>
      {adminLogin && (
        <Button
          color="primary"
          onClick={() => skipWelcome()}
        >
          Close (Admin)
        </Button>
      )}
    </Card>
  );
}

PartnerConnectedCard.propTypes = {
  showWelcomeCard: PropTypes.func.isRequired,
  skipWelcome: PropTypes.func.isRequired,
};
