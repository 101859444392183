const discountPercent = 50;

const planOptions = [
  {
    name: 'Essentials',
    price: 15.00,
    discountPrice: 15.00 * (1.00 - discountPercent / 100.00),
    units: 'Up to 3',
    unitsIncluded: '3',
    maxUnits: 3,
    billing: 'Billed monthly',
    id: 'essential',
  },
  {
    name: 'Growth',
    price: 25.00,
    discountPrice: 25.00 * (1.00 - discountPercent / 100.00),
    units: 'Up to 10',
    unitsIncluded: '10',
    maxUnits: 10,
    billing: 'Billed monthly',
    id: 'growth',
  },
  {
    name: 'Investor',
    price: 45.00,
    discountPrice: 45.00 * (1.00 - discountPercent / 100.00),
    units: 'Up to 20',
    unitsIncluded: '20',
    maxUnits: 20,
    billing: 'Billed monthly',
    id: 'investor',
  },
  {
    name: 'Professional',
    price: 80.00,
    discountPrice: 80.00 * (1.00 - discountPercent / 100.00),
    units: 'Unlimited',
    unitsIncluded: 'unlimited',
    billing: 'Billed monthly',
    id: 'professional',
  },
];

export default planOptions;
