import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { withStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import {
  create, find, patch,
} from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleKeyboardDatePickerChange,
  handleRadioGroupChange,
  handleTextFieldChange,
  handleAutocompleteChange,
  handleChange,
} from '../functions/InputHandlers';

import {
  institutionNameLabel,
  getPropertyOptions,
  getPlaidItems,
} from './Autocomplete/Library';

import PlaidLinkAdd from './Plaid/PlaidLinkAdd';
import PlaidLinkAddExisting from './Plaid/PlaidLinkAddExisting';
import YodleeLinkAdd from './Yodlee/YodleeLinkAdd';
import UploadTransactionsDialog from './UploadTransactionsDialog';
import TransactionScope from './TransactionScope';
import { setJournalScope } from '../functions/JournalFunctions';
import PurpleButton from './StyledComponents/PurpleButton';

const styles = () => ({
  onboardingCard: {
    width: '100%',
    maxWidth: '600px',
  },
});

class AddBankingAccountDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  // eslint-disable-next-line react/sort-comp
  setStateIfLoading = async () => {
    const { organizationName, showWelcome } = this.context;
    const { accountType } = this.props;
    const { loading } = this.state;
    if (!loading) {
      return null;
    }

    const { organizationId, bookkeepingStartDate, demo } = this.context;
    const importStartDate = bookkeepingStartDate < moment().format('YYYY-MM-DD') ? bookkeepingStartDate : moment().format('YYYY-MM-DD');

    const initialState = {
      submitting: false,
      key: 'bankingAccountsOverview',
      uploadTransactionsDialog: false,
      plaidItem: null,
      yodleeProvider: null,
      settings: {
        importStartDate: showWelcome ? importStartDate : null,
        createType: 'linked',
        dataProvider: 'plaid',
        plaidConnectionType: 'existing',
      },
      account: {
        organizationId,
        name: '',
        type2: 'Bank',
        institutionName: '',
        accountNumberMask: '',
        journal: {
          enitityId: null,
          propertyId: null,
          unitId: null,
          journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
        },
      },
      error: null,
      loading: false,
    };

    if (accountType === 'linked') {
      initialState.key = 'importStartDate';
    } else if (accountType === 'manual') {
      initialState.key = 'manualAccountType';
      initialState.settings.createType = 'manual';
    }

    if (demo) {
      initialState.key = 'demo';
    }

    initialState.propertyOptions = await getPropertyOptions(this);
    initialState.plaidItemOptions = await getPlaidItems(this);

    this.setState(initialState);
    return null;
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    this.setState({ loading: true, uploadTransactionsDialog: false });
    closeDialog();
  };

  processCreateType = () => {
    const { settings } = this.state;

    if (settings.createType === 'linked') {
      this.setState({ key: 'importStartDate' });
    } else {
      this.setState({ key: 'manualAccountType' });
    }
  };

  processImportStartDate = () => {
    this.setState({ key: 'dataProvider' });
  };

  processDataProvider = () => {
    const { settings, plaidItemOptions } = this.state;

    if (settings.dataProvider === 'plaid' && plaidItemOptions.length === 0) {
      this.setState({ key: 'plaidLinkNew' });
    } else if (settings.dataProvider === 'plaid') {
      this.setState({ key: 'plaidConnection' });
    } else if (settings.dataProvider === 'yodlee') {
      this.setState({ key: 'yodleeLink' });
    }
  };

  processPlaidConnection = (event) => {
    event.preventDefault();
    const { settings } = this.state;

    if (settings.plaidConnectionType === 'new') {
      this.setState({ key: 'plaidLinkNew' });
    } else {
      if (!settings.plaidItemIdSelect) {
        return;
      }
      this.setState({ key: 'plaidLinkExisting' });
    }
  };

  onPlaidSuccess = (plaidItem) => {
    const { organizationName } = this.context;
    const { onAddAccount } = this.props;
    onAddAccount();
    const addedAccounts = [];
    plaidItem.accounts.forEach((account) => {
      addedAccounts.push({
        plaidAccountId: account.id,
        mask: account.mask,
        name: account.name,
        journal: {
          enitityId: null,
          propertyId: null,
          unitId: null,
          journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
        },
      });
    });
    this.setState({ plaidItem, key: 'plaidSuccess', addedAccounts });
  };

  onPlaidClose = () => {
    this.setState({ key: 'dataProvider' });
  };

  onYodleeSuccess = async (yodleeProvider) => {
    const { organizationName, organizationId } = this.context;
    const { onAddAccount } = this.props;
    onAddAccount();

    const { providerAccountId } = yodleeProvider;
    const query = {
      organizationId,
      providerAccountId,
    };
    const yodleeAccounts = await find(this, 'yodlee-accounts', { query });

    const addedAccounts = [];
    yodleeAccounts.data.forEach((account) => {
      addedAccounts.push({
        yodleeAccountId: account.accountId,
        mask: account.mask,
        name: account.name,
        journal: {
          enitityId: null,
          propertyId: null,
          unitId: null,
          journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
        },
      });
    });
    this.setState({ yodleeProvider, key: 'yodleeSuccess', addedAccounts });
  };

  onYodleeClose = () => {
    this.setState({ key: 'dataProvider' });
  };

  processManualAccountType = () => {
    const { account } = this.state;

    if (account.type2 === 'Credit Card') {
      handleChange('nested_account_type', 'Liability', this);
    } else {
      handleChange('nested_account_type', 'Asset', this);
    }

    this.setState({ key: 'manualAccountName' });
  };

  saveManualAccount = async (event) => {
    event.preventDefault();
    const {
      submitting, account,
    } = this.state;
    const { onAddAccount } = this.props;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    const accountSubmit = { ...account };

    setJournalScope(account.journal);
    accountSubmit.propertyId = account.journal.propertyId;
    accountSubmit.entityId = account.journal.entityId;
    accountSubmit.unitId = account.journal.unitId;

    create(this, 'accounts', accountSubmit, true)
      .then((newAccount) => {
        onAddAccount();
        this.setState({ account: newAccount, key: 'manualAccountUploadTransactions' });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  handleSaveAndClose = async () => {
    const { onAddAccount, allAccounts } = this.props;
    const { plaidItem, yodleeProvider, addedAccounts } = this.state;

    const patchPromises = [];

    let account;

    addedAccounts.forEach((accountItem) => {
      if (plaidItem) {
        account = allAccounts.find((item) => item.plaidAccountId === accountItem.plaidAccountId);
      }
      if (yodleeProvider) {
        account = allAccounts.find(
          (item) => item.yodlee_account.accountData.id === accountItem.yodleeAccountId,
        );
      }

      if (account) {
        setJournalScope(accountItem.journal);
        const data = {
          name: accountItem.name,
          propertyId: accountItem.journal.propertyId,
          entityId: accountItem.journal.entityId,
          unitId: accountItem.journal.unitId,
        };
        patchPromises.push(patch(this, 'accounts', account.id, data));
      }
    });

    await Promise.all(patchPromises)
      .then(() => {
        onAddAccount();
        this.closeDialog();
      })
      .catch((error) => {
        this.setState({ error });
      });
  };

  handleScopeChange = (target, values, journal) => {
    const newData = {
      ...journal,
      ...values,
    };

    handleChange(target, newData, this);
  };

  getDialogContent = (key) => {
    const {
      settings,
      plaidItem,
      yodleeProvider,
      account,
      plaidItemOptions,
      error,
      addedAccounts,
    } = this.state;

    switch (key) {
      case 'bankingAccountsOverview':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                How would you like to setup the account?
              </Typography>
            </Box>
            <FormControl component="fieldset">
              <RadioGroup aria-label="basis" name="nested_settings_createType" value={settings.createType} onChange={handleRadioGroupChange(this)}>
                <FormControlLabel value="linked" control={<Radio required />} label="Import a Linked Account (recommended)" />
                <FormControlLabel value="manual" control={<Radio required />} label="Create a Manual Account" />
              </RadioGroup>
            </FormControl>
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                {`Linking accounts reduces manual data entry and helps you keep better books.
                Recent financial transactions are securely and automatically added to the
                import feed for quick and easy booking. You can set up rules to automatically
                process recurring transactions. We recommend creating accounts that you use
                exclusively for business purposes as linked accounts.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.processCreateType}
              >
                Next
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'importStartDate':
        return (
          <DialogContent>
            <form onSubmit={this.processImportStartDate}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  From what date would you like to import transactions?
                </Typography>
              </Box>
              <KeyboardDatePicker
                label="Transaction Import Start Date"
                format="MM/DD/YYYY"
                placeholder="MM/DD/YYYY"
                value={settings.importStartDate}
                disableFuture
                maxDateMessage="The import start date cannot be in the future"
                onChange={handleKeyboardDatePickerChange('nested_settings_importStartDate', this)}
                margin="dense"
                fullWidth
                clearable
                required
              />
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2">
                  {`The available length of transaction history varies by financial institution.
                We'll import as much as possible beginning on the specified date.`}
                </Typography>
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <PurpleButton
                  variant="contained"
                >
                  Next
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'dataProvider':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Which data provider would you like to use to import and link the account?
              </Typography>
            </Box>
            <FormControl component="fieldset">
              <RadioGroup aria-label="basis" name="nested_settings_dataProvider" value={settings.dataProvider} onChange={handleRadioGroupChange(this)}>
                <FormControlLabel value="plaid" control={<Radio required />} label="Plaid (recommended)" />
                <FormControlLabel value="yodlee" control={<Radio required />} label="Yodlee" />
              </RadioGroup>
            </FormControl>
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                You will be prompted by the data provider to securely log in to your
                financial institution and select the desired accounts to import and link.
                <br />
                <br />
                REI Hub does not directly connect to your bank and can only automatically import
                transaction information that is available to Plaid or Yodlee.
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.processDataProvider}
              >
                Start Import
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'plaidConnection':
        return (
          <DialogContent>
            <form onSubmit={this.processPlaidConnection}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Are you adding accounts to an existing Plaid connection?
                </Typography>
              </Box>
              <FormControl component="fieldset">
                <RadioGroup aria-label="basis" name="nested_settings_plaidConnectionType" value={settings.plaidConnectionType} onChange={handleRadioGroupChange(this)}>
                  <FormControlLabel value="existing" control={<Radio required />} label="Add additional accounts through an existing connection" />
                  <FormControlLabel value="new" control={<Radio required />} label="Add accounts from a new institution or connection" />
                </RadioGroup>
              </FormControl>
              {settings.plaidConnectionType === 'existing' && (
              <Autocomplete
                options={plaidItemOptions}
                getOptionLabel={institutionNameLabel}
                value={settings.plaidItemIdSelect}
                onChange={handleAutocompleteChange(
                  'nested_settings_plaidItemIdSelect',
                  this,
                )}
                getOptionSelected={(option, value) => option.plaidItemId === value.plaidItemId}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    margin="dense"
                    label="Plaid Connection"
                    placeholder="Type to Search"
                    fullWidth
                    required
                  />
                )}
              />
              )}
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                {settings.plaidConnectionType === 'new' && (
                <Typography variant="body2">
                  {`You'll be redirected to Plaid to securely log in to your financial institution
                      and select the desired accounts to import and link.`}
                </Typography>
                )}
                {settings.plaidConnectionType === 'existing' && (
                <Typography variant="body2">
                  {`You'll be redirected to Plaid to securely log in to your financial institution
                      and select the desired accounts. `}
                  <strong>
                    {'Please select '}
                    <i>ALL ACCOUNTS</i>
                    {` that should be linked through this connection,
                          including existing linked accounts.`}
                  </strong>
                </Typography>
                )}
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <PurpleButton
                  variant="contained"
                >
                  Start Import
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'plaidSuccess':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Success
              </Typography>
            </Box>
            <Typography variant="body1">
              {`Plaid successfully connected to ${plaidItem.institutionName} and is retrieving
                transactions from the following accounts. This may take several minutes. You can
                continue working while this process completes. When available, transactions will
                automatically populate to the import feed.`}
            </Typography>
            {addedAccounts.map((item, index) => (
              <Box key={item.plaidAccountId} mb={2}>
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="h6" gutterBottom>
                    {`xxxx${item.mask}`}
                  </Typography>
                  <TextField
                    fullWidth
                    label="Account Name"
                    InputProps={{
                      value: item.name,
                      name: `nested_addedAccounts_${index}_name`,
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                  <TransactionScope
                    journal={addedAccounts[index].journal}
                    transactionScopeChange={(values) => {
                      this.handleScopeChange(`nested_addedAccounts_${index}_journal`, values, addedAccounts[index].journal);
                    }}
                    label="Account"
                  />
                </Box>
              </Box>
            ))}
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.handleSaveAndClose}
              >
                Save and Close
              </PurpleButton>
            </Box>
          </DialogContent>
        );
      case 'yodleeSuccess':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Success
              </Typography>
            </Box>
            <Typography variant="body1">
              {`Yodlee successfully connected to ${yodleeProvider.providerName} and is retrieving
                transactions from the following accounts. This may take several minutes. You can
                continue working while this process completes. When available, transactions will
                automatically populate to the import feed.`}
            </Typography>
            {addedAccounts.map((item, index) => (
              <Box key={item.yodleeAccountId} mb={2}>
                <Box
                  border={1}
                  borderColor="grey.500"
                  borderRadius="borderRadius"
                  padding={2}
                  marginY={2}
                >
                  <Typography variant="h6" gutterBottom>
                    {`xxxx${item.mask}`}
                  </Typography>
                  <TextField
                    fullWidth
                    label="Account Name"
                    InputProps={{
                      value: item.name,
                      name: `nested_addedAccounts_${index}_name`,
                      onChange: handleTextFieldChange(this),
                    }}
                  />
                  <TransactionScope
                    journal={addedAccounts[index].journal}
                    transactionScopeChange={(values) => {
                      this.handleScopeChange(`nested_addedAccounts_${index}_journal`, values, addedAccounts[index].journal);
                    }}
                    label="Account"
                  />
                </Box>
              </Box>
            ))}
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.handleSaveAndClose}
              >
                Save and Close
              </PurpleButton>
            </Box>
          </DialogContent>
        );
      case 'demo':
        return (
          <>
            <DialogContent>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Demo
                </Typography>
              </Box>
              <Typography variant="body1">
                You are not allowed to save changes in the demo portfolio.
              </Typography>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Button
                  onClick={this.closeDialog}
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                >
                  Close
                </Button>
              </Box>
            </DialogContent>
          </>
        );
      case 'manualAccountType':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Which type of account would you like to create?
              </Typography>
            </Box>
            <FormControl component="fieldset">
              <RadioGroup aria-label="basis" name="nested_account_type2" value={account.type2} onChange={handleRadioGroupChange(this)}>
                <FormControlLabel value="Bank" control={<Radio required />} label="Bank Account" />
                <FormControlLabel value="Credit Card" control={<Radio required />} label="Credit Card" />
              </RadioGroup>
            </FormControl>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.processManualAccountType}
              >
                Next
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'manualAccountName':
        return (
          <DialogContent>
            <form onSubmit={this.saveManualAccount}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Please enter the account details.
                </Typography>
              </Box>
              <TextField
                label="Account Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: account.name,
                  name: 'nested_account_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TransactionScope
                journal={account.journal}
                transactionScopeChange={(values) => {
                  this.handleScopeChange('nested_account_journal', values, account.journal);
                }}
                label="Account"
              />
              <TextField
                label="Financial Institution Name (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: account.institutionName,
                  name: 'nested_account_institutionName',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <TextField
                label="Last Four Digits of Account Number (optional)"
                fullWidth
                margin="dense"
                InputProps={{
                  value: account.accountNumberMask,
                  name: 'nested_account_accountNumberMask',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Typography color="error">{error && error.message}</Typography>
                <PurpleButton
                  variant="contained"
                >
                  Save
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'manualAccountUploadTransactions':
        return (
          <DialogContent>
            <Typography variant="caption" gutterBottom>
              {account.name}
            </Typography>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Your account has been saved. Would you like to upload a transaction file?
              </Typography>
            </Box>
            <Box
              border={1}
              borderColor="grey.500"
              borderRadius="borderRadius"
              padding={2}
              marginY={2}
            >
              <Typography variant="body2">
                {`Uploaded transactions are added to the import feed of the account
                    for quick and accurate bookkeeping with less manual data entry.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={this.setState({ uploadTransactionsDialog: true })}
              >
                Upload Transactions
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Skip For Now
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  onEnterCard = async () => {
    await this.setStateIfLoading();
  };

  render() {
    const { isOpen, onAddAccount, classes } = this.props;
    const { showWelcome } = this.context;
    const {
      loading, key, settings, account, uploadTransactionsDialog,
    } = this.state;

    if (loading && showWelcome) {
      this.onEnterCard();
    }

    if (key === 'plaidLinkNew') {
      return (
        <PlaidLinkAdd
          onSuccess={this.onPlaidSuccess}
          closeDialog={this.onPlaidClose}
          importStartDate={settings.importStartDate}
          banking
          creditCards
        />
      );
    }

    if (key === 'plaidLinkExisting') {
      return (
        <PlaidLinkAddExisting
          onSuccess={this.onPlaidSuccess}
          closeDialog={this.onPlaidClose}
          importStartDate={settings.importStartDate}
          plaidItemId={settings.plaidItemIdSelect.plaidItemId}
          banking
          creditCards
        />
      );
    }

    if (key === 'yodleeLink') {
      return (
        <YodleeLinkAdd
          onSuccess={this.onYodleeSuccess}
          closeDialog={this.onYodleeClose}
          importStartDate={settings.importStartDate}
        />
      );
    }

    if (uploadTransactionsDialog) {
      return (
        <UploadTransactionsDialog
          closeDialog={this.closeDialog}
          onUploadTransactions={onAddAccount}
          accountId={account.id}
        />
      );
    }

    return (
      <>
        {!showWelcome && (
          <Dialog
            open={isOpen}
            scroll="body"
            maxWidth="sm"
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
            onEnter={this.setStateIfLoading}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            {!loading && this.getDialogContent(key)}
          </Dialog>
        )}
        {showWelcome && (
          <Card
            open={isOpen}
            className={classes.onboardingCard}
          >
            {!loading && this.getDialogContent(key)}
          </Card>
        )}
      </>
    );
  }
}

AddBankingAccountDialog.contextType = PersonContext;

AddBankingAccountDialog.defaultProps = {
  accountType: null,
};

AddBankingAccountDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddAccount: PropTypes.func.isRequired,
  allAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
  accountType: PropTypes.node,
};

export default withStyles(styles)(AddBankingAccountDialog);
