import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  Card, Fab, makeStyles, TextField,
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { PlayCircleFilled } from '@material-ui/icons';
import history from '../history';
import AddBankingAccountDialog from './AddBankingAccountDialog';
import AddLoanDialog from './AddLoanDialog';

import { find, patch } from '../feathersWrapper';

import { PersonContext } from '../contexts/PersonContext';
import { useSegmentTrack } from '../functions/SegmentFunctions';
import VideoPlayerDialog from './VideoPlayerDialog';
import PurpleButton from './StyledComponents/PurpleButton';
import PlaidLinkAdd from './Plaid/PlaidLinkAdd';
import YodleeLinkAdd from './Yodlee/YodleeLinkAdd';
import SkipForNowAccountsDialog from './SkipForNowAccountsDialog';
import TransactionScope from './TransactionScope';
import { setJournalScope } from '../functions/JournalFunctions';

const useStyles = makeStyles((theme) => ({
  root: { paddingTop: '55px' },
  closeIcon: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    color: 'white',
  },
  successIcon: {
    height: '64px',
    width: '64px',
    color: 'green',
  },
  heroImage: {
    maxWidth: '300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '248px',
    },
  },
  onboardingCard: {
    maxWidth: '600px',
    width: '100%',
  },
  setupCompleteImage: {
    height: 'auto',
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '300px',
  },
}));

export default function ViewWelcomeAccountsDialog({
  isOpen,
  onAddAccount,
  allAccounts,
}) {
  const classes = useStyles();
  const context = useContext(PersonContext);
  const {
    organizationId,
    organizationName,
    bookkeepingStartDate,
    setOrganizationId,
    setIsTourVisible,
    partnerName,
    setIsScheduleCallDialogVisible,
  } = context;
  const [loading, setLoading] = useState(true);
  const [addAccountDialog, setAddAccountDialog] = useState(false);

  const [plaidAddNew, setPlaidAddNew] = useState(false);
  const [yodleeAddLink, setYodleeAddLink] = useState(false);

  const [addLoanAccountDialog, setAddLoanAccountDialog] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [skipForNowDialogVisible, setSkipForNowDialogVisible] = useState(false);
  const [plaidExitDialogVisible, setPlaidExitDialogVisible] = useState(false);
  const [state, setState] = useState({
    key: 'accountsOverview',
  });
  const tracking = useSegmentTrack();

  useEffect(() => {
    async function getData() {
      const bankingQuery = {
        organizationId,
        type2: ['Bank', 'Credit Card'],
        default: null,
      };
      const bankingAccounts = await find(this, 'accounts', { query: bankingQuery });
      const accountCount = bankingAccounts.total;
      if (window.location.pathname === '/onboarding-complete') {
        setState({
          key: 'setupComplete',
        });
      } else if (accountCount === 0) {
        setState({
          key: 'accountsOverview',
          accountCount,
        });
      } else {
        setState({
          key: 'addAccountsSuccess',
          accountCount,
        });
      }
      setLoading(false);
    }
    getData();
  }, [organizationId]);

  const addAccount = async () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'onboardingAddAccount',
      organizationId,
    });

    await onAddAccount();

    const query = {
      organizationId,
      type2: ['Bank', 'Credit Card'],
    };

    let key;
    if (state.plaidItem) {
      key = 'plaidSuccess';
    } else if (state.yodleeProvider) {
      key = 'yodleeSuccess';
    } else {
      key = 'addAccountsSuccess';
    }

    const accounts = await find(this, 'accounts', { query });
    setState({
      ...state,
      key,
      accountCount: accounts.total,
    });
  };

  const handleSaveAndClose = async (event) => {
    event.preventDefault();
    const patchPromises = [];

    let account;

    state.addedAccounts.forEach((accountItem) => {
      if (state.plaidItem) {
        account = allAccounts.find((item) => item.plaidAccountId === accountItem.plaidAccountId);
      }
      if (state.yodleeProvider) {
        account = allAccounts.find(
          (item) => item.yodlee_account.accountData.id === accountItem.yodleeAccountId,
        );
      }

      if (account) {
        setJournalScope(accountItem.journal);
        const data = {
          name: accountItem.name,
          propertyId: accountItem.journal.propertyId,
          entityId: accountItem.journal.entityId,
          unitId: accountItem.journal.unitId,
        };
        patchPromises.push(patch(this, 'accounts', account.id, data));
      }
    });

    const results = await Promise.allSettled(patchPromises);
    let error;
    results.forEach((result) => {
      if (result.status === 'rejected') {
        error = result.reason;
      }
    });

    if (error) {
      setState({ ...state, error });
    } else {
      onAddAccount();
      setState({
        ...state,
        error: null,
        plaidItem: null,
        yodleeProvider: null,
        key: 'addAccountsSuccess',
      });
    }
  };

  const addLoanAccount = async () => {
    const query = {
      organizationId,
      type2: ['Mortgage', 'HELOC', 'Hard Money Loan', 'Loan'],
    };
    const loanAccounts = await find(this, 'accounts', { query });

    setState({
      ...state,
      loanAccountCount: loanAccounts.total,
      key: 'addAnotherLoanAccount',
    });
  };

  const finishOnboarding = async () => {
    await patch(this, 'organizations', organizationId, {
      showWelcome: false,
    });
    await setOrganizationId(organizationId);

    setIsTourVisible(true);
    setIsScheduleCallDialogVisible(true);

    history.push('/properties');
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const getDialogContent = (key) => {
    switch (key) {
      case 'accountsOverview':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Box style={{ position: 'relative' }} mb={1.5}>
                <img className={classes.heroImage} src="/rei_hub_accounts_intro_hero.png" alt="Accounts" />
                <Fab
                  color="secondary"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                  }}
                  onClick={() => {
                    tracking(
                      'watch_accounts_overview clicked',
                      { location: 'Onboarding - Accounts Intro' },
                    );
                    setVideoDialogOpen(true);
                  }}
                >
                  <PlayCircleFilled color="white" fontSize="24px" />
                </Fab>
              </Box>
              <Box mb={3}>
                <Box mb={2} textAlign="center">
                  <Typography variant="h6">
                    Save time and energy by linking your accounts
                  </Typography>
                </Box>
                <Box mb={1} textAlign="center">
                  <Typography variant="body1">
                    {`Financial transactions are securely and automatically added for easy and accurate
                    categorization, eliminating manual data entry. `}
                    <Box component="span" fontWeight="bold">
                      Over 80% of REI Hub customers link
                      at least one account.
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
            {partnerName && (
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginTop={3}
                textAlign="left"
              >
                <Typography variant="body2">
                  <li>
                    {`Linked accounts in REI Hub require separate authorization from deposit accounts
                  connected through ${partnerName}.`}
                  </li>
                  <li>
                    {`We recommend creating all accounts receiveing deposits from ${partnerName} as
                  linked accounts in REI Hub.`}
                  </li>
                </Typography>
              </Box>
            )}
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={3} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'link_account clicked',
                    { location: 'Onboarding - Accounts: Intro' },
                  );
                  setPlaidAddNew(true);
                }}
              >
                Link Account
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    tracking(
                      'import_manually clicked',
                      { location: 'Onboarding - Accounts Intro' },
                    );
                    setState({ ...state, accountType: 'manual' });
                    setAddAccountDialog(true);
                  }}
                >
                  Add Manually
                </Button>
              </Box>
              <Box mt={1}>
                <PurpleButton
                  variant="text"
                  onClick={() => {
                    tracking(
                      'skip_for_now clicked',
                      { location: 'Onboarding - Accounts Intro' },
                    );
                    setSkipForNowDialogVisible(true);
                  }}
                >
                  Skip For Now
                </PurpleButton>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'addManualAccounts':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Would you like to add any manual bank or credit card accounts?
              </Typography>
            </Box>
            <Box mb={1}>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2">
                  {`Use the import feed with manual accounts by uploading a transaction file from
                  your financial institution. Transactions can also be created individually.`}
                </Typography>
              </Box>
            </Box>
            <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'add_manual_account clicked',
                    { location: 'Onboarding - Manual Accounts: Intro' },
                  );
                  setAddAccountDialog(true);
                }}
              >
                Add A Manual Account
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    tracking(
                      'no_manual_accounts clicked',
                      { location: 'Onboarding - Manual Accounts: Intro' },
                    );
                    setState({ ...state, key: 'addLoanAccounts' });
                  }}
                >
                  No Manual Accounts
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'plaidSuccess':
        return (
          <DialogContent>
            <form onSubmit={handleSaveAndClose}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Success
                </Typography>
              </Box>
              <Typography variant="body1">
                {`Plaid successfully connected to ${state.plaidItem.institutionName} and is retrieving
                transactions from the following accounts. This may take several minutes. You can
                continue working while this process completes. When available, transactions will
                automatically populate to the import feed.`}
              </Typography>
              {state.addedAccounts.map((item, index) => (
                <Box key={item.plaidAccountId} mb={2}>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="h6" gutterBottom>
                      {`xxxx${item.mask}`}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Account Name"
                      InputProps={{
                        value: item.name,
                        name: `nested_addedAccounts_${index}_name`,
                        onChange: (event, value) => {
                          const newAddedAccounts = state.addedAccounts;
                          newAddedAccounts[index].name = value;
                          setState({ ...state, addedAccounts: newAddedAccounts });
                        },
                      }}
                    />
                    <TransactionScope
                      journal={state.addedAccounts[index].journal}
                      transactionScopeChange={(values) => {
                        const newAddedAccounts = state.addedAccounts;
                        const newJournal = {
                          ...newAddedAccounts[index].journal,
                          ...values,
                        };
                        newAddedAccounts[index].journal = newJournal;
                        setState({ ...state, addedAccounts: newAddedAccounts });
                      }}
                      label="Account"
                    />
                  </Box>
                </Box>
              ))}
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                {!state.error && (
                <Box marginBottom={2}>
                  <Typography variant="body1" color="error">
                    {state.error}
                  </Typography>
                </Box>
                )}
                <PurpleButton
                  variant="contained"
                >
                  {state.error ? 'Retry' : 'Save and Close'}
                </PurpleButton>
              </Box>
            </form>
          </DialogContent>
        );
      case 'yodleeSuccess':
        return (
          <DialogContent>
            <form onSubmit={handleSaveAndClose}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Success
                </Typography>
              </Box>
              <Typography variant="body1">
                {`Yodlee successfully connected to ${state.yodleeProvider.providerName} and is retrieving
                transactions from the following accounts. This may take several minutes. You can
                continue working while this process completes. When available, transactions will
                automatically populate to the import feed.`}
              </Typography>
              {state.addedAccounts.map((item, index) => (
                <Box key={item.yodleeAccountId} mb={2}>
                  <Box
                    border={1}
                    borderColor="grey.500"
                    borderRadius="borderRadius"
                    padding={2}
                    marginY={2}
                  >
                    <Typography variant="h6" gutterBottom>
                      {`xxxx${item.mask}`}
                    </Typography>
                    <TextField
                      fullWidth
                      label="Account Name"
                      InputProps={{
                        value: item.name,
                        name: `nested_addedAccounts_${index}_name`,
                        onChange: (event, value) => {
                          const newAddedAccounts = state.addedAccounts;
                          newAddedAccounts[index].name = value;
                          setState({ ...state, addedAccounts: newAddedAccounts });
                        },
                      }}
                    />
                    <TransactionScope
                      journal={state.addedAccounts[index].journal}
                      transactionScopeChange={(values) => {
                        const newAddedAccounts = state.addedAccounts;
                        newAddedAccounts[index].journal = values;
                        setState({ ...state, addedAccounts: newAddedAccounts });
                      }}
                      label="Account"
                    />
                  </Box>
                </Box>
              ))}
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                {!state.error && (
                <Box marginBottom={2}>
                  <Typography variant="body1" color="error">
                    {state.error}
                  </Typography>
                </Box>
                )}
                <PurpleButton
                  variant="contained"
                >
                  {state.error ? 'Retry' : 'Save and Close'}
                </PurpleButton>
              </Box>
            </form>
          </DialogContent>
        );
      case 'addAccountsSuccess':
        return (
          <DialogContent>
            <Box
              mx="auto"
              textAlign="center"
              marginBottom={2}
              marginTop={2}
            >
              <CheckCircle className={classes.successIcon} />
              <Box>
                <Typography variant="h6" gutterBottom>
                  {`You've added ${state.accountCount} ${pluralize('account', state.accountCount)}
                  to your portfolio.`}
                </Typography>
              </Box>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`You can now categorize your transactions by property as they are imported. If you
                linked an account, we pulled in everything possible since
                ${moment(bookkeepingStartDate).format('M/D/YYYY')}, but how far back we can go
                depends on the financial institution.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'im_done clicked',
                    { location: 'Onboarding - Accounts: Success' },
                  );
                  setState({ ...state, key: 'addLoanAccounts' });
                }}
              >
                {'I\'m Done'}
              </PurpleButton>

              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  fullWidth
                  size="large"
                  onClick={() => {
                    tracking(
                      'link_new_account clicked',
                      { location: 'Onboarding - Accounts: Success' },
                    );
                    setState({ ...state, accountType: 'linked' });
                    // setAddAccountDialog(true);
                    setPlaidAddNew(true);
                  }}
                >
                  Link New Account
                </Button>
              </Box>

              <Box mt={1}>
                <PurpleButton
                  variant="text"
                  onClick={() => {
                    tracking(
                      'import_manually clicked',
                      { location: 'Onboarding - Accounts: Success' },
                    );
                    setState({ ...state, accountType: 'manual' });
                    setAddAccountDialog(true);
                  }}
                >
                  Add Manually
                </PurpleButton>
              </Box>

            </Box>
          </DialogContent>
        );
      case 'addLoanAccounts':
        return (
          <DialogContent>
            <Box mx="auto" mb={2}>
              <Typography variant="h6" gutterBottom>
                Would you like to add any loan accounts?
              </Typography>
            </Box>
            <Box mb={1}>
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2">
                  {`You'll use loan accounts to track the financing and debt payments for your
                  portfolio.`}
                </Typography>
              </Box>
            </Box>
            <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'add_loan_account clicked',
                    { location: 'Onboarding - Loans: Intro' },
                  );
                  setAddLoanAccountDialog(true);
                }}
              >
                Add A Loan Account
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    tracking(
                      'no_loan_account clicked',
                      { location: 'Onboarding - Loans: Intro' },
                    );
                    history.replace('/onboarding-complete');
                    setState({ ...state, key: 'setupComplete' });
                  }}
                >
                  No Loan Accounts
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'addAnotherLoanAccount':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <Typography color="secondary"><AccountBalanceOutlinedIcon /></Typography>
              <Typography variant="caption" gutterBottom>
                STEP 3: ADD YOUR ACCOUNTS
              </Typography>
              <Typography variant="h6" gutterBottom>
                {`You've created ${pluralize('loan', state.loanAccountCount, true)} in your
                portfolio.`}
              </Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`You'll use loan accounts to track the financing and debt payments for your
                portfolio.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'finished clicked',
                    { location: 'Onboarding - Loans: Success' },
                  );
                  setState({ ...state, key: 'setupComplete' });
                  history.replace('/onboarding-complete');
                }}
              >
                Finished
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={() => {
                    tracking(
                      'add_loan_account clicked',
                      { location: 'Onboarding - Loans: Intro' },
                    );
                    setAddLoanAccountDialog(true);
                  }}
                >
                  Add Another Loan
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'setupComplete':
        return (
          <DialogContent>
            <Box mx="auto" textAlign="center" mb={2}>
              <img alt="A happy user on a laptop" src="/onboarding-success.webp" className={classes.setupCompleteImage} />
              <Typography variant={isSmallScreen ? 'h6' : 'h5'}>Your portfolio setup is complete!</Typography>
            </Box>
            <Box mb={1} textAlign="center">
              <Typography variant="body1">
                {`Now we'll guide you around REI Hub, the tool that'll make tracking your rental property finances
                easier than ever before.`}
              </Typography>
            </Box>
            <Box maxWidth="400px" marginX="auto" mt={3} marginBottom={2}>
              <PurpleButton
                variant="contained"
                onClick={() => {
                  tracking(
                    'sounds_good clicked',
                    { location: 'Onboarding - Complete' },
                  );
                  finishOnboarding();
                }}
              >
                Sounds good
              </PurpleButton>
            </Box>
          </DialogContent>
        );
      default:
        return null;
    }
  };

  const plaidExitDialog = () => (
    <Dialog
      open={plaidExitDialogVisible}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setPlaidExitDialogVisible(false);
        }
      }}
      onLoad={() => {
        tracking(
          'not_finding_account_modal loaded',
          { location: 'Onboarding - Linked Account: Not Finding Account Modal' },
        );
      }}
      maxWidth="sm"
      disableEscapeKeyDown
      id="plaid-exit-dialog"
      aria-describedby="plaid-exit-dialog"
      PaperProps={{
        elevation: 0,
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <>
        <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ClearIcon
            className={classes.closeIcon}
            onClick={() => {
              tracking(
                'close clicked',
                { location: 'Onboarding - Linked Account: Not Finding Account Modal' },
              );
              setPlaidExitDialogVisible(false);
              setState({
                ...state,
                key: state.accountCount === 0
                  ? 'accountsOverview'
                  : 'addAccountsSuccess',
              });
            }}
          />
        </Box>
        <Box
          style={{
            backgroundColor: 'white',
            marginRight: 25,
            marginLeft: 25,
          }}
        >
          <DialogContent>
            <Box
              marginX="auto"
              marginBottom={2}
              textAlign="left"
            >
              <Typography variant="h6" gutterBottom>
                {'Not finding the account you\'re looking for?'}
              </Typography>
              <Box marginBottom={1} textAlign="left">
                <Typography variant="body1">
                  We can try with our alternative data provider Yodlee to link to your account. Or
                  you could import the account information manually.
                </Typography>
              </Box>
              <Box
                maxWidth="400px"
                marginX="auto"
                textAlign="center"
                marginBottom={1}
                marginTop={5}
              >
                <PurpleButton
                  variant="contained"
                  onClick={() => {
                    tracking(
                      'try_linking_via_yodlee clicked',
                      { location: 'Onboarding - Linked Account: Not Finding Account Modal' },
                    );
                    setPlaidExitDialogVisible(false);
                    setYodleeAddLink(true);
                  }}
                >
                  Try Linking Via Yodlee
                </PurpleButton>
                <Box marginTop={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => {
                      tracking(
                        'import_manually clicked',
                        { location: 'Onboarding - Linked Account: Not Finding Account Modal' },
                      );
                      setState({ ...state, accountType: 'manual' });
                      setPlaidExitDialogVisible(false);
                      setAddAccountDialog(true);
                    }}
                  >
                    Add Manually
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </>
    </Dialog>
  );

  const onPlaidSuccess = (plaidItem) => {
    const addedAccounts = [];
    plaidItem.accounts.forEach((account) => {
      addedAccounts.push({
        plaidAccountId: account.id,
        mask: account.mask,
        name: account.name,
        journal: {
          enitityId: null,
          propertyId: null,
          unitId: null,
          journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
        },
      });
    });
    setPlaidAddNew(false);
    setState({
      plaidItem,
      key: 'plaidSuccess',
      addedAccounts,
      accountCount: state.accountCount + addedAccounts.length,
    });
  };

  const onYodleeSuccess = async (yodleeProvider) => {
    const { providerAccountId } = yodleeProvider;
    const query = {
      organizationId,
      providerAccountId,
    };
    const yodleeAccounts = await find(this, 'yodlee-accounts', { query });

    const addedAccounts = [];
    yodleeAccounts.data.forEach((account) => {
      addedAccounts.push({
        yodleeAccountId: account.accountId,
        mask: account.mask,
        name: account.name,
        journal: {
          enitityId: null,
          propertyId: null,
          unitId: null,
          journalScopeSelect: { name: `${organizationName} Portfolio`, id: 'Portfolio' },
        },
      });
    });
    setState({
      yodleeProvider,
      key: 'yodleeSuccess',
      addedAccounts,
      accountCount: state.accountCount + addedAccounts.length,
    });
  };

  if (loading) {
    return null;
  }

  if (addAccountDialog) {
    return (
      <AddBankingAccountDialog
        isOpen
        closeDialog={() => {
          setState({ ...state, accountType: null });
          setAddAccountDialog(false);
        }}
        onAddAccount={addAccount}
        accountType={state.accountType}
        allAccounts={allAccounts}
      />
    );
  }

  if (plaidExitDialogVisible) {
    return plaidExitDialog();
  }

  if (plaidAddNew) {
    return (
      <PlaidLinkAdd
        onSuccess={onPlaidSuccess}
        closeDialog={() => {
          setPlaidAddNew(false);
          setPlaidExitDialogVisible(true);
        }}
        importStartDate={bookkeepingStartDate}
      />
    );
  }

  if (yodleeAddLink) {
    return (
      <YodleeLinkAdd
        onSuccess={onYodleeSuccess}
        closeDialog={() => {
          setYodleeAddLink(false);
        }}
        importStartDate={bookkeepingStartDate}
      />
    );
  }

  if (addLoanAccountDialog) {
    return (
      <AddLoanDialog
        isOpen
        closeDialog={() => setAddLoanAccountDialog(false)}
        onAddAccount={addLoanAccount}
      />
    );
  }

  return (
    <>
      <SkipForNowAccountsDialog
        isOpen={skipForNowDialogVisible}
        closeDialog={() => setSkipForNowDialogVisible(false)}
        onConfirm={() => setState({ ...state, accountType: null, key: 'addLoanAccounts' })}
      />
      <VideoPlayerDialog
        open={videoDialogOpen}
        onClose={() => {
          setVideoDialogOpen(false);
        }}
        source="https://www.youtube.com/embed/914EJSM_w-g?rel=0&amp;wmode=opaque"
        allowFullScreen={false}
      />
      <Card
        open={isOpen}
        scroll="body"
        className={classes.onboardingCard}
      >
        {getDialogContent(state.key)}
      </Card>
    </>
  );
}

ViewWelcomeAccountsDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onAddAccount: PropTypes.func.isRequired,
  allAccounts: PropTypes.arrayOf(PropTypes.object).isRequired,
};
