import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker } from '@material-ui/pickers';

import NumberFormat from 'react-number-format';

import { Autocomplete } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import { Card } from '@material-ui/core';
import {
  create, find, patch,
} from '../feathersWrapper';
import { PersonContext } from '../contexts/PersonContext';
import {
  handleTextFieldChange,
  handleNumberFormatChange,
  handleCheckboxChange,
  handleKeyboardDatePickerChange,
  handleTransactionScopeChange,
  handleAutocompleteChange,
} from '../functions/InputHandlers';

import TransactionScope from './TransactionScope';

import {
  setJournalScope,
} from '../functions/JournalFunctions';
import { getLoanAccountTypeOptions, getPaymentAccountOptions, nameLabel } from './Autocomplete/Library';
import { getManualBasicJournal } from './SearchSelect/TransactionTypeOptions';
import PurpleButton from './StyledComponents/PurpleButton';

const styles = () => ({
  onboardingCard: {
    width: '100%',
    maxWidth: '600px',
  },
});

class AddLoanDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  setInitialState = async () => {
    const { bookkeepingStartDate } = this.context;
    const initialState = {
      submitting: false,
      step: 'enterLoanInformation',
      account: {
        name: '',
        type: 'Liability',
        loanType: null,
        hasEscrow: true,
        setUpPaymentTemplate: true,
        lastCurrentBalance: null,
        lastCurrentBalanceDate: moment(),
        mortgagePaymentAmount: null,
        mortgagePaymentAccountId: null,
        mortgagePaymentAccountIdSelect: null,
        mortgageInterestRatePecent: null,
        mortgageEscrowTransferAmount: null,
        autobookMatchingTransactions: true,
      },
      error: null,
      advancedPaymentTemplate: false,
      journal: getManualBasicJournal(),
      loanAccount: null,
      addOpeningBalance: true,
      loanOpeningBalanceAmount: null,
      escrowOpeningBalanceAmount: null,
      loading: false,
    };
    initialState.journal.journalScopeSelect = { name: 'Property/Unit', id: 'Property/Unit' };
    if (bookkeepingStartDate) {
      initialState.journal.date = moment(bookkeepingStartDate).subtract(1, 'days').format('MM/DD/YYYY');
    }
    initialState.journal.description = 'Opening Balance';
    initialState.paymentAccountOptions = await getPaymentAccountOptions(this);

    this.setState(initialState);
  };

  handleSaveLoanAndContinue = async (event) => {
    event.preventDefault();
    const { organizationId } = this.context;
    const { onAddAccount } = this.props;
    const {
      submitting,
      account,
      journal,
    } = this.state;

    if (submitting) {
      return;
    }
    this.setState({ submitting: true });

    setJournalScope(journal);

    const accountSubmit = { ...account };
    accountSubmit.organizationId = organizationId;

    accountSubmit.type2 = account.loanType.id;

    accountSubmit.propertyId = journal.propertyId;
    accountSubmit.entityId = journal.entityId;
    accountSubmit.unitId = journal.unitId;

    accountSubmit.mortgagePaymentAmount = null;
    accountSubmit.mortgagePaymentAccountId = null;
    accountSubmit.mortgageInterestRatePecent = null;
    accountSubmit.mortgageEscrowTransferAmount = null;

    if (account.hasEscrow) {
      await create(this, 'accounts', {
        organizationId,
        name: `Escrow for ${account.name}`,
        type: 'Asset',
        type2: 'Escrow',
        propertyId: accountSubmit.propertyId,
        entityId: accountSubmit.entityId,
        unitId: accountSubmit.unitId,
      }, true)
        .then((result) => {
          accountSubmit.mortgageEscrowAccountId = result.id;
        })
        .catch((error) => {
          this.setState({ error });
          this.setState({ submitting: false });
        });
    }

    create(this, 'accounts', accountSubmit, true)
      .then((result) => {
        onAddAccount();
        const accountUpdate = { ...account };
        if (account.loanType.id !== 'Mortgage') {
          accountUpdate.setUpPaymentTemplate = false;
        }
        this.setState({
          step: 'templateSetUp',
          loanOpeningBalanceAmount: accountUpdate.lastCurrentBalance,
          account: accountUpdate,
          loanAccount: result,
          submitting: false,
        });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  handlePaymentTemplateSetup = async (event) => {
    event.preventDefault();
    const { organizationId, basis } = this.context;
    const { onAddAccount } = this.props;
    const {
      submitting,
      account,
      loanAccount,
      loanOpeningBalanceAmount,
    } = this.state;

    if (submitting) {
      return;
    }

    if (!account.setUpPaymentTemplate) {
      this.setState({ step: 'openingBalance' });
      return;
    }

    if (account.mortgageEscrowTransferAmount >= account.mortgagePaymentAmount) {
      this.setState({ error: { message: 'The escrow portion of the mortgage payment should be less than the total payment amount' } });
      return;
    }

    this.setState({ submitting: true });
    const accountSubmit = { ...account };
    accountSubmit.mortgageInterestRate = account.mortgageInterestRatePercent / 100;
    accountSubmit.mortgageEscrowTransferAmount = account.hasEscrow
      ? account.mortgageEscrowTransferAmount : null;
    accountSubmit.mortgagePaymentAccountId = account.mortgagePaymentAccountIdSelect
      ? account.mortgagePaymentAccountIdSelect.id : null;

    patch(this, 'accounts', loanAccount.id, accountSubmit, true)
      .then(async () => {
        onAddAccount();
        if (account.setUpPaymentTemplate && account.autobookMatchingTransactions) {
          const automationResult = await create(this, 'automations', {
            organizationId,
            automationName: 'bookLoanPayments',
            loanAccountId: loanAccount.id,
          });
          if (automationResult.newTransactions) {
            const balanceReportQuery = {
              organizationId,
              basis,
              accountId: loanAccount.id,
              reportName: 'accountJournalTotals',
            };

            const balanceReport = await create(this, 'reports', { ...balanceReportQuery });
            const newLoanOpeningBalanceAmount = loanOpeningBalanceAmount
              - balanceReport[0].netCredits;
            this.setState({
              step: 'autobook',
              autobookedTransactionCount: automationResult.newTransactions,
              loanOpeningBalanceAmount: newLoanOpeningBalanceAmount,
              submitting: false,
            });
            return;
          }
        }
        this.setState({ step: 'openingBalance', submitting: false });
      })
      .catch((error) => {
        this.setState({ error });
        this.setState({ submitting: false });
      });
  };

  saveOpeningBalance = async (event) => {
    event.preventDefault();
    const { organizationId } = this.context;
    const { onAddAccount } = this.props;
    const {
      submitting,
      journal,
      loanAccount,
      loanOpeningBalanceAmount,
      escrowOpeningBalanceAmount,
      addOpeningBalance,
    } = this.state;

    if (submitting) {
      return;
    }

    if (!addOpeningBalance) {
      onAddAccount();
      this.setState({ submitting: false });
      this.closeDialog();
      return;
    }

    this.setState({ submitting: true });

    const accountResult = await find(this, 'accounts', {
      query: { organizationId, default: 'autoBalance' },
    });
    const autobalanceAccount = accountResult.data[0];

    setJournalScope(journal);
    journal.type = 'Opening Balance';
    journal.organizationId = organizationId;

    const loanOpeningBalanceJournal = { ...journal };

    loanOpeningBalanceJournal.amount = loanOpeningBalanceAmount;

    loanOpeningBalanceJournal.debitLines[0].debit = loanOpeningBalanceAmount;
    loanOpeningBalanceJournal.debitLines[0].accountId = autobalanceAccount.id;

    loanOpeningBalanceJournal.creditLines[0].credit = loanOpeningBalanceAmount;
    loanOpeningBalanceJournal.creditLines[0].accountId = loanAccount.id;

    loanOpeningBalanceJournal.journalLines = loanOpeningBalanceJournal.debitLines.concat(
      loanOpeningBalanceJournal.creditLines,
    );
    await create(this, 'journals', loanOpeningBalanceJournal, true);

    if (escrowOpeningBalanceAmount) {
      const escrowOpeningBalanceJournal = { ...journal };

      escrowOpeningBalanceJournal.amount = escrowOpeningBalanceAmount;

      escrowOpeningBalanceJournal.debitLines[0].debit = escrowOpeningBalanceAmount;
      escrowOpeningBalanceJournal.debitLines[0].accountId = loanAccount.mortgageEscrowAccountId;

      escrowOpeningBalanceJournal.creditLines[0].credit = escrowOpeningBalanceAmount;
      escrowOpeningBalanceJournal.creditLines[0].accountId = autobalanceAccount.id;

      escrowOpeningBalanceJournal.journalLines = escrowOpeningBalanceJournal.debitLines.concat(
        escrowOpeningBalanceJournal.creditLines,
      );
      await create(this, 'journals', escrowOpeningBalanceJournal, true);
    }

    onAddAccount();
    this.setState({ submitting: false });
    this.closeDialog();
  };

  closeDialog = () => {
    const { closeDialog } = this.props;
    closeDialog();
  };

  getDialogStepContent = () => {
    const {
      step,
      account,
      autobookedTransactionCount,
      error,
      journal,
      addOpeningBalance,
      loanOpeningBalanceAmount,
      escrowOpeningBalanceAmount,
      advancedPaymentTemplate,
      paymentAccountOptions,
    } = this.state;

    switch (step) {
      case 'enterLoanInformation':
        return (
          <DialogContent>
            <form onSubmit={this.handleSaveLoanAndContinue}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>Please enter your loan information</Typography>
              </Box>
              <TextField
                label="Loan Name"
                fullWidth
                required
                margin="dense"
                InputProps={{
                  value: account.name,
                  name: 'nested_account_name',
                  onChange: handleTextFieldChange(this),
                }}
              />
              <Autocomplete
                options={getLoanAccountTypeOptions}
                getOptionLabel={nameLabel}
                value={account.loanType}
                onChange={handleAutocompleteChange('nested_account_loanType', this)}
                disableClearable
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                    label="Loan Type"
                    margin="dense"
                    required
                    helperText={account.loanType && account.loanType.id === 'HELOC'
                      ? 'Set the loan scope (below) to indicate where the HELOC funds will be utilized, not the property used as collateral.'
                      : ''}
                  />
                )}
              />
              <TransactionScope
                journal={journal}
                transactionScopeChange={(newScopeValues) => {
                  handleTransactionScopeChange(journal, newScopeValues, this);
                }}
                label="Loan"
              />
              <FormControl margin="dense" fullWidth>
                <InputLabel required>
                  Current Loan Balance
                </InputLabel>
                <NumberFormat
                  value={account.lastCurrentBalance}
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale
                  required
                  onValueChange={handleNumberFormatChange('nested_account_lastCurrentBalance', this)}
                  customInput={Input}
                />
              </FormControl>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={account.hasEscrow} onChange={handleCheckboxChange(this)} name="nested_account_hasEscrow" />}
                  label="This loan includes an escrow account"
                />
              </FormControl>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Typography color="error">{error && error.message}</Typography>
                <PurpleButton
                  variant="contained"
                >
                  Save and Continue
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.closeDialog}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'templateSetUp':
        return (
          <DialogContent>
            <form onSubmit={this.handlePaymentTemplateSetup}>
              <Typography variant="caption" gutterBottom>
                {account.name}
              </Typography>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Your loan has been saved. Do you want to set up a payment template for this loan?
                </Typography>
              </Box>
              <FormControl margin="none" fullWidth>
                <FormControlLabel
                  control={<Checkbox checked={account.setUpPaymentTemplate} onChange={handleCheckboxChange(this)} name="nested_account_setUpPaymentTemplate" />}
                  label={
                    account.loanType.id === 'Mortgage'
                      ? 'Set up loan payment template (strongly recommended)'
                      : 'Set up loan payment template'
                  }
                />
              </FormControl>
              {account.setUpPaymentTemplate && (
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Payment Template
                </Typography>
                <FormControl margin="dense" fullWidth>
                  <InputLabel required>
                    Monthly Payment Amount
                  </InputLabel>
                  <NumberFormat
                    value={account.mortgagePaymentAmount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    required
                    onValueChange={handleNumberFormatChange('nested_account_mortgagePaymentAmount', this)}
                    customInput={Input}
                  />
                </FormControl>
                <FormControl margin="dense" fullWidth>
                  <InputLabel required>
                    Interest Rate
                  </InputLabel>
                  <NumberFormat
                    value={account.mortgageInterestRatePercent}
                    suffix="%"
                    decimalScale={3}
                    fixedDecimalScale
                    required
                    onValueChange={handleNumberFormatChange('nested_account_mortgageInterestRatePercent', this)}
                    customInput={Input}
                  />
                </FormControl>
                {account.hasEscrow && (
                <FormControl margin="dense" fullWidth>
                  <InputLabel required>
                    Monthly Escrow Transfer Amount
                  </InputLabel>
                  <NumberFormat
                    value={account.mortgageEscrowTransferAmount}
                    thousandSeparator
                    prefix="$"
                    decimalScale={2}
                    fixedDecimalScale
                    required
                    onValueChange={handleNumberFormatChange('nested_account_mortgageEscrowTransferAmount', this)}
                    customInput={Input}
                  />
                </FormControl>
                )}
                {!advancedPaymentTemplate && (
                <Button onClick={() => this.setState({ advancedPaymentTemplate: true })} color="secondary">
                  More Options
                </Button>
                )}
                {advancedPaymentTemplate && (
                <>
                  <Autocomplete
                    options={paymentAccountOptions}
                    getOptionLabel={nameLabel}
                    value={account.mortgagePaymentAccountIdSelect}
                    onChange={handleAutocompleteChange(
                      'nested_account_mortgagePaymentAccountIdSelect',
                      this,
                    )}
                    getOptionSelected={(option, value) => option.id === value.id}
                    renderInput={(params) => (
                      <TextField
                        {...params /* eslint-disable-line react/jsx-props-no-spreading */}
                        margin="dense"
                        label="Payment Account (Optional)"
                        placeholder="Type to Search"
                        fullWidth
                      />
                    )}
                  />
                  <FormControl margin="none" fullWidth>
                    <FormControlLabel
                      control={<Checkbox checked={account.autobookMatchingTransactions} onChange={handleCheckboxChange(this)} name="nested_account_autobookMatchingTransactions" />}
                      label="Automatically book matching loan payments"
                    />
                  </FormControl>
                </>
                )}
              </Box>
              )}
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2">
                  {`Completing the payment template allows REI Hub to automatically recognize and
                  amortize conventional mortgage payments in your transaction import feed. Each
                  recognized payment is automatically split into its principal, interest, and
                  escrow components to accurately track cashflow and expenses.`}
                </Typography>
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} mb={2}>
                <Typography color="error">{error && error.message}</Typography>
                <PurpleButton
                  variant="contained"
                >
                  {account.setUpPaymentTemplate ? 'Save' : 'Continue'}
                </PurpleButton>
                <Box mt={1}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={this.closeDialog}
                  >
                    Close
                  </Button>
                </Box>
              </Box>
            </form>
          </DialogContent>
        );
      case 'autobook':
        return (
          <DialogContent>
            <Typography variant="body1">
              {`${autobookedTransactionCount} transactions matched the payment template and were
                booked automatically.`}
            </Typography>
            <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4}>
              <PurpleButton
                variant="contained"
                onClick={this.setState({ step: 'openingBalance' })}
              >
                Continue
              </PurpleButton>
              <Box mt={1}>
                <Button
                  color="secondary"
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={this.closeDialog}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </DialogContent>
        );
      case 'openingBalance':
        return (
          <DialogContent>
            <form onSubmit={this.saveOpeningBalance}>
              <Box mx="auto" mb={2}>
                <Typography variant="h6" gutterBottom>
                  Do you want to add an opening balance transaction for this loan?
                </Typography>
              </Box>
              <FormControlLabel
                control={<Checkbox checked={addOpeningBalance} onChange={handleCheckboxChange(this)} name="addOpeningBalance" />}
                label="Add Opening Balance"
              />
              {addOpeningBalance && (
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="subtitle2" gutterBottom>
                  Opening Balance
                </Typography>
                <KeyboardDatePicker
                  label="Date"
                  format="MM/DD/YYYY"
                  placeholder="MM/DD/YYYY"
                  value={journal.date}
                  onChange={handleKeyboardDatePickerChange('nested_journal_date', this)}
                  margin="dense"
                  fullWidth
                  clearable
                  required
                />
                <FormControl margin="dense" fullWidth>
                  <InputLabel required>
                    Loan Opening Balance Amount
                  </InputLabel>
                  <NumberFormat
                    value={loanOpeningBalanceAmount}
                    required
                    thousandSeparator
                    prefix="$"
                    fixedDecimalScale
                    decimalScale={2}
                    onValueChange={handleNumberFormatChange('loanOpeningBalanceAmount', this)}
                    customInput={Input}
                  />
                </FormControl>
                {account.hasEscrow && (
                <FormControl margin="dense" fullWidth>
                  <InputLabel>
                    Escrow Opening Balance Amount
                  </InputLabel>
                  <NumberFormat
                    value={escrowOpeningBalanceAmount}
                    thousandSeparator
                    prefix="$"
                    fixedDecimalScale
                    decimalScale={2}
                    onValueChange={handleNumberFormatChange('escrowOpeningBalanceAmount', this)}
                    customInput={Input}
                  />
                </FormControl>
                )}
              </Box>
              )}
              <Box
                border={1}
                borderColor="grey.500"
                borderRadius="borderRadius"
                padding={2}
                marginY={2}
              >
                <Typography variant="body2">
                  Adding an opening balance transaction establishes the starting point
                  for your loan account on your balance sheet.
                  The opening balance is not the original loan amount;
                  it is the outstanding amount as of the start
                  of your recordkeeping in REI Hub.
                </Typography>
              </Box>
              <Box maxWidth="400px" marginX="auto" textAlign="center" mt={4} marginBottom={2}>
                <Typography color="error">{error && error.message}</Typography>
                <PurpleButton
                  variant="contained"
                >
                  Finish
                </PurpleButton>
              </Box>
            </form>
          </DialogContent>
        );
      default:
        return (
          <></>
        );
    }
  };

   onEnterCard = async () => {
     await this.setInitialState();
   };

   openingBalanceSaveButtonText() {
     const { account } = this.state;
     if (account.hasEscrow) {
       return 'Save Opening Balances';
     }
     return 'Save Opening Balance';
   }

   render() {
     const { isOpen, classes } = this.props;
     const { showWelcome } = this.context;
     const { loading } = this.state;

     if (loading && showWelcome) {
       this.onEnterCard();
     }

     return (
       <>
         {!showWelcome && (
         <Dialog
           open={isOpen}
           scroll="body"
           maxWidth="sm"
           fullWidth
           disableBackdropClick
           disableEscapeKeyDown
           onEnter={this.setInitialState}
           aria-labelledby="alert-dialog-title"
           aria-describedby="alert-dialog-description"
         >
           {this.getDialogStepContent()}
         </Dialog>
         )}
         {showWelcome && (
         <Card
           open={isOpen}
           className={classes.onboardingCard}
         >
           {!loading && this.getDialogStepContent()}
         </Card>
         )}
       </>
     );
   }
}

AddLoanDialog.contextType = PersonContext;

AddLoanDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onAddAccount: PropTypes.func.isRequired,
};

export default withStyles(styles)(AddLoanDialog);
