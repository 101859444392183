import { createTheme } from '@material-ui/core/styles';

import { SPACES } from '~/constants/themes';

const primary = '#431C5D';
const secondary = '#41AAF5';
const semiBold = 600;
const headerFontProps = {
  fontFamily: '"Poppins", sans-serif',
  fontWeight: semiBold,
};

export default createTheme({
  typography: {
    fontSize: 16,
    // Use the system font instead of the default Roboto font.
    fontFamily: '"Open Sans", sans-serif',
    body1: { fontSize: '18px' },
    subtitle1: { fontSize: '18px', fontWeight: semiBold },
    subtitle2: { fontWeight: semiBold },
    overline: { sontSize: '14px', textTransform: 'uppercase' },
    caption: { fontSize: '14px' },
    h1: { ...headerFontProps, fontSize: '96px' },
    h2: { ...headerFontProps, fontSize: '64px', lineHeight: '82px' },
    h3: { ...headerFontProps, fontSize: '48px', lineHeight: '64.02px' },
    h4: { ...headerFontProps, fontSize: '32px', lineHeight: '47.99px' },
    h5: { ...headerFontProps, fontSize: '24px', lineHeight: '37px' },
    h6: { ...headerFontProps, fontSize: '20px', lineHeight: '37px' },
  },
  palette: {
    action: {
      active: '#0000008F',
    },
    error: {
      main: '#D32F2F',
    },
    primary: {
      contrast: '#F0E4F0',
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    success: {
      main: '#2E7D32',
    },
    text: {
      primary: '#000000DE',
      secondary: '#00000099',
    },
  },
  overrides: {
    // May need to reconfigure after migration to v4?
    MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    },
    MuiInput: {
      underline: {
        '&$focused:after': {
          borderBottomColor: secondary,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ${secondary}`,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: semiBold,
      },
    },
  },
  space: SPACES,
});
