import { createTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

import { SPACES } from '~/constants/themes';

const primary = grey[800];
const secondary = '#41AAF5';
const headerFontProps = {
  fontFamily: '"Poppins", sans-serif',
  fontWeight: 600,
};

const theme = createTheme({
  typography: {
    fontSize: 16,
    // Use the system font instead of the default Roboto font.
    fontFamily: '"Open Sans", sans-serif',
    subtitle1: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
    overline: {
      textTransform: 'uppercase',
    },
    h1: { ...headerFontProps, fontSize: '96px' },
    h2: { ...headerFontProps, fontSize: '64px', lineHeight: '82px' },
    h3: { ...headerFontProps, fontSize: '48px', lineHeight: '64.02px' },
    h4: { ...headerFontProps, fontSize: '32px', lineHeight: '47.99px' },
    h5: { ...headerFontProps, fontSize: '24px', lineHeight: '37px' },
    h6: { ...headerFontProps, fontSize: '20px', lineHeight: '37px' },
  },
  palette: {
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    text: { secondary: '#00000099' },
  },
  space: SPACES,
  overrides: {
    // May need to reconfigure after migration to v4?
    /* MuiFormLabel: {
      asterisk: {
        display: 'none',
      },
    }, */
    MuiInput: {
      underline: {
        '&$focused:after': {
          borderBottomColor: secondary,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderBottom: `2px solid ${secondary}`,
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
      label: {
        fontWeight: 600,
      },
    },
  },
});

export default theme;
