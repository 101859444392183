const DISCOUNTS = [
  {
    id: 'early_subscription_60',
    discountPercent: 60,
    duration: 2,
  },
  {
    id: 'early_subscription_40',
    discountPercent: 40,
    duration: 3,
  },
  {
    id: 'early_subscription_10',
    discountPercent: 10,
    duration: 3,
  },
  {
    id: 'trial_expired_60',
    discountPercent: 60,
    duration: 2,
  },
];

export default DISCOUNTS;
