import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import LockIcon from '@material-ui/icons/LockOutlined';
import makeStyles from '@material-ui/styles/makeStyles';
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { PersonContext } from '~/contexts/PersonContext';
import client from '~/feathers';

const WRONG_CREDENTIALS_MESSAGE = 'Incorrect email or password. Please try again.';
const SERVER_ERROR_MESSAGE = 'Sorry, we were unable to log you in. Please try again.';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100%',
    padding: '4%',
  },
  centerContent: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loginCard: {
    maxWidth: '440px',
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textField: {
    marginBottom: '12px',
  },
  forgotPasswordLink: {
    fontWeight: '600',
  },
  errorMessage: {
    marginBottom: '12px',
  },
}));

export default function Login() {
  const [loginFormEmail, setLoginFormEmail] = useState('');
  const [loginFormPassword, setLoginFormPassword] = useState('');
  const [autoSelectOrganization, setAutoSelectOrganization] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState({ message: '', severity: 'warning' });

  const location = useLocation();
  const { setPerson, setContextState } = useContext(PersonContext);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed.email) {
      setLoginFormEmail(parsed.email);
    }
    if (parsed.autoSelectOrganization === 'false') {
      setAutoSelectOrganization(false);
    }
  }, [location]);

  const loginSubmit = (event) => {
    event.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    setError({});

    client
      .authenticate({
        strategy: 'local',
        email: loginFormEmail.trim().toLowerCase(),
        password: loginFormPassword,
      })
      .then(async (response) => {
        await setPerson(response);
        setContextState({ autoSelectOrganization, sessionSource: 'login' });
      })
      .catch((err) => {
        if (err.message === 'Invalid login') {
          setError({ message: WRONG_CREDENTIALS_MESSAGE, severity: 'warning' });
        } else {
          setError({ message: SERVER_ERROR_MESSAGE, severity: 'error' });
        }

        setSubmitting(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'loginFormEmail') setLoginFormEmail(value);
    if (name === 'loginFormPassword') setLoginFormPassword(value);
  };

  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Helmet>
        <title>Sign In | REI Hub</title>
        <meta
          name="description"
          content="Sign in to REI Hub"
        />
      </Helmet>
      <Grid item xs={12} className={classes.centerContent}>
        <Card className={classes.loginCard}>
          <Box display="flex" flexDirection="column" width={1} px="50px" py="30px">
            <form onSubmit={loginSubmit}>
              <Box mb="30px">
                <Typography variant="h5" align="center" component="div">
                  Sign In
                </Typography>
              </Box>

              <TextField
                label="Email"
                id="email"
                fullWidth
                required
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  value: loginFormEmail,
                  name: 'loginFormEmail',
                  onChange: handleChange,
                  autoComplete: 'email',
                  type: 'email',
                  endAdornment: (
                    <InputAdornment position="end">
                      <EmailIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                label="Password"
                id="password"
                fullWidth
                required
                variant="outlined"
                className={classes.textField}
                InputProps={{
                  value: loginFormPassword,
                  name: 'loginFormPassword',
                  onChange: handleChange,
                  type: 'password',
                  autoComplete: 'current-password',
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
              />

              <Link
                color="primary"
                href={loginFormEmail
                  ? `/resetpassword?email=${loginFormEmail}`
                  : '/resetpassword'}
                underline="always"
                className={classes.forgotPasswordLink}
              >
                Forgot Password?
              </Link>

              <Box marginTop="30px">
                {Boolean(error.message) && (
                  <Alert
                    severity={error.severity}
                    className={classes.errorMessage}
                  >
                    {error.message}
                  </Alert>
                )}
                <Button
                  color="primary"
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  disableElevation
                  disabled={submitting}
                >
                  Sign In
                </Button>
                <Box marginTop={1.5}>
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={submitting}
                    size="large"
                    fullWidth
                    component={RouterLink}
                    to="/register"
                  >
                    Create an Account Instead
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
