import PropTypes from 'prop-types';
import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { PersonContext } from '../../contexts/PersonContext';
import history from '../../history';

export default function AuthenticateContainer({ children }) {
  const [loading, setLoading] = useState(true);
  const { checkCache, id, accountantLogin } = useContext(PersonContext);
  const location = useLocation();

  async function checkRedirect() {
    if (id && accountantLogin) {
      if (location.pathname !== '/accountant/firms') {
        await history.push('/accountant/firms');
      }
    } else if (id) {
      if (location.pathname !== '/portfolios') {
        await history.push('/portfolios');
      }
    }
  }

  useEffect(() => {
    async function init() {
      checkCache();
      await checkRedirect();
      setLoading(false);
    }

    init();
  }, []);

  useEffect(() => {
    checkRedirect();
  }, [id, accountantLogin, location.pathname]);

  if (loading) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}

AuthenticateContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
