export const FONT_SIZES = {
  sm: '12px',
  md: '16px',
  lg: '18px',
};

export const SPACES = {
  xxsm: '4px',
  xsm: '8px',
  sm: '12px',
  md: '16px',
  lg: '24px',
  xlg: '32px',
};
